import { Avatar, AvatarGroup } from '@mantine/core';
import { ResourceAvatar } from '@medplum/react';

const GroupedAvatar = ({
  profiles,
  onClick,
  maxVisible = 2,
}: {
  profiles: any[];
  onClick?: () => void;
  maxVisible?: number;
}): JSX.Element | null => {
  if (!profiles || profiles.length === 0) {
    // Case: No members
    return null; // Or render a placeholder/avatar indicating "No members"
  }

  if (profiles.length === 1) {
    // Case: Single member
    return (
      <div onClick={onClick} style={{ cursor: 'pointer' }}>
        <ResourceAvatar value={profiles[0]} radius="xl" size={36} />
      </div>
    );
  }

  // Case: More than 1 member
  const visibleMembers = profiles.slice(0, maxVisible);
  const remainingCount = profiles.length - maxVisible;

  return (
    <AvatarGroup spacing="sm" onClick={onClick} style={{ cursor: 'pointer' }}>
      {visibleMembers.map((el: unknown) => (
        <ResourceAvatar value={el} radius="xl" size={36} />
      ))}
      {remainingCount > 0 && <Avatar>{`+${remainingCount}`}</Avatar>}
    </AvatarGroup>
  );
};

export { GroupedAvatar };
