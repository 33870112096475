import { Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
// import { Filter, Operator, SearchRequest } from '@medplum/core';
import { SearchRequest } from '@medplum/core';
// import { Practitioner, Appointment } from '@medplum/fhirtypes';
// import { SearchControl } from '@medplum/react';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { CreateAppointment } from '../../components/CreateAppointment';
// import Appointments from '../../queue-management/Appointments';
import SearchControl from './Table';
import { Patient, Practitioner, PractitionerRole } from '@medplum/fhirtypes';
import { Loading, useMedplum } from '@medplum/react';

export interface UtilsData {
  allPatients: Patient[];
  allLocations: Location[];
  allPractitioners: Practitioner[];
  allPractitionerRoles: PractitionerRole[];
}

const AppointmentsPage = ({ Toggle }: { Toggle: FC<any> | undefined }): JSX.Element => {
  // const profile = useMedplumProfile() as Practitioner;
  const navigate = useNavigate();
  // const location = useLocation();
  // const [createAppointmentOpened, createAppointmentHandlers] = useDisclosure(false);
  const [_, createAppointmentHandlers] = useDisclosure(false);
  const medplum = useMedplum();

  // const tab = location.pathname.split('/').pop() ?? '';

  const [fetchLoading, setFetchLoading] = useState(true);
  const [utilsData, setUtilsData] = useState<UtilsData>({
    allPatients: [],
    allLocations: [],
    allPractitioners: [],
    allPractitionerRoles: [],
  });

  // const tabs = [
  //   ['upcoming', 'Upcoming'],
  //   ['past', 'Past'],
  // ];

  // const upcomingFilter: Filter = {
  //   code: 'date',
  //   operator: Operator.STARTS_AFTER,
  //   value: new Date().toISOString(),
  // };
  // const pastFilter: Filter = {
  //   code: 'date',
  //   operator: Operator.ENDS_BEFORE,
  //   value: new Date().toISOString(),
  // };

  // Start the SearchRequest with the appropriate filter depending on the active tab
  const [search, setSearch] = useState<SearchRequest>({
    resourceType: 'Appointment',
    fields: ['patient', 'start', 'end', 'serviceType', '_lastUpdated', 'status', 'location', 'practitioner'],
    // filters: [
    //   // { code: 'actor', operator: Operator.EQUALS, value: getReferenceString(profile as Practitioner) },
    //   tab === 'upcoming' ? upcomingFilter : pastFilter,
    // ],
    sortRules: [
      {
        code: 'date',
        descending: true,
      },
    ],
  });

  const getUtilsData = async (): Promise<void> => {
    setFetchLoading(true);
    const _count = 10000000;
    const results = await Promise.allSettled([
      medplum.search('Location', { _count, _sort: 'name' }),
      medplum.search('Practitioner', { _count, _sort: 'name' }),
      medplum.search('PractitionerRole', { _count }),
      medplum.search('Patient', { _count, _sort: 'name' }),
    ]);

    const locations =
      results[0].status === 'fulfilled'
        ? (results[0].value.entry?.map((entry) => entry.resource as Location) ?? [])
        : [];
    const practitioners =
      results[1].status === 'fulfilled'
        ? (results[1].value.entry?.map((entry) => entry.resource as Practitioner) ?? [])
        : [];
    const practitionerRoles =
      results[2].status === 'fulfilled'
        ? (results[2].value.entry?.map((entry) => entry.resource as PractitionerRole) ?? [])
        : [];
    const patients =
      results[3].status === 'fulfilled' ? (results[3].value.entry?.map((entry) => entry.resource) ?? []) : [];

    setUtilsData({
      allLocations: locations,
      allPractitioners: practitioners,
      allPractitionerRoles: practitionerRoles,
      allPatients: patients,
    });
    setFetchLoading(false);
  };

  useEffect(() => {
    // eslint-disable-next-line
    getUtilsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Ensure tab is either 'upcoming' or 'past'
  // if it's neither, navigate to the 'upcoming' tab
  // useEffect(() => {
  // if (!['upcoming', 'past'].includes(tab)) {
  //   navigate('/Appointment/upcoming');
  // }
  // }, [tab, navigate]);

  // function changeTab(newTab: string | null): void {
  //   // Remove date filters keepinsetSearchg others
  //   const filters = search.filters?.filter((f) => f.code !== 'date');

  //   // Add the appropriate date filter depending on the active tab
  //   if (newTab === 'upcoming') {
  //     navigate('/Appointment/upcoming');
  //     filters?.push(upcomingFilter);
  //   } else if (newTab === 'past') {
  //     navigate('/Appointment/past');
  //     filters?.push(pastFilter);
  //   }

  //   setSearch({
  //     ...search,
  //     filters,
  //   } as SearchRequest);
  // }

  if (fetchLoading) {
    return <Loading />;
  }

  return (
    <Paper shadow="xs" m="md" p="xs">
      {/* <CreateAppointment opened={createAppointmentOpened} handlers={createAppointmentHandlers} /> */}
      {/* <Tabs value={tab.toLowerCase()} onChange={changeTab}>
        <Tabs.List mb="xs">
          {tabs.map((tab) => (
            <Tabs.Tab value={tab[0]} key={tab[0]}>
              {tab[1]}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs> */}
      {/* <SearchControl
        search={search}
        onClick={(e: { resource: { resourceType: any; id: any } }) =>
          navigate(`/${e.resource.resourceType}/${e.resource.id}`)
        }
        onAuxClick={(e: { resource: { resourceType: any; id: any } }) =>
          window.open(`/${e.resource.resourceType}/${e.resource.id}`, '_blank')
        }
        onChange={(e: { definition: SetStateAction<SearchRequest<any>> }) => {
          setSearch(e.definition);
        }}
        onNew={() => createAppointmentHandlers.open()}
        checkboxesEnabled={false}
        hideFilters
      /> */}
      <SearchControl
        search={search}
        onClick={(e) => navigate(`/${e.resource.resourceType}/${e.resource.id}`)}
        onAuxClick={(e) => window.open(`/${e.resource.resourceType}/${e.resource.id}`, '_blank')}
        onChange={(e) => {
          setSearch(e.definition);
        }}
        onNew={() => createAppointmentHandlers.open()}
        checkboxesEnabled={false}
        hideFilters
        setSearch={setSearch}
        utilsData={utilsData}
        Toggle={Toggle}
      />
    </Paper>
  );
};

export default AppointmentsPage;
