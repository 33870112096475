import { Grid, Group, AppShell as MantineAppShell, Menu, UnstyledButton } from '@mantine/core';
// import { formatHumanName } from '@medplum/core';
// import { HumanName } from '@medplum/fhirtypes';
import { useMedplum, useMedplumProfile } from '@medplum/react-hooks';
// import { IconChevronDown } from '@tabler/icons-react';
import cx from 'clsx';
import { ReactNode, useState } from 'react';
import { ResourceAvatar } from '../ResourceAvatar/ResourceAvatar';
import classes from './Header.module.css';
import { HeaderDropdown } from './HeaderDropdown';
import { Link, useLocation } from 'react-router-dom';
// import { ProjectMembership } from '@medplum/fhirtypes';
// import { HeaderSearchInput } from './HeaderSearchInput';

export interface HeaderProps {
  readonly pathname?: string;
  readonly searchParams?: URLSearchParams;
  readonly headerSearchDisabled?: boolean;
  readonly logo: ReactNode;
  readonly version?: string;
  readonly navbarToggle: () => void;
  readonly notifications?: ReactNode;
}

const allLinks: { [key in 'practitioner' | 'guest' | 'admin' | 'staff']: { link: string; label: string }[] } = {
  admin: [
    { link: '/home', label: 'Calendar' },
    { link: '/Patient', label: 'Patients' },
    { link: '/Practitioner', label: 'Surgeons' },
    { link: '/Staff', label: 'Staff' },
    { link: '/Users', label: 'Users' },
    { link: '/Location', label: 'Locations' },
  ],
  staff: [
    { link: '/home', label: 'Calendar' },
    { link: '/Patient', label: 'Patients' },
  ],
  practitioner: [
    { link: '/home', label: 'Calendar' },
    { link: '/Patient', label: 'Patients' },
  ],
  guest: [
    { link: '/home', label: 'Calendar' },
    { link: '/Patient', label: 'Patients' },
  ],
};

export function Header(props: HeaderProps): JSX.Element {
  const profile = useMedplumProfile();
  const medplum = useMedplum();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  // const [links, setLinks] = useState<{ link: string; label: string }[]>([]);
  // const [active, setActive] = useState(links[0].link);
  const userProjectMembership = medplum.getProjectMembership();

  const { pathname } = useLocation();

  let links = [{ link: '/home', label: 'Calendar' }];

  // const getUtilsData = async (): Promise<void> => {
  //   const memberships = await medplum.search('ProjectMembership', { profile: `Practitioner/${profile?.id}` });
  //   if (memberships.entry) {
  //     const userProjectMembership = memberships.entry[0].resource as ProjectMembership;
  //     // console.log(
  //     //   'userProjectMembership.authRole || profile?.resourceType',
  //     //   userProjectMembership.authRole || profile?.resourceType?.toLocaleLowerCase()
  //     // );

  //     if (userProjectMembership.project) {
  //       const isAdmin = userProjectMembership.admin ? 'admin' : undefined;
  //       // const role = userProjectMembership?.authRole || profile?.resourceType?.toLocaleLowerCase() || isAdmin;
  //       const role = userProjectMembership?.authRole || isAdmin || profile?.resourceType?.toLocaleLowerCase();

  //       if (role && ['practitioner', 'staff', 'guest', 'admin'].includes(role)) {
  //         setLinks(allLinks[role as keyof typeof allLinks]);
  //       }
  //     }
  //   }
  // };

  if (!userProjectMembership) {
    return <></>;
  }

  if (userProjectMembership?.admin) {
    links = allLinks.admin;
  } else if (userProjectMembership?.pViewPatient) {
    links.push({ link: '/Patient', label: 'Patients' });
  }

  const items = links.map((link) => (
    <Link key={link.label} to={link.link} className={classes.link} data-active={link.link === pathname || undefined}>
      {link.label}
    </Link>
  ));

  return (
    <MantineAppShell.Header pt={8} style={{ zIndex: 101 }}>
      {/* <Group justify="space-between"> */}
      <Grid justify="space-between" align="center" p={0}>
        <Grid.Col span={{ xs: 4, sm: 3, md: 3, lg: 2, xl: 2 }} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
          <Group>
            <UnstyledButton className={classes.logoButton} onClick={props.navbarToggle}>
              {props.logo}
            </UnstyledButton>
            {/* {!props.headerSearchDisabled && (
            <HeaderSearchInput pathname={props.pathname} searchParams={props.searchParams} />
          )} */}
          </Group>
        </Grid.Col>
        <Grid.Col span={{ xs: 12, sm: 8, md: 7, lg: 8, xl: 8 }} order={{ xs: 3, sm: 2, md: 2, lg: 2, xl: 2 }}>
          <Group gap="sm" justify="flex-start" w={{ sm: '100%' }}>
            <Group
              gap={'xs'}
              w={{ sm: '100%', xs: '100%' }}
              justify="center"
              align="center"
              display={'flex'}
              my={{ xs: 'lg' }}
            >
              {items}
            </Group>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ xs: 3, sm: 1, md: 2, lg: 2, xl: 2 }} order={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 3 }}>
          <Group gap="lg" pr="sm" justify="flex-end">
            {props.notifications}
            <Menu
              // width={260}
              shadow="xl"
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              opened={userMenuOpened}
              onClose={() => setUserMenuOpened(false)}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                  onClick={() => setUserMenuOpened((o) => !o)}
                >
                  <Group gap={7}>
                    <ResourceAvatar value={profile} radius="xl" size={36} />
                    {/* <Text size="sm" className={classes.userName}>
                    {formatHumanName(profile?.name?.[0] as HumanName)}
                  </Text> */}
                    {/* <IconChevronDown size={16} stroke={1.5} /> */}
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <HeaderDropdown version={props.version} />
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Grid.Col>
      </Grid>
      {/* </Group> */}
    </MantineAppShell.Header>
  );
}
