import { Button, Group, Menu, Modal, Table, Text, UnstyledButton } from '@mantine/core';
import { useUser } from '../../user.context';
import classes from './AssociatedUsers.module.css';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { Dispatch, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { createReference } from '@medplum/core';

const AssociatedUsers = ({
  selectedUser,
  setSelectedUser,
}: {
  resources: any[];
  refreshResults: () => void;
  selectedUser: any;
  setSelectedUser: Dispatch<any>;
}): JSX.Element => {
  const { getUtilsData, allAssociatedUsers, setAllAssociatedUsers, profile } = useUser();
  const [removeModelOpened, removeModelHandler] = useDisclosure(false);
  const [selectedSurgeon, setSelectedSurgeon] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const medplum = useMedplum();

  const [allUsers, setAllUsers] = useState<any[]>(selectedUser.associatedSurgeons);

  const fields = [{ name: 'Invited By' }];
  fields.push({ name: 'Surgeon' });
  // fields.push({ name: 'User' });
  fields.push({ name: 'Action' });

  const handleOpen = (resource: any): void => {
    removeModelHandler.open();
    setSelectedSurgeon(resource);
  };

  const handleClose = (): void => {
    removeModelHandler.close();
    setSelectedSurgeon(undefined);
    setDeleteLoading(false);
  };

  const handleConfirm = async (): Promise<void> => {
    setDeleteLoading(true);
    // Delete Calendar Access API call
    try {
      await medplum.deleteResource(selectedSurgeon.resourceType, selectedSurgeon.id);

      const updatedAllAssociatedUsers = allAssociatedUsers.filter((surgeon) => surgeon.id !== selectedSurgeon.id);
      setAllAssociatedUsers(updatedAllAssociatedUsers);

      const filteredAssociatedSurgeons = selectedUser.associatedSurgeons.filter(
        (surgeon: { id: any }) => surgeon.id !== selectedSurgeon.id
      );

      setAllUsers(filteredAssociatedSurgeons);

      setSelectedUser({
        ...selectedUser,
        associatedSurgeons: filteredAssociatedSurgeons,
      });

      showNotification({
        title: `Staff removed successfully!`,
        message: `${selectedSurgeon?.receiverDetails?.title} ${selectedSurgeon?.receiverDetails?.profile?.display} has been removed from ${selectedSurgeon?.practitioner?.display} calendar`,
        color: 'green',
      });
      setSelectedSurgeon(undefined);
      handleClose();
      await getUtilsData();

      // Create Communication Log for removed user
      const text = `You have been removed from surgeon ${selectedSurgeon.practitioner?.display}'s calendar by ${createReference(profile).display}.`;

      await medplum.createResource({
        resourceType: 'Communication',
        status: 'completed',
        subject: selectedSurgeon.receiverDetails.profile,
        sender: createReference(profile),
        sent: new Date().toISOString(),
        payload: [{ contentString: text }],
      });

      // Create Communication Log for admin
      const text2 = `You removed the ${selectedSurgeon?.receiverDetails?.title} ${selectedSurgeon?.receiverDetails?.profile?.display} from surgeon ${selectedSurgeon.practitioner?.display}'s calendar.`;

      await medplum.createResource({
        resourceType: 'Communication',
        status: 'completed',
        subject: createReference(profile),
        sender: createReference(profile),
        sent: new Date().toISOString(),
        payload: [{ contentString: text2 }],
      });

      // Create Communication Log for surgeon by admin
      const payloadText = `${selectedSurgeon?.receiverDetails?.title} ${selectedSurgeon?.receiverDetails?.profile?.display} has been removed from your calendar by admin ${createReference(profile).display}.`;

      await medplum.createResource({
        resourceType: 'Communication',
        status: 'completed',
        subject: selectedSurgeon.practitioner,
        sender: createReference(profile),
        sent: new Date().toISOString(),
        payload: [{ contentString: payloadText }],
      });
    } catch (error) {
      console.error('Error removing calendar access', error);
      setSelectedSurgeon(undefined);
      showNotification({
        title: 'Error removing user',
        message: 'There was an error removing the user',
        color: 'red',
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal
        opened={removeModelOpened}
        onClose={removeModelHandler.close}
        title={'Remove Calendar'}
        centered
        size="lg"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Text>
          Are you sure you want to remove surgeon {selectedSurgeon?.practitioner?.display}'s calendar from{' '}
          {selectedSurgeon?.receiverDetails?.title} {selectedSurgeon?.receiverDetails?.profile?.display}?
        </Text>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
          <Button variant="outline" color="gray" onClick={handleClose} disabled={deleteLoading}>
            Cancel
          </Button>
          <Button color="red" onClick={handleConfirm} loading={deleteLoading}>
            Confirm
          </Button>
        </div>
      </Modal>

      <Table className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            {fields.map((field) => (
              <Table.Th key={field.name} className={classes.tableHead}>
                <Menu shadow="md" width={240} position="bottom-end">
                  <Menu.Target>
                    <UnstyledButton className={classes.control} p={2}>
                      <Group justify="space-between" wrap="nowrap">
                        <Text className={classes.headTitle}>{field.name}</Text>
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                </Menu>
              </Table.Th>
            ))}
          </Table.Tr>
          {/* {!props.hideFilters && (
    <Table.Tr>
      {checkboxColumn && <Table.Th />}
      {fields.map((field) => (
        <Table.Th key={field.name}>
          {field.searchParams && (
            <FilterDescription
              resourceType={resourceType}
              searchParams={field.searchParams}
              filters={memoizedSearch.filters}
            />
          )}
        </Table.Th>
      ))}
    </Table.Tr>
  )} */}
        </Table.Thead>
        <Table.Tbody>
          {allUsers?.map(
            (resource: any) =>
              resource && (
                <>
                  <Table.Tr
                    key={resource.id}
                    className={classes.tr}
                    data-testid="search-control-row"
                    // onClick={(e) => handleRowClick(e, resource)}
                    // onAuxClick={(e) => handleRowClick(e, resource)}
                  >
                    {/* {checkboxColumn && (
                <Table.Td>
                  <input
                    type="checkbox"
                    value="checked"
                    data-testid="row-checkbox"
                    aria-label={`Checkbox for ${resource.id}`}
                    checked={!!state.selected[resource.id as string]}
                    onChange={(e) => handleSingleCheckboxClick(e, resource.id as string)}
                  />
                </Table.Td>
              )} */}

                    {fields.map((field) => {
                      if (field.name === 'Invited By') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.senderDetails?.profileDetails} size="sm" />
                              {resource?.senderDetails?.profile?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (field.name === 'Surgeon') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.practitionerDetails} size="sm" />
                              {resource?.practitioner?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (field.name === 'User') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.receiverDetails?.profileDetails} size="sm" />
                              {resource?.receiverDetails?.profile?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (field.name === 'Action') {
                        return (
                          <Table.Td>
                            <Group onClick={() => handleOpen(resource)}>
                              <UnstyledButton className={classes.actionButton}>
                                <Text>Remove</Text>
                              </UnstyledButton>
                            </Group>
                          </Table.Td>
                        );
                      }

                      return <Table.Td key={field?.name}>{}</Table.Td>;
                    })}
                  </Table.Tr>
                </>
              )
          )}
        </Table.Tbody>
      </Table>
    </>
  );
};

export default AssociatedUsers;
