import { ActionIcon, Anchor, Center, Group, Loader, Popover, ScrollArea, Text } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import {
  MedplumClient,
  ProfileResource,
  createReference,
  formatHumanName,
  formatSearchQuery,
  normalizeErrorString,
} from '@medplum/core';
import {
  Attachment,
  AuditEvent,
  Bundle,
  BundleEntry,
  Communication,
  DiagnosticReport,
  Media,
  OperationOutcome,
  Reference,
  Resource,
  ResourceType,
} from '@medplum/fhirtypes';
import { useMedplum, useResource } from '@medplum/react-hooks';
import { IconCheck, IconCloudUpload, IconFileAlert, IconMessage } from '@tabler/icons-react';
import { ReactNode, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import classes from './ResourceTimeline.module.css';
import {
  AttachmentButton,
  AttachmentDisplay,
  DiagnosticReportDisplay,
  Form,
  Panel,
  ResourceAvatar,
  ResourceTable,
  Timeline,
  TimelineItem,
  TimelineItemProps,
  sortByDateAndPriority,
} from '@medplum/react';
import { ResourceDiffTable } from '../../../../../react/src/ResourceDiffTable/ResourceDiffTable';
import { Mention, MentionsInput } from 'react-mentions';
import mentionsInputStyle from './mentionsInputStyles';
import mentionStyle from './mentionStyles';
// import style from './style.module.css';

export interface ResourceTimelineMenuItemContext {
  readonly primaryResource: Resource;
  readonly currentResource: Resource;
  readonly reloadTimeline: () => void;
}

export interface ResourceTimelineProps<T extends Resource> {
  readonly value: T | Reference<T>;
  readonly loadTimelineResources: (
    medplum: MedplumClient,
    resourceType: ResourceType,
    id: string
  ) => Promise<PromiseSettledResult<Bundle>[]>;
  readonly createCommunication?: (resource: T, sender: ProfileResource, text: string) => Communication;
  readonly createMedia?: (resource: T, operator: ProfileResource, attachment: Attachment) => Media;
  readonly getMenu?: (context: ResourceTimelineMenuItemContext) => ReactNode;
  setTableNotesItems: any;
}

export function ResourceTimeline<T extends Resource>(props: ResourceTimelineProps<T>): JSX.Element {
  const medplum = useMedplum();
  const sender = medplum.getProfile() as ProfileResource;
  const inputRef = useRef<HTMLInputElement>(null);
  const resource = useResource(props.value);
  const [history, setHistory] = useState<Bundle>();
  const [items, setItems] = useState<Resource[]>([]);
  const loadTimelineResources = props.loadTimelineResources;
  const [result, setResult] = useState('');
  const [data, setData] = useState<{ id: string; display: string; user: any; reference: string }[]>([]);

  const itemsRef = useRef<Resource[]>(items);
  itemsRef.current = items;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const getUsers = async () => {
      const users = await medplum.search(
        'Patient',
        formatSearchQuery({
          resourceType: 'Patient',
          count: 1000,
          sortRules: [{ code: 'name', descending: false }],
        })
      );
      const patientData =
        users?.entry?.map((user: any) => ({
          id: user.resource.id,
          display: formatHumanName(user.resource.name?.[0]) + ' - Patient',
          user: user.resource,
          reference: `${user.resource.resourceType}/${user.resource.id}`,
        })) || [];

      const practitioners = await medplum.search(
        'Practitioner',
        formatSearchQuery({
          resourceType: 'Practitioner',
          count: 1000,
          sortRules: [{ code: 'name', descending: false }],
        })
      );

      const practitionerData =
        practitioners?.entry?.map((practitioner: any) => ({
          id: practitioner.resource.id,
          display: formatHumanName(practitioner.resource.name[0]) + ' - Practitioner',
          user: practitioner.resource,
          reference: `${practitioner.resource.resourceType}/${practitioner.resource.id}`,
        })) || [];

      const sortedData = [...patientData, ...practitionerData].sort((a, b) => a.display.localeCompare(b.display));
      setData(sortedData);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sorts and sets the items.
   *
   * Sorting is primarily a function of meta.lastUpdated, but there are special cases.
   * When displaying connected resources, for example a Communication in the context of an Encounter,
   * the Communication.sent time is used rather than Communication.meta.lastUpdated.
   *
   * Other examples of special cases:
   * - DiagnosticReport.issued
   * - Media.issued
   * - Observation.issued
   * - DocumentReference.date
   *
   * See "sortByDateAndPriority()" for more details.
   */
  const sortAndSetItems = useCallback(
    (newItems: Resource[]): void => {
      sortByDateAndPriority(newItems, resource);
      newItems.reverse();
      props?.setTableNotesItems(newItems);
      setItems(newItems);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resource]
  );

  /**
   * Handles a batch request response.
   * @param batchResponse - The batch response.
   */
  const handleBatchResponse = useCallback(
    (batchResponse: PromiseSettledResult<Bundle>[]): void => {
      const newItems = [];

      for (const settledResult of batchResponse) {
        if (settledResult.status !== 'fulfilled') {
          // User may not have access to all resource types
          continue;
        }

        const bundle = settledResult.value;
        if (bundle.type === 'history') {
          setHistory(bundle);
        }

        if (bundle.entry) {
          for (const entry of bundle.entry) {
            newItems.push(entry.resource as Resource);
          }
        }
      }

      sortAndSetItems(newItems);
    },
    [sortAndSetItems]
  );

  /**
   * Adds an array of resources to the timeline.
   * @param resource - Resource to add.
   */
  const addResource = useCallback(
    (resource: Resource): void => sortAndSetItems([...itemsRef.current, resource]),
    [sortAndSetItems]
  );

  /**
   * Loads the timeline.
   */
  const loadTimeline = useCallback(() => {
    let resourceType: ResourceType;
    let id: string;
    if ('resourceType' in props.value) {
      resourceType = props.value.resourceType;
      id = props.value.id as string;
    } else {
      [resourceType, id] = props.value.reference?.split('/') as [ResourceType, string];
    }
    loadTimelineResources(medplum, resourceType, id).then(handleBatchResponse).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => loadTimeline(), [loadTimeline]);

  /**
   * Adds a Communication resource to the timeline.
   * @param contentString - The comment content.
   */
  function createComment(contentString: string): void {
    if (!resource || !props.createCommunication) {
      // Encounter not loaded yet
      return;
    }
    medplum
      .createResource(props.createCommunication(resource, sender, contentString))
      .then((result) => {
        addResource(result);
        setResult('');
      })
      .catch(console.error);
  }

  /**
   * Adds a Media resource to the timeline.
   * @param attachment - The media attachment.
   */
  function createMedia(attachment: Attachment): void {
    if (!resource || !props.createMedia) {
      // Encounter not loaded yet
      return;
    }
    medplum
      .createResource(props.createMedia(resource, sender, attachment))
      .then((result) => addResource(result))
      .then(() =>
        updateNotification({
          id: 'upload-notification',
          color: 'teal',
          title: 'Upload complete',
          message: '',
          icon: <IconCheck size={16} />,
          autoClose: 2000,
        })
      )
      .catch((reason) =>
        updateNotification({
          id: 'upload-notification',
          color: 'red',
          title: 'Upload error',
          message: normalizeErrorString(reason),
          icon: <IconFileAlert size={16} />,
          autoClose: 2000,
        })
      );
  }

  function onUploadStart(): void {
    showNotification({
      id: 'upload-notification',
      loading: true,
      title: 'Initializing upload...',
      message: 'Please wait...',
      autoClose: false,
      withCloseButton: false,
    });
  }

  function onUploadProgress(e: ProgressEvent): void {
    updateNotification({
      id: 'upload-notification',
      loading: true,
      title: 'Uploading...',
      message: getProgressMessage(e),
      autoClose: false,
      withCloseButton: false,
    });
  }

  function onUploadError(outcome: OperationOutcome): void {
    updateNotification({
      id: 'upload-notification',
      color: 'red',
      title: 'Upload error',
      message: normalizeErrorString(outcome),
      icon: <IconFileAlert size={16} />,
      autoClose: 2000,
    });
  }

  if (!resource) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  return (
    <Timeline>
      {props.createCommunication && (
        <Panel>
          <Form
            testid="timeline-form"
            // onSubmit={(formData: Record<string, string>) => {
            onSubmit={() => {
              // createComment(formData.text);
              // const input = inputRef.current;
              // if (input) {
              //   input.value = '';
              //   input.focus();
              // }

              if (result.trim().length > 0) {
                createComment(result);
              }
            }}
          >
            <Group gap="xs" wrap="nowrap" style={{ width: '100%' }}>
              <ResourceAvatar value={sender} />
              {/* <TextInput
                name="text"
                ref={inputRef}
                placeholder="Add comment"
                style={{ width: '100%', maxWidth: 300 }}
              /> */}
              {/* <Editor /> */}
              <MentionsInput
                value={result}
                onChange={(e: { target: { value: SetStateAction<string> } }) => setResult(e.target.value)}
                // className="mention-input-parent mantine-Input-input mantine-TextInput-input"
                style={{
                  maxWidth: '300px',
                  width: '100%',
                  ...mentionsInputStyle,
                }}
                inputRef={inputRef}
                className="mention-input-parent"
              >
                <Mention
                  style={mentionStyle}
                  data={data}
                  trigger={'@'}
                  appendSpaceOnAdd={true}
                  // renderSuggestion={(suggestion: { display?: any; id?: any }) =>
                  //   customMentionRender({ ...suggestion, role: 'Patient' })
                  // }
                  // displayTransform={(id, display) => {
                  //   return `${display}`;
                  //   // return customMentionRender({ display, id, role: 'Patient' });
                  // }}
                />
              </MentionsInput>

              <ActionIcon type="submit" radius="xl" color="blue" variant="filled">
                <IconMessage size={16} />
              </ActionIcon>
              <AttachmentButton
                securityContext={createReference(resource)}
                onUpload={createMedia}
                onUploadStart={onUploadStart}
                onUploadProgress={onUploadProgress}
                onUploadError={onUploadError}
              >
                {(props) => (
                  <ActionIcon {...props} radius="xl" color="blue" variant="filled">
                    <IconCloudUpload size={16} />
                  </ActionIcon>
                )}
              </AttachmentButton>
            </Group>
          </Form>
        </Panel>
      )}
      {items.map((item) => {
        if (!item) {
          // TODO: Handle null history items for deleted versions.
          return null;
        }
        const key = `${item.resourceType}/${item.id}/${item.meta?.versionId}`;
        const menu = props.getMenu
          ? props.getMenu({
              primaryResource: resource,
              currentResource: item,
              reloadTimeline: loadTimeline,
            })
          : undefined;
        if (item.resourceType === resource.resourceType && item.id === resource.id) {
          return <HistoryTimelineItem key={key} history={history as Bundle} resource={item} popupMenuItems={menu} />;
        }
        switch (item.resourceType) {
          case 'AuditEvent':
            return <AuditEventTimelineItem key={key} resource={item} popupMenuItems={menu} />;
          case 'Communication':
            return <CommunicationTimelineItem key={key} resource={item} popupMenuItems={menu} data={data} />;
          case 'DiagnosticReport':
            return <DiagnosticReportTimelineItem key={key} resource={item} popupMenuItems={menu} />;
          case 'Media':
            return <MediaTimelineItem key={key} resource={item} popupMenuItems={menu} />;
          default:
            return (
              <TimelineItem key={key} resource={item} padding={true}>
                <ResourceTable value={item} ignoreMissingValues={true} />
              </TimelineItem>
            );
        }
      })}
    </Timeline>
  );
}

interface HistoryTimelineItemProps extends TimelineItemProps {
  readonly history: Bundle;
}

function HistoryTimelineItem(props: HistoryTimelineItemProps): JSX.Element {
  const { history, resource, ...rest } = props;
  const previous = getPrevious(history, resource);
  if (previous) {
    return (
      <TimelineItem resource={resource} padding={true} {...rest}>
        <ResourceDiffTable original={previous} revised={props.resource} />
      </TimelineItem>
    );
  } else {
    return (
      <TimelineItem resource={resource} padding={true} {...rest}>
        <h3>Created</h3>
        <ResourceTable value={resource} ignoreMissingValues forceUseInput />
      </TimelineItem>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
function getPrevious(history: Bundle, version: Resource): Resource | undefined {
  const entries = history.entry as BundleEntry[];
  const index = entries.findIndex((entry) => entry.resource?.meta?.versionId === version.meta?.versionId);
  if (index >= entries.length - 1) {
    return undefined;
  }
  return entries[index + 1].resource;
}

function CommunicationTimelineItem(props: TimelineItemProps<Communication> & { data: any[] }): JSX.Element {
  const routine = !props.resource.priority || props.resource.priority === 'routine';
  const className = routine ? undefined : classes.pinnedComment;

  const value: string = props.resource.payload?.[0]?.contentString || '';

  const UserMentionPopover = ({
    text,
    data,
  }: {
    text: string;
    data: { display: string; id: string; reference: string }[];
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  }) => {
    // Function to parse the text and find mentions
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const parseText = (text: string) => {
      const regex = /@\[(.*?)\]\((.*?)\)/g; // Matches the pattern @[Name - Role](userId)
      const parts = [];
      let lastIndex = 0;

      // Match all mentions in the text
      let match;
      while ((match = regex.exec(text)) !== null) {
        // Add text before the match
        if (match.index > lastIndex) {
          parts.push(text.slice(lastIndex, match.index));
        }

        // Extract the user details from the match
        const nameAndRole = match[1]; // "Alice Smith - Practitioner"
        const userId = match[2]; // userId (35156126-6979-4bd6-aeb8-bfcdc18251ee)

        // Find the user details by ID
        const user = data.find((u) => u.id === userId);

        if (user) {
          // Add the mention with the popover
          parts.push(
            <Popover position="top" withArrow shadow="md">
              <Popover.Target>
                <span style={{ cursor: 'pointer', backgroundColor: '#cee4e5', color: 'black' }}>{nameAndRole}</span>
              </Popover.Target>
              <Popover.Dropdown>
                <Group gap="xs" wrap="nowrap" style={{ width: '100%' }}>
                  <ResourceAvatar value={user} />
                  <Text size="sm">
                    <Anchor
                      target="_blank"
                      href={`${user.reference}/details`}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      {user.display}
                    </Anchor>
                  </Text>
                </Group>
              </Popover.Dropdown>
            </Popover>
          );
        }

        lastIndex = regex.lastIndex;
      }

      // Add the remaining text after the last match
      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }

      return parts;
    };

    return <Text>{parseText(text)}</Text>;
  };

  return (
    <TimelineItem
      resource={props.resource}
      profile={props.resource.sender}
      dateTime={props.resource.sent}
      padding={true}
      className={className}
      popupMenuItems={props.popupMenuItems}
    >
      <UserMentionPopover text={value} data={props.data} />
      {/* <p>{props.resource.payload?.[0]?.contentString}</p> */}
      {/* <MentionsInput
        // value={parseText(value)}
        value={value}
        // className="mention-input-parent mantine-Input-input mantine-TextInput-input"
        // style={{
        //   maxWidth: '300px',
        //   width: '100%',
        //   ...mentionsInputStyle,
        // }}
        style={{
          border: 'none',
          outline: 'none',
        }}
        classNames={style}
        readOnly
        className="read-only-mention-input-parent"
      >
        <Mention
          style={mentionStyle}
          data={props?.data || []}
          trigger={'@'}
          appendSpaceOnAdd={true}
          // markup="@[__display__](user:__id__)"
        />
      </MentionsInput> */}
    </TimelineItem>
  );
}

function MediaTimelineItem(props: TimelineItemProps<Media>): JSX.Element {
  const contentType = props.resource.content?.contentType;
  const padding =
    contentType &&
    !contentType.startsWith('image/') &&
    !contentType.startsWith('video/') &&
    contentType !== 'application/pdf';
  return (
    <TimelineItem resource={props.resource} padding={!!padding} popupMenuItems={props.popupMenuItems}>
      <AttachmentDisplay value={props.resource.content} />
    </TimelineItem>
  );
}

function AuditEventTimelineItem(props: TimelineItemProps<AuditEvent>): JSX.Element {
  return (
    <TimelineItem resource={props.resource} padding={true} popupMenuItems={props.popupMenuItems}>
      <ScrollArea>
        <pre>{props.resource.outcomeDesc}</pre>
      </ScrollArea>
    </TimelineItem>
  );
}

function DiagnosticReportTimelineItem(props: TimelineItemProps<DiagnosticReport>): JSX.Element {
  return (
    <TimelineItem resource={props.resource} padding={true} popupMenuItems={props.popupMenuItems}>
      <DiagnosticReportDisplay value={props.resource} />
    </TimelineItem>
  );
}

function getProgressMessage(e: ProgressEvent): string {
  if (e.lengthComputable) {
    const percent = (100 * e.loaded) / e.total;
    return `Uploaded: ${formatFileSize(e.loaded)} / ${formatFileSize(e.total)} ${percent.toFixed(2)}%`;
  }
  return `Uploaded: ${formatFileSize(e.loaded)}`;
}

function formatFileSize(bytes: number): string {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
}
