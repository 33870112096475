import { Event } from 'react-big-calendar';
import {
  Modal,
  Text,
  Group,
  Button,
  Select,
  Drawer,
  Title,
  Card,
  Stack,
  ActionIcon,
  Popover,
  Grid,
} from '@mantine/core';
import {
  IconMapPin,
  IconClipboardList,
  IconEdit,
  IconCalendarUser,
  IconTrash,
  IconGenderFemale,
  IconGenderMale,
} from '@tabler/icons-react';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Appointment, Bundle, Patient, Resource, ResourceType } from '@medplum/fhirtypes';
import { useDisclosure } from '@mantine/hooks';
import { sortByDateAndPriority, useMedplum } from '@medplum/react';
import { createReference, formatHumanName, MedplumClient } from '@medplum/core';
import { TimelinePage } from '../../components/dialogs/PatientTimeline';
import { showNotification } from '@mantine/notifications';
import { Appointment as ScheduleAppointment } from './SchedulePage';
import dayjs from 'dayjs';
import PatientDetails from './PatientDetails';
import { useUser } from '../../user.context';

interface AppointmentModalProps {
  appointment: Event | null; // Appointment;
  onClose: () => void;
  onUpdate: (appointment: Appointment) => void;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
  setAllAppointments: Dispatch<SetStateAction<ScheduleAppointment[]>>;
  updateSurgeryHandler: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  setUpdateAppointment: Dispatch<SetStateAction<Event | null>>;
}

const InsuranceStatus = ({
  resource,
  // setFetchAppointments,
  patient,
  setAllAppointments,
  canUpdate,
}: {
  resource: Resource;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
  patient: any;
  setAllAppointments: Dispatch<SetStateAction<ScheduleAppointment[]>>;
  canUpdate: boolean;
}): JSX.Element => {
  const [insuranceStatus, setInsuranceStatus] = useState(resource?.insuranceStatus || 'Unverified');
  const medplum = useMedplum();

  const { profile, setUtilsData } = useUser();

  const handleUpdate = async (): Promise<void> => {
    const otherExtensions =
      resource?.extension?.filter(
        (ext: { url: string }) => ext.url !== 'http://medplum.com/fhir/StructureDefinition/insurance-status'
      )[0]?.valueString || [];

    const updatedExtension = {
      url: 'http://medplum.com/fhir/StructureDefinition/insurance-status',
      valueString: insuranceStatus,
    };

    const updatedAppointment = {
      ...resource,
      extension: [...otherExtensions, updatedExtension],
    };

    delete updatedAppointment?.insuranceStatus;

    const updatedPatient = await medplum.updateResource(updatedAppointment);

    const updatedPatientDetails = { ...updatedPatient, insuranceStatus };

    const updatedItem = { ...patient, patientDetails: updatedPatientDetails };

    setUtilsData((prev) => {
      return {
        ...prev,
        allPatients: prev?.allPatients?.map((p: any) => (p?.id === updatedPatient?.id ? updatedPatientDetails : p)),
      };
    });

    setAllAppointments((prev) => {
      return prev?.map((app) =>
        app.resource.patient.reference === patient.reference
          ? {
              ...app,
              resource: {
                ...app.resource,
                patient: updatedItem,
              },
            }
          : app
      );
    });

    // Create note on change status

    const mentionUser = profile?.name && profile.name.length > 0 ? formatHumanName(profile.name[0]) : null;

    const text = `Insurance status has been changed to ${insuranceStatus} ${mentionUser ? `by @[${mentionUser} - ${profile?.resourceType}](${profile?.id})` : ''}`;

    await medplum.createResource({
      resourceType: 'Communication',
      status: 'completed',
      subject: createReference(updatedPatient),
      sender: createReference(profile),
      sent: new Date().toISOString(),
      payload: [{ contentString: text }],
    });

    // setInsuranceStatus(insuranceStatus);
    showNotification({ color: 'green', message: 'Insurance status changed successfully!' });
    // setFetchAppointments((prev) => !prev);
  };

  return (
    <>
      <Select
        label="Insurance Status"
        data={['Verified', 'Unverified']}
        value={insuranceStatus}
        onChange={(e) => setInsuranceStatus(e || '')}
        readOnly={!canUpdate}
        disabled={!canUpdate}
      />
      {canUpdate && (
        <Group align="right" mt="md">
          <Button onClick={handleUpdate}>Save</Button>
        </Group>
      )}
    </>
  );
};

const AppointmentModal = ({
  appointment,
  onClose,
  setFetchAppointments,
  setAllAppointments,
  updateSurgeryHandler,
  setUpdateAppointment,
}: AppointmentModalProps): JSX.Element => {
  const { location, patient, notes = [], reasonCode = [] } = appointment?.resource || {};
  const { patientDetails } = patient;
  const patientId = patient.actor.reference;
  const resourceType = 'Patient';
  const reference = { reference: resourceType + '/' + patientId };

  const [opened, { open, close }] = useDisclosure(false);
  const [patientDetailsOpened, patientDetailsHandler] = useDisclosure(false);
  const [items, setItems] = useState<Resource[]>(notes);
  const [popoverOpened, setPopoverOpened] = useState(false);

  const medplum = useMedplum();

  const { pDeleteSurgery, pUpdatePatient, pUpdateSurgery } = useUser();

  // const resource = useResource(reference);

  const loadTimelineResources = useCallback((medplum: MedplumClient, resourceType: ResourceType, id: string) => {
    const ref = `${resourceType}/${id}`;
    const _count = 1000;
    return Promise.allSettled([
      // medplum.readHistory('Patient', id),
      medplum.search('Communication', { subject: ref, _count }),
      // medplum.search('Device', { patient: ref, _count }),
      // medplum.search('DeviceRequest', { patient: ref, _count }),
      // medplum.search('DiagnosticReport', { subject: ref, _count }),
      medplum.search('Media', { subject: ref, _count }),
      // medplum.search('ServiceRequest', { subject: ref, _count }),
      // medplum.search('Task', { subject: ref, _count }),
    ]);
  }, []);

  const sortAndSetItems = useCallback(
    (newItems: Resource[]): void => {
      sortByDateAndPriority(newItems, patientDetails);
      newItems.reverse();
      // setItems(newItems);
    },
    [patientDetails]
  );

  const handleBatchResponse = useCallback(
    (batchResponse: PromiseSettledResult<Bundle>[]): void => {
      const newItems = [];

      for (const settledResult of batchResponse) {
        if (settledResult.status !== 'fulfilled') {
          // User may not have access to all resource types
          continue;
        }

        const bundle = settledResult.value;
        // console.log('bundle', bundle);
        // if (bundle.type === 'history') {
        //   setHistory(bundle);
        // }

        if (bundle.entry) {
          for (const entry of bundle.entry) {
            newItems.push(entry.resource as Resource);
          }
        }
      }

      sortAndSetItems(newItems);
    },
    [sortAndSetItems]
  );

  const loadTimeline = useCallback(() => {
    let resourceType: ResourceType;
    let id: string;
    if ('resourceType' in reference) {
      resourceType = reference.resourceType;
      id = reference.reference?.split('/')[1] as string;
    } else {
      [resourceType, id] = reference.reference?.split('/') as [ResourceType, string];
    }
    loadTimelineResources(medplum, resourceType, id).then(handleBatchResponse).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => loadTimeline(), [loadTimeline]);

  const { birthDate } = patient.patientDetails;

  const dob = birthDate ? dayjs(birthDate).format('DD MMM YY') : null;

  let reason = '';

  if ((reasonCode?.length ?? 0) > 0) {
    reason = reasonCode?.[0]?.coding?.[0].code ?? '';
  }

  const deleteEncounterByAppointmentId = async (appointmentId: string): Promise<void> => {
    try {
      const encounterSearch = await medplum.searchResources('Encounter', {
        appointment: `Appointment/${appointmentId}`,
      });

      console.log('encounterSearch', encounterSearch.bundle);

      encounterSearch.bundle.entry?.forEach(async (entry: any) => {
        console.log('entry', entry.resource);
        const encounterId = entry.resource.id;
        const deleteResponse = await medplum.deleteResource('Encounter', encounterId);

        console.log(`Encounter with ID ${encounterId} deleted successfully`, deleteResponse);
      });
    } catch (error) {
      console.error('Error deleting Encounter:', error);
    }
  };

  const handleDelete = async (): Promise<void> => {
    try {
      await medplum.deleteResource('Appointment', appointment?.resource?.id);

      // delete encounter associated with appointment as well

      await deleteEncounterByAppointmentId(appointment?.resource?.id);

      showNotification({ color: 'green', message: 'Appointment deleted successfully!' });
      setFetchAppointments((prev) => !prev);
      setPopoverOpened(false);
      onClose();
      close();
    } catch (error) {
      console.error('Error deleting item', error);
      showNotification({ color: 'red', message: 'Error in deleting appointment!' });
    }
  };

  type IconType = typeof IconGenderFemale;

  function getGenderIcon(patient?: Patient): IconType | undefined {
    switch (patient?.gender) {
      case 'female':
        return IconGenderFemale;
      case 'male':
        return IconGenderMale;
      default:
        return undefined;
    }
  }

  const GenderIconComponent = getGenderIcon(patient?.patientDetails);

  return (
    <>
      {opened && (
        <Drawer
          opened={opened}
          onClose={close}
          title={<Title pl={15}>{`Notes for ${patient?.display}`}</Title>}
          position="right"
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          size="lg"
          transitionProps={{ transition: 'rotate-right', duration: 150, timingFunction: 'linear' }}
        >
          <TimelinePage id={patient.actor.reference} setItems={setItems} />
        </Drawer>
      )}
      {patientDetailsOpened && (
        <Modal
          opened={!!appointment}
          onClose={patientDetailsHandler.close}
          size="auto"
          centered
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
          inset={3}
          zIndex={111}
          className="appointment-detail-modal"
        >
          <PatientDetails patient={patient.patientDetails} notesModelOpen={open} />
        </Modal>
      )}
      <Modal
        opened={!!appointment}
        onClose={onClose}
        size="auto"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        inset={3}
        zIndex={111}
        className="appointment-detail-modal"
        title={
          <Group gap="xs" align="flex-end">
            {pUpdateSurgery && (
              <>
                <ActionIcon variant="transparent" color="dark">
                  <IconEdit
                    size={18}
                    onClick={() => {
                      updateSurgeryHandler.open();
                      setUpdateAppointment(appointment);
                    }}
                  />
                </ActionIcon>
                {/* <ActionIcon variant="subtle" color="dark">
                  <IconTrash size={18} />
                </ActionIcon> */}
                {pDeleteSurgery && (
                  <Popover
                    opened={popoverOpened}
                    onClose={() => setPopoverOpened(false)}
                    position="bottom"
                    withArrow
                    shadow="md"
                    width={'min-content'}
                  >
                    <Popover.Target>
                      <ActionIcon
                        variant="subtle"
                        color="dark"
                        onClick={() => setPopoverOpened((o) => !o)}
                        title="Delete"
                      >
                        <IconTrash size={18} />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Grid>
                        <Grid.Col span={12}>
                          <Text size="sm" w={500} mb="sm">
                            Are you sure you want to delete this appointment?
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <Group justify="end">
                            <Button variant="outline" size="xs" color="gray" onClick={() => setPopoverOpened(false)}>
                              Cancel
                            </Button>
                            <Button variant="filled" size="xs" color="red" onClick={handleDelete}>
                              Confirm
                            </Button>
                          </Group>
                        </Grid.Col>
                      </Grid>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </>
            )}
          </Group>
        }
      >
        <Card
          radius="md"
          px={{
            xs: '0px',
            sm: 'xl',
          }}
        >
          <Group gap={5}>
            {GenderIconComponent && <GenderIconComponent size={20} color={'grey'} />}
            <Text fw={700}>
              {GenderIconComponent && ` | `}
              {formatHumanName(patient?.patientDetails?.name?.[0])}
              {dob && ` | ${dob}`}
              {reason && ` | ${reason}`}
            </Text>
          </Group>

          {/* Date and Time */}
          <Text size="sm" mt="xs" color="dimmed">
            {dayjs(appointment?.start)?.format('dddd, MMMM D • h:mm a')} -{' '}
            {dayjs(appointment?.start).add(1, 'hour')?.format('h:mm a')}
          </Text>

          {/* Contact Details */}
          <Stack gap="xs" mt="md">
            {/* Phone Number */}
            <Group gap="xs">
              <IconMapPin size={16} />
              <Text size="sm">{location?.display}</Text>
            </Group>

            {/* Notes */}
            <Group gap="xs">
              <IconClipboardList size={16} />
              <Text size="sm" onClick={open} style={{ cursor: 'pointer' }}>
                {items.length > 0 ? `View ${items.length} Note${items.length > 1 ? 's' : ''}` : `No notes available`}
              </Text>
            </Group>

            {/* Insurance Information */}
            <Group gap="xs">
              <IconCalendarUser size={16} />
              <Text size="sm">{appointment?.resource?.practitioner?.display}</Text>
            </Group>

            <Group gap="xs">
              <IconCalendarUser size={16} opacity={0} />
              <Text size="sm" style={{ cursor: 'pointer' }} onClick={patientDetailsHandler.open}>
                <Text>View Patient Details</Text>
              </Text>
            </Group>

            {/* Surgery Details */}
            {/* <Divider my="xs" />
            <Text size="sm">DECOOK SURGERY*</Text>
            <Text size="xs" color="dimmed">
              Created by: Heather Ward
            </Text> */}
            <InsuranceStatus
              resource={patientDetails}
              patient={patient}
              setFetchAppointments={setFetchAppointments}
              setAllAppointments={setAllAppointments}
              canUpdate={pUpdatePatient}
            />
          </Stack>
        </Card>

        {/* <Button onClick={open} variant="transparent" mb={20}>
          {items.length > 0 ? `View ${items.length} Note${items.length > 1 ? 's' : ''}` : `No notes available`}
        </Button>
        {patient?.display && (
          <Text mb={10}>
            <b>Patient Name:</b> {patient?.display}
          </Text>
        )}
        {appointment?.patientDOB && (
          <Text mb={10}>
            <b>DOB:</b> {appointment?.patientDOB}
          </Text>
        )}
        <Text mb={10}>
          <b>Surgery Type:</b> {appointment?.surgeryType}
        </Text>
        {location?.display && (
          <Text mb={10}>
            <b>Location:</b> {location?.display}
          </Text>
        )}
        <InsuranceStatus
          resource={patientDetails}
          setUtilsData={setUtilsData}
          patient={patient}
          setFetchAppointments={setFetchAppointments}
          setAllAppointments={setAllAppointments}
          canUpdate={canUpdate}
        /> */}
      </Modal>
    </>
  );
};

export default AppointmentModal;
