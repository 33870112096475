import { Grid, Loader } from '@mantine/core';
import { PatientSummary } from './PatientSummary/PatientSummary';
import { Patient, Reference } from '@medplum/fhirtypes';
import { useEffect, useState } from 'react';
import { useMedplum } from '@medplum/react';
// import { PatientDetails } from '../../components/PatientDetails';
// import { PatientActions } from '../../components/PatientActions';

const PatientDetails = ({ patient, notesModelOpen }: { patient: Patient; notesModelOpen: () => void }): JSX.Element => {
  const [patientData, setPatientData] = useState<Patient | Reference<Patient>>(patient);
  const medplum = useMedplum();
  // const patient = useResource<Patient>({ reference: `Patient/${id}` });

  const fetchData = async (): Promise<void> => {
    const Patient = await medplum.readResource('Patient', patient?.id as string);

    if (!Patient) {
      return;
    }

    setPatientData(Patient);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!patient) {
    return <Loader />;
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <PatientSummary patient={patientData} fetchData={fetchData} notesModelOpen={notesModelOpen} />
      </Grid.Col>
      {/* <Grid.Col span={5}>
        <PatientDetails patient={patient} />
      </Grid.Col>
      <Grid.Col span={3}>
        <Document p="xs">
          <PatientActions patient={patient} />
        </Document>
      </Grid.Col> */}
    </Grid>
  );
};

export default PatientDetails;
