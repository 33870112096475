import { Button, Text, Modal, Grid } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import InviteMember from './InviteUser';
import { useUser } from '../../user.context';
import { GroupedAvatar } from './GroupAvatar';
import AssociatedUsers from './AssociatedUser';

interface HeaderProps {
  selectedDate: Date;
  view: 'day' | 'week' | 'month';
}

const NavHeader: React.FC<HeaderProps> = ({ selectedDate, view }): JSX.Element => {
  const { pInviteUser, associatedStaffs, associatedSurgeons, allAssociatedUsers, userProjectMembership } = useUser();
  const [opened, { open, close }] = useDisclosure(false);
  const [associatedUsersOpened, associatedUsersHandler] = useDisclosure(false);

  const start = dayjs(selectedDate).startOf('week').format('MMM DD, YYYY');
  const end = dayjs(selectedDate).endOf('week').format('MMM DD, YYYY');

  let avatarProfiles = [];
  const isSurgeon = userProjectMembership?.authRole === 'Surgeon';
  const isStaff = userProjectMembership?.authRole === 'Staff';
  const isAdmin = userProjectMembership?.admin || false;

  if (isAdmin) {
    avatarProfiles = allAssociatedUsers.map((staff) => staff.practitionerDetails);
  } else if (isSurgeon) {
    avatarProfiles = associatedStaffs.map((staff) => staff.receiverDetails.profileDetails);
  } else if (isStaff) {
    avatarProfiles = associatedSurgeons.map((staff) => staff.practitionerDetails);
  }

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        // title={
        //   <Text variant="h3" mt={1} color={'primary.dark'}>
        //     Invite User
        //   </Text>
        // }
        size="lg"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <InviteMember close={close} />
      </Modal>
      <Modal
        opened={associatedUsersOpened}
        onClose={associatedUsersHandler.close}
        title={
          <Text variant="h3" mt={1} color={'primary.dark'}>
            {userProjectMembership?.authRole === 'Surgeon' && 'Associated Users'}
            {userProjectMembership?.authRole === 'Staff' && 'Associated Surgeons'}
            {userProjectMembership?.admin && 'Associated Users'}
          </Text>
        }
        size="xl"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <AssociatedUsers />
      </Modal>
      <Grid
        align="center"
        justify="space-between"
        gutter={{
          xs: '0px',
          sm: 'sm',
          md: 'md',
          lg: 'lg',
          xl: 'xl',
        }}
        px={8}
        py={10}
      >
        {/* Title Section */}
        <Grid.Col span={{ xs: 8, sm: 8, md: 7, lg: 8, xl: 8 }}>
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 600 }}>
            {view === 'day' && `Schedules for ${dayjs(selectedDate).format('MMM DD, YYYY')}`}
            {view === 'week' && `Schedules for ${start} - ${end}`}
            {view === 'month' && `Schedules for ${dayjs(selectedDate).format('MMMM')}`}
          </Text>
          <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>Create and complete tasks using boards</p>
        </Grid.Col>

        {/* Avatars and Invite Button */}
        <Grid.Col
          span={{ xs: 4, sm: 4, md: 5, lg: 4, xl: 4 }}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          {/* Avatar Group */}
          <GroupedAvatar profiles={avatarProfiles} onClick={associatedUsersHandler.open} />

          {/* Invite Button */}
          {pInviteUser && (
            <Button
              variant="filled"
              color="dark"
              style={{
                marginLeft: '10px',
                backgroundColor: '#002238',
                padding: '2px 15px',
                fontSize: '14px',
                fontWeight: 600,
              }}
              leftSection={<IconPlus size={16} color="#fff" />}
              onClick={open}
            >
              Invite
            </Button>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export { NavHeader };
