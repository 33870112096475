import { Avatar, AvatarGroup, Button, Text, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { InviteMember } from './InviteUser';
import { useUser } from '../../user.context';

interface HeaderProps {
  selectedDate: Date;
  view: 'day' | 'week' | 'month';
}

const NavHeader: React.FC<HeaderProps> = ({ selectedDate, view }): JSX.Element => {
  const [opened, { open, close }] = useDisclosure(false);

  const { pInviteUser } = useUser();

  const start = dayjs(selectedDate).startOf('week').format('MMM DD, YYYY');
  const end = dayjs(selectedDate).endOf('week').format('MMM DD, YYYY');

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        // title={
        //   <Text variant="h3" mt={1} color={'primary.dark'}>
        //     Invite User
        //   </Text>
        // }
        size="auto"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <InviteMember />
      </Modal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 10px',
        }}
      >
        {/* Title Section */}
        <div>
          <Text style={{ margin: 0, fontSize: '16px', fontWeight: 600 }}>
            {view === 'day' && `Schedules for ${dayjs(selectedDate).format('MMM DD, YYYY')}`}
            {view === 'week' && `Schedules for ${start} - ${end}`}
            {view === 'month' && `Schedules for ${dayjs(selectedDate).format('MMMM')}`}
          </Text>
          <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>Create and complete tasks using boards</p>
        </div>

        {/* Avatars and Invite Button */}
        {pInviteUser && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Avatar Group */}
            <AvatarGroup spacing="sm">
              <Avatar src="https://randomuser.me/api/portraits/women/2.jpg" alt="User 1" radius="xl" size="md" />
              <Avatar src="https://randomuser.me/api/portraits/men/3.jpg" alt="User 2" radius="xl" size="md" />
              <Avatar
                radius="xl"
                size="md"
                style={{ backgroundColor: '#fff', color: '#000', fontSize: '14px', fontWeight: 600 }}
              >
                5
              </Avatar>
            </AvatarGroup>

            {/* Invite Button */}
            <Button
              variant="filled"
              color="dark"
              style={{
                marginLeft: '10px',
                backgroundColor: '#002238',
                padding: '2px 15px',
                fontSize: '14px',
                fontWeight: 600,
              }}
              leftSection={<IconPlus size={16} color="#fff" />}
              onClick={open}
            >
              Invite
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export { NavHeader };
