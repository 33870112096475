import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { ReactElement, useEffect } from 'react';
// import { FhirClient } from '@zapehr/sdk';
import { Location } from 'fhir/r4';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../user.context';
// import { useApiClients } from '../hooks/useAppClients';

type CustomFormEventHandler = (event: React.FormEvent<HTMLFormElement>, value: any, field: string) => void;

interface LocationSelectProps {
  location?: Location | undefined;
  setLocation: (location: Location | undefined) => void;
  updateURL?: boolean;
  storeLocationInLocalStorage?: boolean;
  required?: boolean;
  queryParams?: URLSearchParams;
  handleSubmit?: CustomFormEventHandler;
  renderInputProps?: Partial<AutocompleteRenderInputParams>;
}

export default function LocationSelect({
  queryParams,
  location,
  handleSubmit,
  setLocation,
  updateURL,
  storeLocationInLocalStorage,
  required,
  renderInputProps,
}: LocationSelectProps): ReactElement {
  // const { fhirClient } = useApiClients();
  const navigate = useNavigate();
  const locationHandler = useLocation();
  const { locationOptions, utilsData } = useUser();
  const { allLocations } = utilsData;

  useEffect(() => {
    if (updateURL && localStorage.getItem('selectedLocation')) {
      queryParams?.set('location', JSON.parse(localStorage.getItem('selectedLocation') ?? '')?.id);
      navigate(`?${queryParams?.toString()}`);
    }
  }, [navigate, queryParams, updateURL]);

  const handleLocationChange = (event: any, newValue: any): void => {
    const selectedLocation = newValue
      ? allLocations.find((locationTemp) => locationTemp.id === newValue.value)
      : undefined;
    console.log('selected location in handle location change', selectedLocation);
    if (selectedLocation?.id) {
      localStorage.setItem('selectedLocation', selectedLocation.id);
    } else {
      localStorage.removeItem('selectedLocation');
      localStorage.removeItem('selectedLocationID');
      const queryParams = new URLSearchParams(locationHandler.search);
      queryParams.delete('location');
      setTimeout(() => {
        navigate(`?${queryParams?.toString()}`);
      }, 1000);
    }
    setLocation(selectedLocation);

    if (storeLocationInLocalStorage) {
      if (newValue) {
        localStorage.setItem('selectedLocation', JSON.stringify(selectedLocation));
      } else {
        localStorage.removeItem('selectedLocation');
      }
    }

    if (handleSubmit) {
      handleSubmit(event, selectedLocation, 'location');
    }
  };

  return (
    <Autocomplete
      disabled={renderInputProps?.disabled}
      value={location ? { label: location.name, value: location?.id } : null}
      onChange={handleLocationChange}
      isOptionEqualToValue={(option, tempValue) => option.value === tempValue.value}
      options={locationOptions}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField placeholder="Search office" name="location" {...params} label="Office" required={required} />
      )}
    />
  );
}
