import { Dispatch, SetStateAction, useCallback } from 'react';
import dayjs from 'dayjs';
import { Calendar, dayjsLocalizer, Event } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Avatar, Box, Button, Divider, Grid, Group, Pill, Text, Tooltip } from '@mantine/core';
import './calendar.css';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import AppointmentModal from './appointmentsDetails';
import { NavHeader } from './Header';
import AppointmentNotesDrawer from './notes';
// import { unverifiedLogo } from '../../assets';
import { Appointment as ScheduleAppointment } from './SchedulePage';
// import { IconEdit } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Patient } from '@medplum/fhirtypes';
import { IconAlertCircleFilled, IconCircleCheckFilled, IconGenderFemale, IconGenderMale } from '@tabler/icons-react';
import { formatHumanName } from '@medplum/core';
import { useUser } from '../../user.context';

// const localizer = dayjsLocalizer(dayjs);

interface CalendarViewProps {
  // events: Event[];
  // onSelectEvent: Dispatch<SetStateAction<Event | null>>;
  slots: { title: string; start: Date; end: Date; resource: any }[];
  appointments: { title: string; start: Date; end: Date; resource: any }[];
  selectedEvent: Event | null;
  setSelectedEvent: Dispatch<SetStateAction<Event | null>>;
  view: 'day' | 'week' | 'month';
  setView: Dispatch<SetStateAction<'day' | 'month' | 'week'>>;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  open: () => void;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
  setAllAppointments: Dispatch<SetStateAction<ScheduleAppointment[]>>;
  setUpdateAppointment: Dispatch<SetStateAction<any>>;
  updateSurgeryHandler: {
    readonly open: () => void;
    readonly close: () => void;
    readonly toggle: () => void;
  };
  setDefaultSlot: Dispatch<SetStateAction<string | undefined>>;
}

const CalendarView: React.FC<CalendarViewProps> = ({
  // events,
  // onSelectEvent,
  slots,
  // appointments,
  selectedEvent,
  setSelectedEvent,
  view,
  setView,
  selectedDate,
  // setSelectedDate,
  open,
  setFetchAppointments,
  setAllAppointments,
  setUpdateAppointment,
  updateSurgeryHandler,
  setDefaultSlot,
}) => {
  const [createSlotOpened, createSlotHandlers] = useDisclosure(false);
  const [notesDrawerOpened, notesDrawerHandlers] = useDisclosure(false);
  const isMobile = useMediaQuery(`(max-width: 1023px)`);

  const { pAddSurgery } = useUser();

  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!notesDrawerOpened || !createSlotOpened) {
  //     setFetchAppointments((prev) => !prev);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notesDrawerOpened, createSlotOpened]);

  // Converts Slot resources to big-calendar Event objects
  // Only show free and busy-unavailable slots
  // const slotEvents: Event[] = (slots ?? [])
  //   .filter((slot: { status: string }) => slot.status === 'free' || slot.status === 'busy-unavailable')
  //   .map((slot: { status: string; start: string | number | Date; end: string | number | Date }) => ({
  //     title: slot.status === 'free' ? 'Available' : 'Blocked',
  //     start: new Date(slot.start),
  //     end: new Date(slot.end),
  //     resource: slot,
  //   }));

  // Converts Appointment resources to big-calendar Event objects
  // const appointmentEvents: Event[] = (appointments ?? []).map(
  //   (appointment: { participant: any[]; status: string; start: string; end: string }) => {
  //     // Find the patient among the participants to use as title
  //     const patientParticipant = appointment?.participant?.find((p: any) => p.actor?.reference?.startsWith('Patient/'));
  //     const status = !['booked', 'arrived', 'fulfilled'].includes(appointment.status as string)
  //       ? ` (${appointment.status})`
  //       : '';

  //     return {
  //       title: `${patientParticipant?.actor?.display} ${status}`,
  //       start: new Date(appointment.start as string),
  //       end: new Date(appointment.end as string),
  //       resource: appointment,
  //     };
  //   }
  // );

  // When a slot is selected set the event object and open the modal
  const handleSelectSlot = useCallback(
    (event: Event) => {
      setSelectedEvent(event);
      createSlotHandlers.open();
    },
    [createSlotHandlers, setSelectedEvent]
  );

  // When an exiting event is selected set the event object and open the modal
  const handleSelectEvent = useCallback(
    (event: Event) => {
      if (event.resource.resourceType === 'Slot') {
        // If it's a slot open the management modal
        setSelectedEvent(event);
        createSlotHandlers.open();
      } else if (event.resource.resourceType === 'Appointment') {
        // If it's an appointment navigate to the appointment detail page
        // navigate(`/Appointment/${event.resource.id}`);
      }
    },
    [createSlotHandlers, setSelectedEvent]
  );

  // const localizer = dateFnsLocalizer({
  //   format: (date: string | number | Date | dayjs.Dayjs | null | undefined, format: string | undefined, culture: any) =>
  //     dayjs(date).format(format),
  //   parse: (
  //     value: string | number | Date | dayjs.Dayjs | null | undefined,
  //     format: dayjs.OptionType | undefined,
  //     culture: any
  //   ) => dayjs(value, format).toDate(),
  //   startOfWeek: () => dayjs().startOf('week').toDate(),
  //   getDay: (date: string | number | Date | dayjs.Dayjs | null | undefined) => dayjs(date).day(),
  // });
  type IconType = typeof IconGenderFemale;

  function getGenderIcon(patient?: Patient): IconType | undefined {
    switch (patient?.gender) {
      case 'female':
        return IconGenderFemale;
      case 'male':
        return IconGenderMale;
      default:
        return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const CustomDayView = ({ date, events }: { date: Date; events: Event[]; localizer: any }) => {
    // Generate hourly slots for the day
    // const hours = Array.from({ length: 24 }, (_, i) => dayjs(date).hour(i).minute(0).second(0).toDate());

    // Morning range: 9 AM to 12 PM
    const morningHours = Array.from({ length: 4 }, (_, i) =>
      dayjs(date)
        .hour(9 + i)
        .minute(0)
        .second(0)
        .toDate()
    );

    // console.log('morningHours', morningHours);

    // Afternoon-to-next-morning range: 1 PM to 8 AM
    const afternoonHours = Array.from({ length: 20 }, (_, i) => {
      if (i < 12) {
        i = 13 + i;
      } else if (i >= 8) {
        i = i - 11;
      }

      return dayjs(date).hour(i).minute(0).second(0).toDate();
    });

    // console.log('afternoonHours', afternoonHours);

    // Combine the two ranges
    const hours = [...morningHours, ...afternoonHours];

    // Filter events for the current day
    const dayEvents = events.filter((event: Event) => dayjs(event.start).isSame(date, 'day'));

    return (
      <Box
        p={{
          xs: '0px',
          sm: '20px',
        }}
      >
        {hours.map((hour, index) => {
          const hourEvents = dayEvents.filter((event: Event) => event.start && dayjs(event.start).isSame(hour, 'hour'));
          return (
            <div
              key={index}
              style={{
                padding: '0px 10px',
                margin: '20px 0px',
                display: 'flex',
                flexDirection: 'column',
              }}
              className="appointment-parent-div"
            >
              <div style={{ fontWeight: 'bold', display: 'flex', gap: '10px', height: 'auto' }}>
                <div className="time-div">{dayjs(hour).format('hA')}</div>
                <div className="add-btn-div">
                  {pAddSurgery && hourEvents.length === 0 && (
                    <button
                      className="ActionButton"
                      // onClick={open}
                      onClick={() => {
                        // Convert the Date object to a Luxon DateTime object
                        const dateTime = DateTime.fromJSDate(hour, { zone: 'UTC' }).setZone('Asia/Karachi').set({
                          millisecond: 0,
                          second: 0,
                        });
                        // Format the DateTime object to the desired string format
                        const formattedDate = dateTime.toISO() || undefined;
                        setDefaultSlot(formattedDate);
                        open();
                      }}
                    >
                      + Add Schedules
                    </button>
                  )}
                </div>
              </div>

              <div style={{ fontWeight: 'bold', display: 'flex', gap: '10px' }}>
                {!isMobile && <div style={{ opacity: 0 }}>{dayjs(hour).format('hA')}</div>}
                <div style={{ width: '100%' }}>
                  {hourEvents.map((event) => {
                    const { practitioner, patient, notes = [], reasonCode = [] } = event.resource;
                    const pracAvatarName = practitioner?.display
                      ?.split(' ')
                      .map((name: any[]) => name[0])
                      .join('');

                    const GenderIconComponent = getGenderIcon(patient?.patientDetails);

                    const pracBgColor = event.resource?.practitioner?.backgroundColor;
                    const pracColor = event.resource?.practitioner?.color;
                    const bgColor = '#C8DBE9';
                    const color = '#000000';
                    // const isUnverified = patient?.insuranceStatus !== 'Verified';

                    let reason = '';

                    if ((reasonCode?.length ?? 0) > 0) {
                      reason = reasonCode?.[0]?.coding?.[0].code ?? '';
                    }

                    const isVerified = patient?.patientDetails?.insuranceStatus === 'Verified';

                    return (
                      <Grid
                        bg={bgColor}
                        color={color}
                        my={10}
                        py={10}
                        px={10}
                        w="100%"
                        style={{
                          borderRadius: '5px',
                          border: '1px solid #006DB1',
                          cursor: 'pointer',
                        }}
                        justify="space-between"
                        align="start"
                        grow
                      >
                        <Grid.Col span={{ xs: 12, sm: 10, md: 10, lg: 10, xl: 10 }} order={{ xs: 1, sm: 1 }} pb={0}>
                          <div
                            onClick={() => {
                              setSelectedEvent(event);
                              handleSelectSlot(event);
                            }}
                          >
                            <div style={{ fontWeight: 'bold', fontSize: '14px', color, display: 'flex' }}>
                              <Group gap={5} mb={10}>
                                {GenderIconComponent && <GenderIconComponent size={20} color={color} />}
                                <Text fw={700}>
                                  {GenderIconComponent && ` | `}
                                  {patient?.patientDetails
                                    ? formatHumanName(patient?.patientDetails?.name?.[0])
                                    : patient?.display}
                                  {reason && ` | ${reason}`}
                                </Text>
                              </Group>
                            </div>
                            <div
                              style={{
                                // color,
                                fontSize: '13px',
                                fontWeight: '600',
                                color: '#666666',
                                display: 'flex',
                              }}
                            >
                              {`${dayjs(event.start).format('h:mm A')} - ${dayjs(event.end).format('h:mm A')}`}
                            </div>
                          </div>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }} order={{ xs: 3, sm: 2 }}>
                          {/* <Button variant="transparent" color={color}>
                                {canUpdate && (
                                  <IconEdit
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      updateSurgeryHandler.open();
                                      setUpdateAppointment(event);
                                    }}
                                  />
                                )}
                              </Button> */}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              paddingRight: '10px',
                            }}
                          >
                            {isVerified ? (
                              <IconCircleCheckFilled size={26} color="#00b32d" />
                            ) : (
                              <Tooltip label="Please Verify Insurance">
                                <IconAlertCircleFilled size={26} color="#ff5100" />
                                {/* <Avatar
                                    src={unverifiedLogo}
                                    size="xs"
                                    // my={4}
                                    // mx={10}
                                    style={{
                                      objectFit: 'fill',
                                    }}
                                  /> */}
                              </Tooltip>
                            )}
                          </div>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 10, sm: 8, md: 8, lg: 9, xl: 10 }} order={{ xs: 2, sm: 3 }}>
                          <Group mt={5} gap={10}>
                            <Avatar
                              src={practitioner?.photo}
                              alt={practitioner?.display}
                              radius="xl"
                              size="sm"
                              bg={pracBgColor}
                            >
                              {pracAvatarName}
                            </Avatar>

                            <Pill size="md" bg={pracBgColor} color={pracColor}>
                              {practitioner?.display}
                            </Pill>
                          </Group>
                        </Grid.Col>
                        <Grid.Col span={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 1 }} order={{ xs: 4, sm: 4 }}>
                          <Button
                            variant="transparent"
                            color={color}
                            onClick={(e) => {
                              e.stopPropagation();
                              notesDrawerHandlers.open();
                              setSelectedEvent(event);
                            }}
                            px={0}
                          >
                            {notes.length > 0 ? `View ${notes.length} Note${notes.length > 1 ? 's' : ''}` : `Add Note`}
                          </Button>
                        </Grid.Col>
                      </Grid>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </Box>
    );
  };

  // Add the required static methods
  CustomDayView.title = (date: Date, { localizer }: any) => {
    return localizer.format(date, 'dayHeaderFormat');
  };

  CustomDayView.navigate = (date: Date, action: any) => {
    switch (action) {
      case 'PREV':
        return dayjs(date).subtract(1, 'day').toDate();
      case 'NEXT':
        return dayjs(date).add(1, 'day').toDate();
      default:
        return date;
    }
  };

  CustomDayView.allDay = false;

  return (
    <>
      {notesDrawerOpened && selectedEvent && (
        <AppointmentNotesDrawer
          appointment={selectedEvent}
          onClose={notesDrawerHandlers.close}
          onUpdate={notesDrawerHandlers.close}
          opened={notesDrawerOpened}
          setFetchAppointments={setFetchAppointments}
        />
      )}
      {createSlotOpened && selectedEvent && (
        <AppointmentModal
          appointment={selectedEvent}
          onClose={createSlotHandlers.close}
          onUpdate={createSlotHandlers.close}
          setFetchAppointments={setFetchAppointments}
          setAllAppointments={setAllAppointments}
          updateSurgeryHandler={updateSurgeryHandler}
          setUpdateAppointment={setUpdateAppointment}
        />
      )}
      <div style={{ flex: 1, padding: '20px' }} className="calendar-section-parent">
        <div style={{ border: '1px solid #5FB6E6', padding: '12px 16px', borderRadius: '16px' }}>
          <NavHeader selectedDate={selectedDate} view={view} />
          <Divider my={20} />
          <Calendar
            defaultView="day"
            className={`custom-calendar-view-${view}`}
            view={view}
            // views={['day', 'week', 'month']}
            views={{
              day: CustomDayView,
              week: true,
              month: true,
            }}
            onView={(e) => setView(e as 'day' | 'week' | 'month')}
            localizer={dayjsLocalizer(dayjs)}
            events={slots}
            date={selectedDate}
            backgroundEvents={slots}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            style={{ minHeight: 600 }}
            selectable
            toolbar={false}
            // timeslots={1}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={(event) => {
              return {
                style: {
                  backgroundColor: event.resource?.practitioner?.backgroundColor,
                  color: event.resource?.practitioner?.color,
                },
              };
            }}
            // formats={{
            //   timeGutterFormat: (date, culture, localizer) => dayjs(date).format('h A'),
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarView;
