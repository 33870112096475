import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
// import { FhirClient } from '@zapehr/sdk';
import { Practitioner } from 'fhir/r4';
import { useUser } from '../../user.context';
// import { useApiClients } from '../hooks/useAppClients';

type CustomFormEventHandler = (event: React.FormEvent<HTMLFormElement>, value: any, field: string) => void;

interface PractitionerSelectProps {
  practitioner?: Practitioner | undefined;
  setPractitioner: (practitioner: any) => void;
  // setPractitioner: Dispatch<SetStateAction<Practitioner | undefined>>
  updateURL?: boolean;
  storeLocationInLocalStorage?: boolean;
  required?: boolean;
  queryParams?: URLSearchParams;
  handleSubmit?: CustomFormEventHandler;
  renderInputProps?: Partial<AutocompleteRenderInputParams>;
}

export default function PractitionerSelect({
  queryParams,
  practitioner,
  handleSubmit,
  setPractitioner,
  updateURL,
  storeLocationInLocalStorage,
  required,
  renderInputProps,
}: PractitionerSelectProps): ReactElement {
  const navigate = useNavigate();
  const practitionerHandler = useLocation();
  const { surgeonOptions, utilsData } = useUser();
  const { allPractitioners } = utilsData;

  useEffect(() => {
    if (updateURL && localStorage.getItem('selectedPractitioner')) {
      queryParams?.set('practitioner', JSON.parse(localStorage.getItem('selectedPractitioner') ?? '')?.id);
      navigate(`?${queryParams?.toString()}`);
    }
  }, [navigate, queryParams, updateURL]);

  const handlePractitionerChange = (event: any, newValue: any): void => {
    const selectedPractitioner = newValue
      ? allPractitioners.find((practitionerTemp) => practitionerTemp.id === newValue.value)
      : undefined;
    console.log('selected practitioner in handle practitioner change', selectedPractitioner);
    if (selectedPractitioner?.id) {
      localStorage.setItem('selectedPractitioner', selectedPractitioner.id);
    } else {
      localStorage.removeItem('selectedPractitioner');
      localStorage.removeItem('selectedPractitionerID');
      const queryParams = new URLSearchParams(practitionerHandler.search);
      queryParams.delete('practitioner');
      setTimeout(() => {
        navigate(`?${queryParams?.toString()}`);
      }, 1000);
    }
    setPractitioner(selectedPractitioner);

    if (storeLocationInLocalStorage) {
      if (newValue) {
        localStorage.setItem('selectedPractitioner', JSON.stringify(selectedPractitioner));
      } else {
        localStorage.removeItem('selectedPractitioner');
      }
    }

    if (handleSubmit) {
      handleSubmit(event, selectedPractitioner, 'practitioner');
    }
  };

  return (
    <Autocomplete
      disabled={renderInputProps?.disabled}
      size={renderInputProps?.size}
      value={
        practitioner
          ? {
              label: `${practitioner?.name?.[0]?.prefix?.[0] ?? ''} ${practitioner?.name?.[0]?.given?.[0] ?? ''} ${practitioner?.name?.[0]?.family ?? ''}`,
              value: practitioner?.id,
            }
          : null
      }
      onChange={handlePractitionerChange}
      isOptionEqualToValue={(option, tempValue) => option.value === tempValue.value}
      options={surgeonOptions}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField placeholder="Search surgeon" name="practitioner" {...params} label="Surgeon" required={required} />
      )}
    />
  );
}
