// import { Space } from '@mantine/core';
import {
  // AKLoginRequest,
  // LoginAuthenticationResponse,
  MEDPLUM_VERSION,
  MedplumClient,
  Operator,
  ProfileResource,
  SearchRequest,
  capitalize,
  createReference,
  formatCodeableConcept,
  formatSearchQuery,
  getExtension,
  getReferenceString,
  normalizeErrorString,
} from '@medplum/core';
import { Schedule, UserConfiguration, Questionnaire, Practitioner } from '@medplum/fhirtypes';
import {
  AppShellV2,
  ErrorBoundary,
  Loading,
  NavbarLink,
  NavbarMenu,
  useMedplum,
  useMedplumProfile,
} from '@medplum/react';
import {
  IconCalendar,
  IconBrandAsana,
  IconBuilding,
  IconCalendarEvent,
  IconClipboard,
  IconForms,
  IconId,
  IconLock,
  IconLockAccess,
  IconMicroscope,
  IconPackages,
  IconReceipt,
  IconReportMedical,
  IconStar,
  IconWebhook,
  IconClipboardHeart,
  IconClipboardList,
  // IconHealthRecognition,
  // IconQuestionMark,
  // IconFilePencil,
  IconUser,
  IconGridDots,
  IconCategory,
  // IconChecklist,
  // IconDatabaseImport,
  // IconMail,
  // IconNurse,
  // IconRibbonHealth,
  // IconRobot,
  IconLocation,
} from '@tabler/icons-react';
import { FunctionComponent, SetStateAction, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
// import Cookies from 'js-cookie';
import '@mantine/dates/styles.css';

import './App.css';
// import { SignUpPage } from './SignUpPage';
import { RegisterPage } from './RegisterPage';
import { ScheduleContext } from './Schedule.context';
import { SchedulePage } from './pages/schedule/SchedulePage';

// Charting
// import { EncounterPage } from './charting/pages/EncounterPage';
// import { LandingPage } from './charting/pages/LandingPage';
// import { PatientPage } from './charting/pages/PatientPage';
// import { ResourcePage } from './charting/pages/ResourcePage';
// import { SearchPage } from './charting/pages/SearchPage';
// import { SignInPage } from './charting/pages/SignInPage';
// import { UploadDataPage } from './charting/pages/UploadDataPage';

// Patient Intake
import { IntakeFormPage } from './patient-intake/pages/IntakeFormPage';
import { IntakeResponsePage } from './patient-intake/pages/IntakeResponsePage';
import { PatientPage } from './patient-intake/pages/PatientPage';
// import { QuestionnaireCustomizationPage } from './patient-intake/pages/QuestionnaireCustomizationPage';
// import { ResourcePage } from './patient-intake/pages/ResourcePage';
// import { SearchPage } from './patient-intake/pages/SearchPage';
// import { SignInPage } from './patient-intake/pages/SignInPage';
import { UploadDataPage } from './patient-intake/pages/UploadDataPage';
import { IntakeQuestionnaireContext } from '../src/patient-intake/Questionnaire.context';
import { IntakeFormPagePublic } from './pages/patient-intake-form-public';
import { myAppLogo } from './assets';
import { IntakeFormPageUpdate } from './patient-intake/pages/IntakeFormPageUpdate';
import { CreateResourcePage as PatientCreateResourcePage } from './pages/patient/CreateResourcePage';
import { FormCreatePage as PatientFormCreatePage } from './patient/FormCreatePage';
import { JsonCreatePage as PatientJsonCreatePage } from './patient/JsonCreatePage';
import { SelectOrganization } from './pages/selectOrgization';
import { LoadingOverlay, Space } from '@mantine/core';
import { SearchPage } from './charting/pages/SearchPage';
import { ResourcePage } from './tasks/src/pages/ResourcePage';
import { TaskPage } from './tasks/src/pages/TaskPage';
import { LandingPage } from './tasks/src/pages/LandingPage';
import { UploadDataPage as TaskUploadDataPage } from './tasks/src/pages/UploadDataPage';
import SetupDefaultLocations from './setup-default-locations';
import { PatientIntakeForm } from './patient-intake-form';
import PrivacyPage from './pages/privacy';
// import InviteUsersList from './pages/schedule/InviteUsersList';
import { SignInPage } from './SignInPage';
import { ResetPasswordPage } from './ResetPasswordPage';
import { SetPasswordPage } from './SetPasswordPage';
import { ChangePasswordPage } from './ChangePasswordPage';
import { LoggedInUserTimeline } from './loggedInuserTimeline';
import { UserProvider } from './user.context';
import GuestUsersPage from './pages/GuestUsers';

const SEARCH_TABLE_FIELDS = ['code', 'owner', 'for', 'priority', 'due-date', '_lastUpdated', 'performerType'];
const ALL_TASKS_LINK = {
  icon: <IconGridDots />,
  label: 'All Tasks',
  href: `/Task?_fields=${SEARCH_TABLE_FIELDS.join(',')}`,
};

export function App(): JSX.Element {
  const medplum = useMedplum();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const profile = useMedplumProfile();
  const [searchParams] = useSearchParams();
  const userLogged = medplum.getActiveLogin();
  const userPolicy = medplum.getProjectMembership();
  const [schedule, setSchedule] = useState<Schedule | undefined>();
  const [intakeQuestionnaire, setIntakeQuestionnaire] = useState<Questionnaire | undefined>(undefined);
  const [userLinks, setUserLinks] = useState<NavbarLink[]>([]);
  const showLoadingOverlay = profile && (medplum.isLoading() || userLinks.length === 0);
  const { pathname } = location;

  // const authMethod = Cookies.get('mb_authentik_method');
  // const authentikAuthToken = Cookies.get('mb_authentik_token');

  // const authMethod = Cookies.get('mb_authentik_method') || 'authentik';
  // const authentikAuthToken = Cookies.get('mb_authentik_token') || '123456';

  // Update the sidebar links associated with the Medplum profiles
  useEffect(() => {
    const profileReferenceString = profile && getReferenceString(profile);

    if (!profileReferenceString) {
      return;
    }

    // Construct the search for "My Tasks"
    const myTasksLink = getMyTasksLink(profileReferenceString);

    // Query the user's `PractitionerRole` resources to find all applicable roles
    getTasksByRoleLinks(medplum, profileReferenceString)
      .then((roleLinks) => {
        setUserLinks([myTasksLink, ...roleLinks, ...stateLinks, ALL_TASKS_LINK]);
      })
      .catch((error) => console.error('Failed to fetch PractitionerRoles', normalizeErrorString(error)));

    // Construct Search links for all Tasks for patients in the current user's licensed states
    const stateLinks = getTasksByState(profile as Practitioner);
  }, [profile, medplum]);

  // const handleAuthResponse = useCallback(
  //   (response: LoginAuthenticationResponse): void => {
  //     if (response.code) {
  //       medplum.processCode(response.code).then(console.log).catch(console.log);
  //     }
  //   },
  //   [medplum]
  // );
  // const loginCode: AKLoginRequest = {
  //   authentikAuthToken: authentikAuthToken as string,
  //   authMethod: authMethod as string,
  // };

  // const getData = (): void => {
  //   if (userLogged) {
  //     console.log('Logged in');
  //   } else if (loginCode.authentikAuthToken) {
  //     medplum
  //       .startAKLogin(loginCode)
  //       .then(handleAuthResponse)
  //       .catch((err) => console.log('err', err));
  //   }
  // };

  // useEffect(() => {
  //   getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (medplum.isLoading() || !profile) {
      return;
    }

    // medplum
    //   .searchOne('Questionnaire', { name: 'patient-intake', _sort: '-_lastUpdated' })
    //   .then((intakeQuestionnaire: SetStateAction<Questionnaire | undefined>) => {
    //     console.log('intakeQuestionnaire', intakeQuestionnaire);
    //     setIntakeQuestionnaire(intakeQuestionnaire);
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //   });

    medplum
      .searchOne('Schedule', { actor: getReferenceString(profile) })
      .then((foundSchedule: SetStateAction<Schedule | undefined>) => {
        if (foundSchedule) {
          setSchedule(foundSchedule);
        } else {
          medplum
            .createResource({
              resourceType: 'Schedule',
              actor: [createReference(profile)],
              active: true,
            })
            .then((value: any) => setSchedule(value as Schedule))
            .catch((err: any) => {
              console.log(err);
            });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [medplum, profile]);

  if (medplum.isLoading()) {
    return <Loading />;
  }

  const pathnameLower = pathname.toLowerCase();
  const isTasksRoute = pathnameLower.includes('task') || pathnameLower.includes('diagnosticreport');

  return (
    <UserProvider>
      <AppShellV2
        logo={
          <div
            style={{
              width: '160px',
            }}
          >
            <img src={myAppLogo} height={'100%'} width={'100%'} />
          </div>
        }
        pathname={pathname}
        searchParams={searchParams}
        version={MEDPLUM_VERSION}
        menus={userConfigToMenu(config, userPolicy, profile, intakeQuestionnaire, userLinks)}
        displayAddBookmark={!!config?.id}
        notifications={userLogged && <LoggedInUserTimeline />}
      >
        {userLogged ? (
          <ScheduleContext.Provider value={{ schedule: schedule }}>
            <IntakeQuestionnaireContext.Provider
              value={{ questionnaire: intakeQuestionnaire, setQuestionnaire: setIntakeQuestionnaire }}
            >
              <ErrorBoundary>
                <LoadingOverlay visible={showLoadingOverlay} />
                <Suspense fallback={<Loading />}>
                  <Routes>
                    {/* Tasks Route */}
                    <Route path="/privacy-policy" element={<PrivacyPage />} />
                    <Route path="/home" element={<SchedulePage />} />
                    <Route path="/users" element={<GuestUsersPage />} />
                    {isTasksRoute && (
                      <>
                        <Route path="/" element={profile ? <Navigate to={ALL_TASKS_LINK.href} /> : <LandingPage />} />
                        <Route path="/:resourceType" element={<SearchPage />} />
                        <Route path="/:resourceType/:id/*" element={<ResourcePage />} />
                        <Route path="/Task/:id/*" element={<TaskPage />} />
                        <Route path="/Task" element={<SearchPage />} />
                        <Route path="/Tasks/upload/:dataType" element={<TaskUploadDataPage />} />
                      </>
                    )}

                    <Route path="/Location/upload" element={<SetupDefaultLocations />} />
                    {/* Patient Intake routes */}
                    <Route path="/Patient/new" element={<PatientCreateResourcePage />}>
                      <Route index element={<PatientFormCreatePage />} />
                      <Route path="form" element={<PatientFormCreatePage />} />
                      <Route path="json" element={<PatientJsonCreatePage />} />
                      <Route path="profiles" element={<PatientFormCreatePage />} />
                    </Route>
                    <Route path="/Patient/:id/*" element={<PatientPage />} />
                    <Route path="/Patient/:patientId/intake" element={<IntakeFormPage />} />
                    <Route path="/Patient/:patientId/intake/:responseId" element={<IntakeResponsePage />} />
                    <Route path="/Patient/:patientId/intake/:responseId/edit" element={<IntakeFormPageUpdate />} />
                    {/* <Route path="/:resourceType/:id/*" element={<ResourcePage />} />
                  <Route path="/:resourceType" element={<SearchPage />} /> */}
                    <Route path="/upload/:dataType" element={<UploadDataPage />} />
                    {/* <Route path="/Questionnaire/:questionnaireId/edit" element={<QuestionnaireCustomizationPage />} /> */}

                    <Route
                      path="/Schedule"
                      element={schedule ? <Navigate to={`/Schedule/${schedule.id}`} /> : <Loading />}
                    />
                    <Route path="/Schedule/:id" element={schedule ? <SchedulePage /> : <Loading />} />
                    {/* Charting routes */}
                    {/* <Route path="/Patient/:id/*" element={<PatientPage />} />
                  <Route path="/:resourceType/:id/*" element={<ResourcePage />} />
                  <Route path="/:resourceType" element={<SearchPage />} />
                  <Route path="/Encounter/:id/*" element={<EncounterPage />} />
                  <Route path="/upload/:dataType" element={<UploadDataPage />} /> */}
                    <Route path="*" element={<AppRoutes />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </IntakeQuestionnaireContext.Provider>
          </ScheduleContext.Provider>
        ) : (
          <Routes>
            {/* Patient Intake public route */}
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route path="/setpassword/:id/:secret" element={<SetPasswordPage />} />
            {/* <Route path="/get-started" element={<SignUpPage />} /> */}
            {/* <Route path="/register" element={<RegisterPage />} /> */}
            <Route path="/changepassword" element={<ChangePasswordPage />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/:organizationName" element={<IntakeFormPagePublic />} />
            <Route path="/select-organization" element={<SelectOrganization />} />
            {/* <Route path="/get-started" element={<SignUpPage />} /> */}
            <Route path="/intake-form" element={<PatientIntakeForm />} />
            <Route path="/register" element={<RegisterPage />} />
            {/* <Route path="/register" element={<RegisterPage getData={getData} />} /> */}
            {/* <Route path="*" element={<Navigate to="/get-started" />} /> */}
            <Route path="*" element={<Navigate to="/signin" />} />
          </Routes>
        )}
      </AppShellV2>
    </UserProvider>
  );
}

function userConfigToMenu(
  config: UserConfiguration | undefined,
  userPolicy: any,
  profile: ProfileResource | undefined,
  intakeQuestionnaire: Questionnaire | undefined,
  _userLinks: NavbarLink[] = []
): NavbarMenu[] {
  const result = [];

  const patientIntakeMenu = [
    {
      title: 'Charts',
      links: [{ icon: <IconUser />, label: 'Patients', href: '/Patient' }],
    },
    intakeQuestionnaire
      ? {
          // title: 'Management',
          // links: [
          //   {
          //     icon: <IconFilePencil />,
          //     label: 'Customize intake form',
          //     href: `/Questionnaire/${intakeQuestionnaire.id}/edit`,
          //   },
          // ],
        }
      : {},
    // {
    //   title: 'Upload Data',
    //   links: [
    //     { icon: <IconDatabaseImport />, label: 'Upload Core Data', href: '/upload/core' },
    //     //     { icon: <IconHealthRecognition />, label: 'Upload Example Patient Data', href: '/upload/example' },
    //     //     { icon: <IconRobot />, label: 'Upload Example Bots', href: '/upload/bots' },
    //   ],
    // },
  ];

  result.push(...patientIntakeMenu);

  // const tasksMenu = [
  //   {
  //     title: 'Tasks',
  //     links: userLinks,
  //   },
  //   {
  //     title: 'Upload Data',
  //     links: [
  //       { icon: <IconDatabaseImport />, label: 'Upload Core ValueSets', href: '/Tasks/upload/core' },
  //       { icon: <IconChecklist />, label: 'Upload Example Tasks', href: '/Tasks/upload/task' },
  //       { icon: <IconNurse />, label: 'Upload Example Certifications', href: '/Tasks/upload/role' },
  //       {
  //         icon: <IconRibbonHealth />,
  //         label: 'Upload Example Licenses',
  //         href: '/Tasks/upload/qualifications',
  //       },
  //       { icon: <IconRobot />, label: 'Upload Example Bots', href: '/Tasks/upload/bots' },
  //       { icon: <IconReportMedical />, label: 'Upload Example Report', href: '/Tasks/upload/report' },
  //       { icon: <IconMail />, label: 'Upload Example Messages', href: '/Tasks/upload/message' },
  //     ],
  //   },
  // ];

  // result.push(...tasksMenu);

  const customMenu = {
    title: 'Management',
    links: [
      {
        label: 'Practice',
        href: '/Practice',
        icon: <IconReceipt />,
      },
      {
        label: 'Location',
        href: '/Location',
        icon: <IconLocation />,
      },
      {
        label: 'Locations Upload',
        href: '/Location/upload',
        icon: <IconLocation />,
      },
      {
        label: 'Practitioner',
        href: '/Practitioner',
        icon: <IconReportMedical />,
      },
      // {
      //   label: 'Patient',
      //   href: '/Patient',
      //   icon: <IconCalendarEvent />,
      // },
      {
        label: 'Staff',
        href: '/Staff',
        icon: <IconPackages />,
      },
      {
        label: 'Organization',
        href: '/Organization',
        icon: <IconBuilding />,
      },
      {
        label: 'Intake Forms',
        href: '/Questionnaire',
        icon: <IconCalendarEvent />,
      },
      // {
      //   label: 'Manufacturer',
      //   href: '/Manufacturer',
      //   icon: <IconPackages />,
      // },
    ],
  };

  result.push(customMenu);

  const chartMenu = [
    // {
    //   title: 'Charts',
    //   links: [{ icon: <IconUser />, label: 'Patients', href: '/Patient' }],
    // },
    {
      title: 'Encounters',
      links: [
        { icon: <IconClipboardList />, label: 'All Encounters', href: '/Encounter' },
        {
          icon: <IconClipboardHeart />,
          label: 'My Encounters',
          href: `/Encounter?participant=Practitioner/${profile?.id}`,
        },
      ],
    },
    // {
    //   title: 'Upload Data',
    //   links: [
    //     { icon: <IconDatabaseImport />, label: 'Upload Core ValueSets', href: '/upload/core' },
    //     { icon: <IconQuestionMark />, label: 'Upload Questionnaires', href: '/upload/questionnaire' },
    //     { icon: <IconRobot />, label: 'Upload Example Bots', href: '/upload/bots' },
    //     { icon: <IconHealthRecognition />, label: 'Upload Example Patient Data', href: '/upload/example' },
    //   ],
    // },
  ];

  result.push(...chartMenu);

  const scheduleMenu = {
    title: 'Schedule',
    links: [
      {
        icon: <IconCalendar />,
        label: 'Schedule',
        href: '/Schedule',
      },
      {
        icon: <IconClipboard />,
        label: 'Queue System',
        href: '/Appointment',
      },
    ],
  };

  if (userPolicy?.admin) {
    // result.push(customMenu);
    result.push(scheduleMenu);
  } else if (userPolicy?.authRole === 'Practice') {
    const practiceMenu = {
      ...customMenu,
      links: customMenu.links.filter(
        (link) => link.label === 'Practitioner' || link.label === 'Patient' || link.label === 'Staff'
      ),
    };
    result.push(practiceMenu);
  } else if (userPolicy?.authRole === 'Practitioner') {
    const surgeonMenu = {
      ...customMenu,
      links: customMenu.links.filter((link) => link.label === 'Patient' || link.label === 'Staff'),
    };
    result.push(surgeonMenu);
    result.push(scheduleMenu);
  } else if (userPolicy?.authRole === 'Staff') {
    const staffMenu = {
      ...customMenu,
      links: customMenu.links.filter((link) => link.label === 'Patient'),
    };
    result.push(staffMenu);
  } else if (userPolicy?.authRole === 'Patient') {
    result.push(scheduleMenu);
  }

  const configCustom = {
    resourceType: 'UserConfiguration',
    menu: [
      {
        title: 'Admin',
        link: [
          {
            name: 'Project',
            target: '/admin/project',
          },
          {
            name: 'AccessPolicy',
            target: '/AccessPolicy',
          },
          {
            name: 'Subscriptions',
            target: '/Subscription',
          },
          {
            name: 'Batch',
            target: '/batch',
          },
          {
            name: 'Config',
            target: '/admin/config',
          },
        ],
      },
    ],
  };

  const adminMenus =
    configCustom?.menu?.map((menu) => ({
      title: menu.title,
      links:
        menu.link?.map((link) => ({
          label: link.name,
          href: link.target as string,
          icon: getIcon(link.target as string),
        })) || [],
    })) || [];

  result.push(...adminMenus);

  result.push({
    title: 'Settings',
    links: [
      {
        label: 'Security',
        href: '/security',
        icon: <IconLock />,
      },
    ],
  });

  return result;
}

/**
 * @param profileReference - string representing the current user's profile
 * @returns a NavBar link to a search for all open `Tasks` assigned to the current user
 */
function getMyTasksLink(profileReference: string): NavbarLink {
  const myTasksQuery = formatSearchQuery({
    resourceType: 'Task',
    fields: SEARCH_TABLE_FIELDS,
    sortRules: [{ code: '-priority-order,due-date' }],
    filters: [
      { code: 'owner', operator: Operator.EQUALS, value: profileReference },
      { code: 'status:not', operator: Operator.EQUALS, value: 'completed' },
    ],
  });

  const myTasksLink = { icon: <IconCategory />, label: 'My Tasks', href: `/Task${myTasksQuery}` };
  return myTasksLink;
}

/**
 * @param medplum - the MedplumClient
 * @param profileReference - string representing the current user's profile
 * @returns an array of NavBarLinks to searches for all open `Tasks` assigned to the current user's roles
 */
async function getTasksByRoleLinks(medplum: MedplumClient, profileReference: string): Promise<NavbarLink[]> {
  const roles = await medplum.searchResources('PractitionerRole', {
    practitioner: profileReference,
  });

  // Query the user's `PractitionerRole` resources to find all applicable roles
  return roles
    .map((role) => {
      // For each role, generate a link to all open Tasks
      const roleCode = role?.code?.[0];

      if (!roleCode?.coding?.[0]?.code) {
        return undefined;
      }

      const search: SearchRequest = {
        resourceType: 'Task',
        fields: SEARCH_TABLE_FIELDS,
        sortRules: [{ code: '-priority-order,due-date' }],
        filters: [
          { code: 'owner:missing', operator: Operator.EQUALS, value: 'true' },
          { code: 'status:not', operator: Operator.EQUALS, value: 'completed' },
          { code: 'performer', operator: Operator.EQUALS, value: roleCode?.coding?.[0]?.code },
        ],
      };

      const searchQuery = formatSearchQuery(search);
      const roleDisplay = formatCodeableConcept(roleCode);
      return { icon: <IconUser />, label: `${roleDisplay} Tasks`, href: `/Task${searchQuery}` } as NavbarLink;
    })
    .filter((link): link is NavbarLink => !!link);
}

/**
 *
 * Read all the states for which this practitioner is licensed.
 * Refer to [Modeling Provider Qualifications](https://www.medplum.com/docs/administration/provider-directory/provider-credentials)
 * for more information on how to represent a clinician's licenses
 * @param profile - The resource representing the current user
 * @returns an array of NavBarLinks to searches for all open `Tasks` assigned to patients' in states
 *          where the current user is licensed
 */
function getTasksByState(profile: Practitioner): NavbarLink[] {
  const myStates =
    profile.qualification
      ?.map(
        (qualification) =>
          getExtension(
            qualification,
            'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/practitioner-qualification',
            'whereValid'
          )?.valueCodeableConcept?.coding?.find((coding) => coding.system === 'https://www.usps.com/')?.code
      )
      .filter((state): state is string => !!state) ?? [];

  return myStates.map((state) => {
    const search: SearchRequest = {
      resourceType: 'Task',
      fields: SEARCH_TABLE_FIELDS,
      sortRules: [{ code: '-priority-order,due-date' }],
      filters: [
        { code: 'owner:missing', operator: Operator.EQUALS, value: 'true' },
        { code: 'status:not', operator: Operator.EQUALS, value: 'completed' },
        { code: 'patient.address-state', operator: Operator.EQUALS, value: state },
      ],
    };
    const searchQuery = formatSearchQuery(search);
    return { icon: <IconUser />, label: `${capitalize(state)} Tasks`, href: `/Task${searchQuery}` } as NavbarLink;
  });
}

const resourceTypeToIcon: Record<string, FunctionComponent> = {
  Practice: IconStar,
  Patient: IconStar,
  Practitioner: IconId,
  Organization: IconBuilding,
  ServiceRequest: IconReceipt,
  DiagnosticReport: IconReportMedical,
  Questionnaire: IconForms,
  admin: IconBrandAsana,
  AccessPolicy: IconLockAccess,
  Subscription: IconWebhook,
  batch: IconPackages,
  Observation: IconMicroscope,
};

function getIcon(to: string): JSX.Element | undefined {
  try {
    const resourceType = new URL(to, 'https://app.medplum.com').pathname.split('/')[1];
    if (resourceType in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[resourceType];
      return <Icon />;
    }
  } catch (_err) {
    // Ignore
  }
  return <Space w={30} />;
}
