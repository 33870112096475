import { Button, Group, Menu, Modal, MultiSelect, Table, Text, UnstyledButton } from '@mantine/core';
import { useUser } from '../../user.context';
import classes from './AssociatedUsers.module.css';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { createReference, formatHumanName } from '@medplum/core';
import { HumanName } from '@medplum/fhirtypes';

const AssociatedUsers = (): JSX.Element => {
  const {
    associatedStaffs,
    associatedSurgeons,
    setAssociatedStaff,
    setAssociatedSurgeons,
    setPractitionerReferences,
    practitionerReferences,
    userProjectMembership,
    getUtilsData,
    allAssociatedUsers,
    setAllAssociatedUsers,
    profile,
  } = useUser();

  const [removeModelOpened, removeModelHandler] = useDisclosure(false);
  const [selectUser, setSelectUser] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const medplum = useMedplum();

  const [selectedPractitioners, setSelectedPractitioners] = useState<any>([]);
  const [allUsers, setAllUsers] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [fields, setFields] = useState<{ name: string }[]>([]);
  const [allPractitioners, setAllPractitioners] = useState<{ label: string; value: string }[]>([]);

  const isSurgeon = userProjectMembership?.authRole === 'Surgeon';
  const isStaff = userProjectMembership?.authRole === 'Staff';
  const isAdmin = userProjectMembership?.admin || false;

  useEffect(() => {
    let relatedUsers: any = [];
    const fields = [{ name: 'Invited By' }];
    if (isAdmin) {
      relatedUsers = allAssociatedUsers;
      fields.push({ name: 'Surgeon' });
      fields.push({ name: 'User' });
    } else if (isSurgeon) {
      relatedUsers = associatedStaffs;
      fields.push({ name: 'User' });
    } else if (isStaff) {
      relatedUsers = associatedSurgeons;
      fields.push({ name: 'Surgeon' });
    }

    if (isAdmin || isSurgeon) {
      fields.push({ name: 'Action' });
    }

    const allPractitioners = relatedUsers
      .filter(
        (item: { practitioner: { reference: any } }, index: any, self: any[]) =>
          index ===
          self.findIndex(
            (t: { practitioner: { reference: any } }) => t.practitioner.reference === item.practitioner.reference
          )
      )
      .map((user: { practitioner: { display: any; reference: any } }) => ({
        label: user.practitioner.display,
        value: user.practitioner.reference,
      }));

    setAllPractitioners(allPractitioners);

    setFields(fields);
    setAllUsers(relatedUsers);
    setFilteredUsers(relatedUsers);
  }, [allAssociatedUsers, associatedStaffs, associatedSurgeons, isAdmin, isStaff, isSurgeon, setFilteredUsers]);

  useEffect(() => {
    if (selectedPractitioners.length > 0) {
      const filteredUsers = allUsers.filter((user: any) =>
        selectedPractitioners.some((selected: any) => selected === user.practitioner.reference)
      );
      setFilteredUsers(filteredUsers);
    } else {
      setFilteredUsers(allUsers);
    }
  }, [allUsers, selectedPractitioners]);

  const handleOpen = (resource: any): void => {
    removeModelHandler.open();
    setSelectUser(resource);
  };

  const handleClose = (): void => {
    removeModelHandler.close();
    setSelectUser(undefined);
    setDeleteLoading(false);
  };

  const handleConfirm = async (): Promise<void> => {
    setDeleteLoading(true);
    // Delete Calendar Access API call
    try {
      await medplum.deleteResource(selectUser.resourceType, selectUser.id);

      if (isSurgeon) {
        const updatedAssociatedStaffs = associatedStaffs.filter((staff) => staff.id !== selectUser.id);
        setAssociatedStaff(updatedAssociatedStaffs);
      }

      if (isStaff) {
        const updatedAssociatedSurgeons = associatedSurgeons.filter((staff) => staff.id !== selectUser.id);
        setAssociatedSurgeons(updatedAssociatedSurgeons);
      }

      const updatedPractitionerRef = practitionerReferences.filter((ref) => ref !== selectUser.practitioner.reference);

      setPractitionerReferences(updatedPractitionerRef);

      if (isAdmin) {
        const updatedAllAssociatedUsers = allAssociatedUsers.filter((staff) => staff.id !== selectUser.id);
        setAllAssociatedUsers(updatedAllAssociatedUsers);
      }

      showNotification({
        title: 'User removed successfully',
        message: 'User has been removed from the list',
        color: 'green',
      });
      setSelectUser(undefined);
      handleClose();
      await getUtilsData();

      // Create Communication Log for removed user
      const text = `You have been removed from surgeon ${selectUser.practitioner?.display}'s calendar by ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}`;

      await medplum.createResource({
        resourceType: 'Communication',
        status: 'completed',
        subject: selectUser.receiverDetails.profile,
        sender: createReference(profile),
        sent: new Date().toISOString(),
        payload: [{ contentString: text }],
      });

      if (isSurgeon) {
        // Create Communication Log for surgeon
        const text = `You removed the ${selectUser?.receiverDetails?.title} ${selectUser?.receiverDetails?.profile?.display} from your calendar.`;

        await medplum.createResource({
          resourceType: 'Communication',
          status: 'completed',
          subject: createReference(profile),
          sender: createReference(profile),
          sent: new Date().toISOString(),
          payload: [{ contentString: text }],
        });
      }

      if (isAdmin) {
        // Create Communication Log for admin
        const text = `You removed the ${selectUser?.receiverDetails?.title} ${selectUser?.receiverDetails?.profile?.display} from surgeon ${selectUser.practitioner?.display}'s calendar.`;

        await medplum.createResource({
          resourceType: 'Communication',
          status: 'completed',
          subject: createReference(profile),
          sender: createReference(profile),
          sent: new Date().toISOString(),
          payload: [{ contentString: text }],
        });

        // Create Communication Log for surgeon by admin
        const payloadText = `${selectUser?.receiverDetails?.title} ${selectUser?.receiverDetails?.profile?.display} has been removed from your calendar by admin ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}.`;

        await medplum.createResource({
          resourceType: 'Communication',
          status: 'completed',
          subject: selectUser.practitioner,
          sender: createReference(profile),
          sent: new Date().toISOString(),
          payload: [{ contentString: payloadText }],
        });
      }
    } catch (error) {
      console.error('Error removing calendar access', error);
      setSelectUser(undefined);
      showNotification({
        title: 'Error removing user',
        message: 'There was an error removing the user',
        color: 'red',
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal
        opened={removeModelOpened}
        onClose={removeModelHandler.close}
        title={'Remove Calendar'}
        centered
        size="lg"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Text>
          {isAdmin
            ? `Are you sure you want to remove surgeon ${selectUser?.practitioner?.display}'s calendar from ${selectUser?.receiverDetails?.title} ${selectUser?.receiverDetails?.profile?.display}?`
            : ''}
          {isSurgeon
            ? `Are you sure you want to remove guest ${selectUser?.receiverDetails?.profile?.display} from your calendar?`
            : ''}
        </Text>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
          <Button variant="outline" color="gray" onClick={handleClose} disabled={deleteLoading}>
            Cancel
          </Button>
          <Button color="red" onClick={handleConfirm} loading={deleteLoading}>
            Confirm
          </Button>
        </div>
      </Modal>
      {isAdmin && (
        <MultiSelect
          label="Filter by Surgeons"
          placeholder="Select Surgeons"
          data={allPractitioners}
          withScrollArea={false}
          styles={{ dropdown: { maxHeight: 200, overflowY: 'auto' } }}
          mb="md"
          onChange={setSelectedPractitioners}
          value={selectedPractitioners}
          searchable
          hidePickedOptions
          comboboxProps={{ shadow: 'md' }}
        />
      )}
      <Table className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            {fields.map((field) => (
              <Table.Th key={field.name} className={classes.tableHead}>
                <Menu shadow="md" width={240} position="bottom-end">
                  <Menu.Target>
                    <UnstyledButton className={classes.control} p={2}>
                      <Group justify="space-between" wrap="nowrap">
                        <Text className={classes.headTitle}>{field.name}</Text>
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                </Menu>
              </Table.Th>
            ))}
          </Table.Tr>
          {/* {!props.hideFilters && (
    <Table.Tr>
      {checkboxColumn && <Table.Th />}
      {fields.map((field) => (
        <Table.Th key={field.name}>
          {field.searchParams && (
            <FilterDescription
              resourceType={resourceType}
              searchParams={field.searchParams}
              filters={memoizedSearch.filters}
            />
          )}
        </Table.Th>
      ))}
    </Table.Tr>
  )} */}
        </Table.Thead>
        <Table.Tbody>
          {filteredUsers?.map(
            (resource: any) =>
              resource && (
                <>
                  <Table.Tr
                    key={resource.id}
                    className={classes.tr}
                    data-testid="search-control-row"
                    // onClick={(e) => handleRowClick(e, resource)}
                    // onAuxClick={(e) => handleRowClick(e, resource)}
                  >
                    {/* {checkboxColumn && (
                <Table.Td>
                  <input
                    type="checkbox"
                    value="checked"
                    data-testid="row-checkbox"
                    aria-label={`Checkbox for ${resource.id}`}
                    checked={!!state.selected[resource.id as string]}
                    onChange={(e) => handleSingleCheckboxClick(e, resource.id as string)}
                  />
                </Table.Td>
              )} */}

                    {fields.map((field) => {
                      if (field.name === 'Invited By') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.senderDetails?.profileDetails} size="sm" />
                              {resource?.senderDetails?.profile?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (field.name === 'Surgeon') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.practitionerDetails} size="sm" />
                              {resource?.practitioner?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (field.name === 'User') {
                        return (
                          <Table.Td key={field.name}>
                            <Group>
                              <ResourceAvatar value={resource?.receiverDetails?.profileDetails} size="sm" />
                              {resource?.receiverDetails?.profile?.display}
                            </Group>
                          </Table.Td>
                        );
                      }

                      if (
                        field.name === 'Action' &&
                        (isAdmin || (isSurgeon && resource?.receiverDetails?.title === 'Guest'))
                      ) {
                        return (
                          <Table.Td>
                            <Group onClick={() => handleOpen(resource)}>
                              <UnstyledButton className={classes.actionButton}>
                                <Text>Remove</Text>
                              </UnstyledButton>
                            </Group>
                          </Table.Td>
                        );
                      }

                      return <Table.Td key={field?.name}>{}</Table.Td>;
                    })}
                  </Table.Tr>
                </>
              )
          )}
        </Table.Tbody>
      </Table>
    </>
  );
};

export default AssociatedUsers;
