import { Box, Grid } from '@mui/material';
import { SignInForm, useMedplumProfile } from '@medplum/react';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getConfig, isRegisterEnabled } from './config';
import { myAppLogo } from './assets';

export function SignInPage(): JSX.Element {
  const profile = useMedplumProfile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const config = getConfig();

  const navigateToNext = useCallback(() => {
    // only redirect to next if it is a pathname to avoid redirecting
    // to a maliciously crafted URL, e.g. /signin?next=https%3A%2F%2Fevil.com
    const nextUrl = searchParams.get('next');
    navigate(nextUrl?.startsWith('/') ? nextUrl : '/');
  }, [searchParams, navigate]);

  useEffect(() => {
    if (profile && searchParams.has('next')) {
      navigateToNext();
    }
  }, [profile, searchParams, navigateToNext]);

  return (
    <SignInForm
      onSuccess={() => navigateToNext()}
      onForgotPassword={() => navigate('/resetpassword')}
      onRegister={isRegisterEnabled() ? () => navigate('/register') : undefined}
      googleClientId={config.googleClientId}
      login={searchParams.get('login') || undefined}
      projectId={searchParams.get('project') || undefined}
    >
      {/* <Logo size={32} />
      <Title>Sign in to Medplum</Title> */}
      <Grid
        item
        sx={{
          px: '20px',
          my: '30px',
          //   marginLeft: isAuthenticated ? { xs: 'auto', md: '100px' } : 'auto',
          //   marginRight: isAuthenticated ? '0px' : 'auto',
        }}
      >
        <Box
          component="img"
          sx={{ margin: 1, width: 200, alignSelf: 'center', minHeight: '39px' }}
          alt={'logo'}
          src={myAppLogo}
        />
      </Grid>
      {searchParams.get('project') === 'new' && <div>Sign in again to create a new project</div>}
    </SignInForm>
  );
}
