import {
  // Button,
  // ActionIcon,
  // Button,
  Center,
  // Group,
  // Group,
  Loader,
  // Text,
  // Menu,
  // Pagination,
  // Table,
  // Text,
  // UnstyledButton,
} from '@mantine/core';
import {
  // DEFAULT_SEARCH_COUNT,
  Filter,
  SearchRequest,
  deepEquals,
  formatSearchQuery,
  isDataTypeLoaded,
  normalizeOperationOutcome,
} from '@medplum/core';
import {
  Appointment,
  Bundle,
  BundleEntry,
  OperationOutcome,
  Practitioner,
  PractitionerRole,
  Resource,
  ResourceType,
  SearchParameter,
  Location,
} from '@medplum/fhirtypes';
import {
  // addFilter,
  // buildFieldNameString,
  // Container,
  // getFieldDefinitions,
  // getOpString,
  OperationOutcomeAlert,
  // renderValue,
  // SearchFieldEditor,
  // SearchFilterEditor,
  // setPage,
} from '@medplum/react';
import { useMedplum } from '@medplum/react-hooks';
import {} from // IconAdjustmentsHorizontal,
// IconBoxMultiple,
// IconColumns,
// IconFilePlus,
// IconFilter,
// IconRefresh,
// IconTableExport,
// IconTrash,
'@tabler/icons-react';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { SearchExportDialog } from '../../../../react/src/SearchExportDialog/SearchExportDialog';
// import { SearchFilterValueDialog } from '../../../../react/src/SearchFilterValueDialog/SearchFilterValueDialog';
// import { SearchFilterValueDisplay } from '../../../../react/src/SearchFilterValueDisplay/SearchFilterValueDisplay';
// import { Container } from '../Container/Container';
// import { OperationOutcomeAlert } from '../OperationOutcomeAlert/OperationOutcomeAlert';
// import { SearchExportDialog } from '../SearchExportDialog/SearchExportDialog';
// import { SearchFieldEditor } from '../SearchFieldEditor/SearchFieldEditor';
// import { SearchFilterEditor } from '../SearchFilterEditor/SearchFilterEditor';
// import { SearchFilterValueDialog } from '../SearchFilterValueDialog/SearchFilterValueDialog';
// import { SearchFilterValueDisplay } from '../SearchFilterValueDisplay/SearchFilterValueDisplay';
// import { SearchPopupMenu }SearchFilterValueDisplay from '../SearchPopupMenu/SearchPopupMenu';
// import { isCheckboxCell, killEvent } from '../utils/dom';
import classes from './SearchControl.module.css';
// import { SearchPopupMenu } from '../../../../react/src/SearchPopupMenu/SearchPopupMenu';
import Appointments from '../../queue-management/Appointments';
import { Link, useLocation } from 'react-router-dom';
import { SearchFilterEditor } from './searchEditor';
import { Button, Grid, Typography } from '@mui/material';
// import { getFieldDefinitions } from './SearchControlField';
// import { addFilter, buildFieldNameString, getOpString, renderValue, setPage } from './SearchUtils';
import AddIcon from '@mui/icons-material/Add';

export class SearchChangeEvent extends Event {
  readonly definition: SearchRequest;

  constructor(definition: SearchRequest) {
    super('change');
    this.definition = definition;
  }
}

export class SearchLoadEvent extends Event {
  readonly response: Bundle;

  constructor(response: Bundle) {
    super('load');
    this.response = response;
  }
}

export class SearchClickEvent extends Event {
  readonly resource: Resource;
  readonly browserEvent: MouseEvent;

  constructor(resource: Resource, browserEvent: MouseEvent) {
    super('click');
    this.resource = resource;
    this.browserEvent = browserEvent;
  }
}

export interface SearchControlProps {
  readonly search: SearchRequest;
  readonly checkboxesEnabled?: boolean;
  readonly hideToolbar?: boolean;
  readonly hideFilters?: boolean;
  readonly onLoad?: (e: SearchLoadEvent) => void;
  readonly onChange?: (e: SearchChangeEvent) => void;
  readonly onClick?: (e: SearchClickEvent) => void;
  readonly onAuxClick?: (e: SearchClickEvent) => void;
  readonly onNew?: () => void;
  readonly onExport?: () => void;
  readonly onExportCsv?: () => void;
  readonly onExportTransactionBundle?: () => void;
  readonly onDelete?: (ids: string[]) => void;
  readonly onBulk?: (ids: string[]) => void;
  readonly setSearch?: (search: SearchRequest) => void;
}

interface SearchControlState {
  readonly searchResponse?: Bundle;
  readonly selected: { [id: string]: boolean };
  readonly fieldEditorVisible: boolean;
  readonly filterEditorVisible: boolean;
  readonly filterDialogVisible: boolean;
  readonly exportDialogVisible: boolean;
  readonly filterDialogFilter?: Filter;
  readonly filterDialogSearchParam?: SearchParameter;
  setSearch?: (search: SearchRequest) => void;
}

/**
 * The SearchControl component represents the embeddable search table control.
 * It includes the table, rows, headers, sorting, etc.
 * It does not include the field editor, filter editor, pagination buttons.
 * @param props - The SearchControl React props.
 * @returns The SearchControl React node.
 */
export default function SearchControl(props: SearchControlProps): JSX.Element {
  const medplum = useMedplum();
  const [outcome, setOutcome] = useState<OperationOutcome | undefined>();
  const { search, onLoad, setSearch } = props;
  const location = useLocation();

  const [memoizedSearch, setMemoizedSearch] = useState(search);

  if (!deepEquals(search, memoizedSearch)) {
    setMemoizedSearch(search);
  }

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const [state, setState] = useState<SearchControlState>({
    selected: {},
    fieldEditorVisible: false,
    filterEditorVisible: false,
    exportDialogVisible: false,
    filterDialogVisible: false,
  });

  const stateRef = useRef<SearchControlState>(state);
  stateRef.current = state;

  const total = memoizedSearch.total ?? 'accurate';

  const handleFetch = async (options: RequestInit | undefined): Promise<void> => {
    setOutcome(undefined);

    try {
      await medplum.requestSchema(memoizedSearch.resourceType as ResourceType);
    } catch (reason) {
      if (reason instanceof Error) {
        setState({ ...stateRef.current, searchResponse: undefined });
        setOutcome(normalizeOperationOutcome(reason));
        return;
      }
    }

    const _count = 10000000;
    const [locations, practitioners, practitionerRoles] = await Promise.allSettled([
      (await medplum.search('Location', { _count, _sort: 'name' })).entry?.map((entry) => entry.resource as Location) ??
        [],
      (await medplum.search('Practitioner', { _count, _sort: 'name' })).entry?.map(
        (entry) => entry.resource as Practitioner
      ) ?? [],
      (await medplum.search('PractitionerRole', { _count })).entry?.map(
        (entry) => entry.resource as PractitionerRole
      ) ?? [],
    ]);
    let allLocations: Location[] = [];
    let allPractitioners: Practitioner[] = [];
    let allPractitionerRoles: PractitionerRole[] = [];

    if (locations.status === 'fulfilled') {
      allLocations = locations.value;
    } else {
      console.error('Failed to load locations', locations.reason);
    }

    if (practitioners.status === 'fulfilled') {
      allPractitioners = practitioners.value;
    } else {
      console.error('Failed to load practitioners', practitioners.reason);
    }

    if (practitionerRoles.status === 'fulfilled') {
      allPractitionerRoles = practitionerRoles.value;
    } else {
      console.error('Failed to load practitionerRoles', practitionerRoles.reason);
    }

    const response = await medplum.search(
      memoizedSearch.resourceType as ResourceType,
      formatSearchQuery({
        ...memoizedSearch,
        total,
        fields: undefined,
        count: 100000,
        // include: [
        //   {
        //     resourceType: 'Appointment',
        //     searchParam: 'actor',
        //     // targetType: 'string',
        //     // modifier: 'iterate',
        //   },
        // ],
        // filters: [
        //   {
        //     code: 'actor',
        //     operator: Operator.EQUALS,
        //     value: 'Patient/40391c2d-28a0-4d9d-abc7-01ec4dcd1634',
        //   },
        // ],
        // actor: 'Patient/40391c2d-28a0-4d9d-abc7-01ec4dcd1634'
        // include: [
        //   {
        //     resourceType: 'Appointment',
        //     searchParam: 'actor',
        //     // targetType: 'string',
        //     // modifier: 'iterate',
        //   },
        // ],
      }),
      // + '&actor=Patient/40391c2d-28a0-4d9d-abc7-01ec4dcd1634', // Appointment:actor&_include=Appointment:location
      // }) + '&actor=Practitioner/23f05db6-fcf2-4d7c-90ee-ad3dc8590505', // Appointment:actor&_include=Appointment:location
      options
    );

    const getTimezoneFromExtension = (resource: { extension: any[] }): string => {
      // Find the timezone extension by URL
      const timezoneExtension = resource.extension?.find(
        (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone'
      );

      // Return the valueString (timezone) or a default value
      return timezoneExtension?.valueString || ''; // Default to UTC if not found
    };

    for (const entry of response.entry || []) {
      const { participant = [] } = entry.resource || {};

      let patient = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Patient')
      );

      if (patient) {
        // const patientDetails = await medplum.readResource('Patient', patient.actor?.reference?.replace('Patient/', ''));

        // const email = patientDetails?.telecom?.find((telecom: { system: string }) => telecom.system === 'email')?.value;
        // let user: User | null = null;

        // if (email) {
        //   user = await medplum.searchOne('User', `email=${email}`);
        // }

        patient = patient
          ? {
              ...patient,
              ...patient.actor,
              actor: { ...patient.actor, reference: patient.actor?.reference?.replace('Patient/', '') },
              // user: user,
              // patientDetails,
              // email,
            }
          : undefined;
      }

      let location = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Location')
      );

      const locationRecord: Location | undefined = allLocations.find(
        (loc: any) => loc?.id === location?.actor?.reference?.split('/')?.[1]
      );

      let locationAverageWaitingTime = null;
      let locationAverageSessionTime = null;
      // console.log('locationRecord', locationRecord);
      if (locationRecord) {
        const locationWaitTimeExtension = locationRecord?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-waiting-time'
        );

        // console.log('locationWaitTimeExtension', locationWaitTimeExtension);
        if (locationWaitTimeExtension) {
          locationAverageWaitingTime = locationWaitTimeExtension?.valueDuration?.value;
        }
        const locationAverageSessionTimeExtension = locationRecord?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-session-time'
        );

        if (locationAverageSessionTimeExtension) {
          locationAverageSessionTime = locationAverageSessionTimeExtension?.valueDuration?.value;
        }

        // console.log('locationAverageSessionTimeExtension', locationAverageSessionTimeExtension);
      }

      if (location) {
        location = location
          ? {
              ...location,
              ...location.actor,
              actor: { ...location.actor, reference: location.actor?.reference?.replace('Location/', '') },
              locationAverageWaitingTime,
              locationAverageSessionTime,
            }
          : undefined;
      }

      let practitioner = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Practitioner')
      );

      // console.log('practitioner.actor', practitioner?.actor?.reference?.split('/')[1]);
      // console.log('allPractitioners', allPractitioners);

      const practitionerRecord = allPractitioners.find(
        (loc: any) => loc?.id === practitioner?.actor?.reference?.split('/')[1]
      );
      // console.log('practitionerRecord', practitionerRecord);

      let practitionerAverageWaitingTime = null;
      let practitionerAverageSessionTime = null;

      let practitionerRoleAverageWaitingTime = null;
      let practitionerRoleAverageSessionTime = null;

      if (practitionerRecord) {
        const practitionerWaitTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-waiting-time'
        );

        // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
        if (practitionerWaitTimeExtension) {
          practitionerAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
        }
        const practitionerAverageSessionTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-session-time'
        );

        if (practitionerAverageSessionTimeExtension) {
          practitionerAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
        }

        console.log('practitioner.actor', practitioner?.actor);
        console.log('location.actor', location?.actor);
        console.log('allPractitionerRoles', allPractitionerRoles);

        const practitionerRoleRecord = allPractitionerRoles.find((pr: any) => {
          const prFound = pr?.practitioner?.reference === practitioner?.actor?.reference;
          const locationFound = pr?.location?.find(
            (loc: any) =>
              loc.reference === location?.actor || loc.reference === `Location/${location?.actor?.reference}`
          );
          console.log('prFound', prFound);
          console.log('locationFound', locationFound);
          return prFound && locationFound;
        });
        console.log('practitionerRoleRecord', practitionerRoleRecord);

        if (practitionerRoleRecord) {
          const practitionerWaitTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-waiting-time'
          );

          // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
          if (practitionerWaitTimeExtension) {
            practitionerRoleAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
          }
          const practitionerAverageSessionTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-session-time'
          );

          if (practitionerAverageSessionTimeExtension) {
            practitionerRoleAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
          }

          // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
        }

        // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
      }

      if (practitioner) {
        practitioner = practitioner
          ? {
              ...practitioner,
              ...practitioner.actor,
              actor: { ...practitioner.actor, reference: practitioner.actor?.reference?.replace('Practitioner/', '') },
              practitionerAverageWaitingTime,
              practitionerAverageSessionTime,
              practitionerRoleAverageWaitingTime,
              practitionerRoleAverageSessionTime,
            }
          : undefined;
      }

      const userTimezone = getTimezoneFromExtension(entry.resource);

      entry.resource = {
        ...entry.resource,
        patient,
        location,
        practitioner,
        userTimezone,
      };

      // return { ...entry, patient, location };
    }

    setState({ ...stateRef.current, searchResponse: response });
    if (onLoad) {
      onLoad(new SearchLoadEvent(response));
    }

    // medplum.requestSchema(memoizedSearch.resourceType as ResourceType).catch((reason) => {
    // setState({ ...stateRef.current, searchResponse: undefined });
    // setOutcome(normalizeOperationOutcome(reason));
    // });
    // medplum
    //   .requestSchema(memoizedSearch.resourceType as ResourceType)
    //   .then(() => {
    //     console.log('zee 176', { ...memoizedSearch, total, fields: undefined });
    //     console.log('zee options', formatSearchQuery({ ...memoizedSearch, total, fields: undefined }));
    //     return medplum.search(
    //       memoizedSearch.resourceType as ResourceType,
    //       formatSearchQuery({ ...memoizedSearch, total, fields: undefined }),
    //       options
    //     );
    //   })
    //   .then((response) => {
    //     setState({ ...stateRef.current, searchResponse: response });
    //     if (onLoad) {
    //       onLoad(new SearchLoadEvent(response));
    //     }
    //   })
    //   .catch((reason) => {
    //     setState({ ...stateRef.current, searchResponse: undefined });
    //     setOutcome(normalizeOperationOutcome(reason));
    //   });
  };

  const loadResults = useCallback(
    (options?: RequestInit) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleFetch(options);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [medplum, memoizedSearch, total, onLoad, queryParams]
  );

  // const refreshResults = useCallback(() => {
  //   setState({ ...stateRef.current, searchResponse: undefined });
  //   loadResults({ cache: 'reload' });
  // }, [loadResults]);

  useEffect(() => {
    loadResults();
  }, [loadResults]);

  // function handleSingleCheckboxClick(e: ChangeEvent, id: string): void {
  //   e.stopPropagation();

  //   const el = e.target as HTMLInputElement;
  //   const checked = el.checked;
  //   const newSelected = { ...stateRef.current.selected };
  //   if (checked) {
  //     newSelected[id] = true;
  //   } else {
  //     delete newSelected[id];
  //   }
  //   setState({ ...stateRef.current, selected: newSelected });
  // }

  // function handleAllCheckboxClick(e: ChangeEvent): void {
  //   e.stopPropagation();

  //   const el = e.target as HTMLInputElement;
  //   const checked = el.checked;
  //   const newSelected = {} as { [id: string]: boolean };
  //   const searchResponse = stateRef.current.searchResponse;
  //   if (checked && searchResponse?.entry) {
  //     searchResponse.entry.forEach((entry) => {
  //       if (entry.resource?.id) {
  //         newSelected[entry.resource.id] = true;
  //       }
  //     });
  //   }
  //   setState({ ...stateRef.current, selected: newSelected });
  // }

  // function isAllSelected(): boolean {
  //   const state = stateRef.current;
  //   if (!state.searchResponse?.entry || state.searchResponse.entry.length === 0) {
  //     return false;
  //   }
  //   for (const e of state.searchResponse.entry) {
  //     if (e.resource?.id && !state.selected[e.resource.id]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  /**
   * Emits a change event to the optional change listener.
   * @param newSearch - The new search definition.
   */
  function emitSearchChange(newSearch: SearchRequest): void {
    if (props.onChange) {
      props.onChange(new SearchChangeEvent(newSearch));
    }
  }

  /**
   * Handles a click on a order row.
   * @param e - The click event.
   * @param resource - The FHIR resource.
   */
  // function handleRowClick(e: MouseEvent, resource: Resource): void {
  //   // if (isCheckboxCell(e.target as Element)) {
  //   //   // Ignore clicks on checkboxes
  //   //   return;
  //   // }

  //   // if (e.button === 2) {
  //   //   // Ignore right clicks
  //   //   return;
  //   // }

  //   // killEvent(e);

  //   const isAux = e.button === 1 || e.ctrlKey || e.metaKey;

  //   if (!isAux && props.onClick) {
  //     props.onClick(new SearchClickEvent(resource, e));
  //   }

  //   if (isAux && props.onAuxClick) {
  //     props.onAuxClick(new SearchClickEvent(resource, e));
  //   }
  // }

  // function isExportPassed(): boolean {
  //   return !!(props.onExport ?? props.onExportCsv ?? props.onExportTransactionBundle);
  // }

  if (outcome) {
    return <OperationOutcomeAlert outcome={outcome} />;
  }

  if (!isDataTypeLoaded(memoizedSearch.resourceType)) {
    return (
      <Center style={{ width: '100%', height: '100%' }}>
        <Loader />
      </Center>
    );
  }

  // const checkboxColumn = props.checkboxesEnabled;
  // const fields = getFieldDefinitions(memoizedSearch);
  // const resourceType = memoizedSearch.resourceType;
  const lastResult = state.searchResponse;
  const entries = lastResult?.entry;
  const resources: Appointment[] =
    entries
      ?.map((e: BundleEntry<Appointment>) => {
        // let patient = e?.resource?.participant?.find((participant) =>
        //   participant.actor?.reference?.startsWith('Patient')
        // );

        // if (patient) {
        //   patient = patient
        //     ? {
        //         ...patient,
        //         ...patient.actor,
        //         actor: { ...patient.actor, reference: patient.actor?.reference?.replace('Patient/', '') },
        //       }
        //     : undefined;
        // }

        // let location = e?.resource?.participant?.find((participant) =>
        //   participant.actor?.reference?.startsWith('Location')
        // );

        // if (location) {
        //   location = location
        //     ? {
        //         ...location,
        //         ...location.actor,
        //         actor: { ...location.actor, reference: location.actor?.reference?.replace('Location/', '') },
        //       }
        //     : undefined;
        // }

        return { ...e.resource } as Appointment;
      })
      .sort((a, b) => {
        const dateA = a.start ? new Date(a.start).getTime() : 0;
        const dateB = b.start ? new Date(b.start).getTime() : 0;
        return dateB - dateA;
      }) || [];

  // const buttonVariant = 'subtle';
  // const buttonColor = 'gray';
  // const iconSize = 16;
  // const isMobile = window.innerWidth < 768;

  const categorizedByStatus = resources.reduce(
    (acc: { [key in Appointment['status']]?: Appointment[] }, appointment) => {
      const { status } = appointment;

      if (!acc[status]) {
        acc[status] = [];
      }
      acc[status].push(appointment);
      return acc;
    },
    {}
  );

  return (
    <div className={classes.root} data-testid="search-control">
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={9} md={12} lg={10} xl={8}>
          <SearchFilterEditor
            search={memoizedSearch}
            visible={stateRef.current.filterEditorVisible}
            onOk={(result) => {
              emitSearchChange(result);
              setState({
                ...stateRef.current,
                filterEditorVisible: false,
              });
            }}
            onCancel={() => {
              setState({
                ...stateRef.current,
                filterEditorVisible: false,
              });
            }}
          />
        </Grid>
        <Grid>
          <Link to="/Appointment/add">
            <Button
              sx={{
                borderRadius: 100,
                textTransform: 'none',
                fontWeight: 600,
                marginTop: 3,
              }}
              variant="contained"
            >
              <AddIcon />
              <Typography fontWeight="bold">Add patient</Typography>
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/* {!props.hideToolbar && (
        <Group justify="space-between" mb="xl">
          <Group gap={2}>
            <Button
              size="compact-md"
              variant={buttonVariant}
              color={buttonColor}
              leftSection={<IconColumns size={iconSize} />}
              onClick={() => setState({ ...stateRef.current, fieldEditorVisible: true })}
            >
              Fields
            </Button>
            <Button
              size="compact-md"
              variant={buttonVariant}
              color={buttonColor}
              leftSection={<IconFilter size={iconSize} />}
              onClick={() => setState({ ...stateRef.current, filterEditorVisible: true })}
            >
              Filters
            </Button>
            {props.onNew && (
              <Button
                size="compact-md"
                variant={buttonVariant}
                color={buttonColor}
                leftSection={<IconFilePlus size={iconSize} />}
                onClick={props.onNew}
              >
                New...
              </Button>
            )}
            {!isMobile && isExportPassed() && (
              <Button
                size="compact-md"
                variant={buttonVariant}
                color={buttonColor}
                leftSection={<IconTableExport size={iconSize} />}
                onClick={
                  props.onExport ? props.onExport : () => setState({ ...stateRef.current, exportDialogVisible: true })
                }
              >
                Export...
              </Button>
            )}
            {!isMobile && props.onDelete && (
              <Button
                size="compact-md"
                variant={buttonVariant}
                color={buttonColor}
                leftSection={<IconTrash size={iconSize} />}
                onClick={() => (props.onDelete as (ids: string[]) => any)(Object.keys(state.selected))}
              >
                Delete...
              </Button>
            )}
            {!isMobile && props.onBulk && (
              <Button
                size="compact-md"
                variant={buttonVariant}
                color={buttonColor}
                leftSection={<IconBoxMultiple size={iconSize} />}
                onClick={() => (props.onBulk as (ids: string[]) => any)(Object.keys(state.selected))}
              >
                Bulk...
              </Button>
            )}
          </Group>
          <Group gap={2}>
            {lastResult && (
              <Text size="xs" c="dimmed" data-testid="count-display">
                {getStart(memoizedSearch, lastResult).toLocaleString()}-
                {getEnd(memoizedSearch, lastResult).toLocaleString()}
                {lastResult.total !== undefined &&
                  ` of ${memoizedSearch.total === 'estimate' ? '~' : ''}${lastResult.total?.toLocaleString()}`}
              </Text>
            )}
            <ActionIcon variant={buttonVariant} color={buttonColor} title="Refresh" onClick={refreshResults}>
              <IconRefresh size={iconSize} />
            </ActionIcon>
          </Group>
        </Group>
      )} */}
      <Appointments
        appoinments={resources}
        handleFetch={handleFetch}
        categorizedByStatusAppointment={categorizedByStatus}
        search={search}
        setSearch={setSearch ?? (() => {})}
      />

      {/* <Table className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            {checkboxColumn && (
              <Table.Th>
                <input
                  type="checkbox"
                  value="checked"
                  aria-label="all-checkbox"
                  data-testid="all-checkbox"
                  checked={isAllSelected()}
                  onChange={(e) => handleAllCheckboxClick(e)}
                />
              </Table.Th>
            )}
            {fields.map((field) => (
              <Table.Th key={field.name} className={classes.tableHead}>
                <Menu shadow="md" width={240} position="bottom-end">
                  <Menu.Target>
                    <UnstyledButton className={classes.control} p={2}>
                      <Group justify="space-between" wrap="nowrap">
                        <Text fw={500}>{buildFieldNameString(field.name)}</Text>
                        <Center className={classes.icon}>
                          <IconAdjustmentsHorizontal size={14} stroke={1.5} />
                        </Center>
                      </Group>
                    </UnstyledButton>
                  </Menu.Target>
                  <SearchPopupMenu
                    search={memoizedSearch}
                    searchParams={field.searchParams}
                    onPrompt={(searchParam, filter) => {
                      setState({
                        ...stateRef.current,
                        filterDialogVisible: true,
                        filterDialogSearchParam: searchParam,
                        filterDialogFilter: filter,
                      });
                    }}
                    onChange={(result) => {
                      emitSearchChange(result);
                    }}
                  />
                </Menu>
              </Table.Th>
            ))}
          </Table.Tr>
          {!props.hideFilters && (
            <Table.Tr>
              {checkboxColumn && <Table.Th />}
              {fields.map((field) => (
                <Table.Th key={field.name}>
                  {field.searchParams && (
                    <FilterDescription
                      resourceType={resourceType}
                      searchParams={field.searchParams}
                      filters={memoizedSearch.filters}
                    />
                  )}
                </Table.Th>
              ))}
            </Table.Tr>
          )}
        </Table.Thead>
        <Table.Tbody>
          {resources?.map(
            (resource) =>
              resource && (
                <Table.Tr
                  key={resource.id}
                  className={classes.tr}
                  data-testid="search-control-row"
                  onClick={(e) => handleRowClick(e, resource)}
                  onAuxClick={(e) => handleRowClick(e, resource)}
                >
                  {checkboxColumn && (
                    <Table.Td>
                      <input
                        type="checkbox"
                        value="checked"
                        data-testid="row-checkbox"
                        aria-label={`Checkbox for ${resource.id}`}
                        checked={!!state.selected[resource.id as string]}
                        onChange={(e) => handleSingleCheckboxClick(e, resource.id as string)}
                      />
                    </Table.Td>
                  )}
                  {fields.map((field) => (
                    <Table.Td key={field.name}>{renderValue(resource, field)}</Table.Td>
                  ))}
                </Table.Tr>
              )
          )}
        </Table.Tbody>
      </Table> */}
      {/* {resources?.length === 0 && (
        <Container>
          <Center style={{ height: 150 }}>
            <Text size="xl" c="dimmed">
              No results
            </Text>
          </Center>
        </Container>
      )} */}
      {/* {lastResult && (
        <Center m="md" p="md">
          <Pagination
            value={getPage(memoizedSearch)}
            total={getTotalPages(memoizedSearch, lastResult)}
            onChange={(newPage) => emitSearchChange(setPage(memoizedSearch, newPage))}
            getControlProps={(control) => {
              switch (control) {
                case 'previous':
                  return { 'aria-label': 'Previous page' };
                case 'next':
                  return { 'aria-label': 'Next page' };
                default:
                  return {};
              }
            }}
          />
        </Center>
      )} */}
      {/* <SearchFieldEditor
        search={memoizedSearch}
        visible={stateRef.current.fieldEditorVisible}
        onOk={(result) => {
          emitSearchChange(result);
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            fieldEditorVisible: false,
          });
        }}
      />
      <SearchExportDialog
        visible={stateRef.current.exportDialogVisible}
        exportCsv={props.onExportCsv}
        exportTransactionBundle={props.onExportTransactionBundle}
        onCancel={() => {
          setState({
            ...stateRef.current,
            exportDialogVisible: false,
          });
        }}
      />
      <SearchFilterValueDialog
        key={state.filterDialogSearchParam?.code}
        visible={stateRef.current.filterDialogVisible}
        title={state.filterDialogSearchParam?.code ? buildFieldNameString(state.filterDialogSearchParam.code) : ''}
        resourceType={resourceType}
        searchParam={state.filterDialogSearchParam}
        filter={state.filterDialogFilter}
        defaultValue=""
        onOk={(filter) => {
          emitSearchChange(addFilter(memoizedSearch, filter.code, filter.operator, filter.value));
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
        onCancel={() => {
          setState({
            ...stateRef.current,
            filterDialogVisible: false,
          });
        }}
      /> */}
    </div>
  );
}

/**
 * @deprecated
 *
 * The memoization `MemoizedSearchControl` provides has been merged into `SearchControl`. Previously the memoization was done via HOC but
 * it was proven that this wasn't effective for a large number of use cases, especially when:
 * 1. `search` was an inline static object, which would trigger the memo to recompute on every re-render of the parent component
 * 2. Any of the callbacks, such as `onClick` were not memoized via `useCallback`, which would result in the recomputation as well
 *
 * Scenario 1 also retriggered the effect that runs `loadResults` on change of the `search`, which was less than desirable.
 *
 * The memoization is now accomplished via checking deep equality of the incoming `search` prop in the body of the component, and setting a memoized
 * state whenever the incoming and current memoized value are not deeply equal. See: https://github.com/medplum/medplum/pull/5023
 */
export const MemoizedSearchControl = SearchControl;

// interface FilterDescriptionProps {
//   readonly resourceType: string;
//   readonly searchParams: SearchParameter[];
//   readonly filters?: Filter[];
// }

// function FilterDescription(props: FilterDescriptionProps): JSX.Element {
//   const filters = (props.filters ?? []).filter((f) => props.searchParams.find((p) => p.code === f.code));
//   if (filters.length === 0) {
//     return <span>no filters</span>;
//   }

//   return (
//     <>
//       {filters.map((filter: Filter) => (
//         <div key={`filter-${filter.code}-${filter.operator}-${filter.value}`}>
//           {getOpString(filter.operator)}
//           &nbsp;
//           <SearchFilterValueDisplay resourceType={props.resourceType} filter={filter} />
//         </div>
//       ))}
//     </>
//   );
// }

// function getPage(search: SearchRequest): number {
//   return Math.floor((search.offset ?? 0) / (search.count ?? DEFAULT_SEARCH_COUNT)) + 1;
// }

// function getTotalPages(search: SearchRequest, lastResult: Bundle): number {
//   const pageSize = search.count ?? DEFAULT_SEARCH_COUNT;
//   const total = getTotal(search, lastResult);
//   return Math.ceil(total / pageSize);
// }

// function getStart(search: SearchRequest, lastResult: Bundle): number {
//   return Math.min(getTotal(search, lastResult), (search.offset ?? 0) + 1);
// }

// function getEnd(search: SearchRequest, lastResult: Bundle): number {
//   return Math.max(getStart(search, lastResult) + (lastResult.entry?.length ?? 0) - 1, 0);
// }

// function getTotal(search: SearchRequest, lastResult: Bundle): number {
//   let total = lastResult.total;
//   if (total === undefined) {
//     // If the total is not specified, then we have to estimate it
//     total =
//       (search.offset ?? 0) +
//       (lastResult.entry?.length ?? 0) +
//       (lastResult.link?.some((l) => l.relation === 'next') ? 1 : 0);
//   }
//   return total;
// }
