import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
// import { ZambdaClient } from '@zapehr/sdk';
import { Patient, Resource, Location, LocationHoursOfOperation } from 'fhir/r4';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
// import { useNavigate } from 'react-router-dom';
// import { GetLocationParameters, GetLocationResponse, createAppointment, getLocations } from '../api/api';
// import CustomBreadcrumbs from '../../queue-management/components/CustomBreadcrumbs';

import DateSearch from '../../queue-management/components/DateSearch';
import LocationSelect from '../../queue-management/components/LocationSelect';
import { MAXIMUM_CHARACTER_LIMIT, ReasonForVisitOptions } from '../../queue-management/constants';
// import { useApiClients } from '../hooks/useAppClients';
// import PageContainer from '../../queue-management/layout/PageContainer';
import {
  CreateAppointmentParameters,
  PersonSex,
  PRIVATE_EXTENSION_BASE_URL,
  VisitType,
} from '../../queue-management/types/types';
// import { PRIVATE_EXTENSION_BASE_URL } from 'ehr-utils';
import SlotPicker from '../../queue-management/components/SlotPicker';
import { useMedplum } from '@medplum/react';
import {
  Appointment,
  User,
  Practitioner as MedplumPractitioner,
  Location as MedplumLocation,
  HumanName,
} from '@medplum/fhirtypes';
import { createReference, formatHumanName, ProfileResource } from '@medplum/core';
import PractitionerSelect from '../../queue-management/components/PractitionerSelect';
import { notifications } from '@mantine/notifications';
// import { GetLocationParameters } from '../../types';
import bcrypt from 'bcryptjs';
import { CustomDialog } from '../../queue-management/components/dialogs/CustomDialog';
import { useUser } from '../../user.context';

type SlotLoadingState =
  | { status: 'initial'; input: undefined }
  | { status: 'loading'; input: undefined }
  | { status: 'loaded'; input: string };

interface GetLocationParameters {
  slug?: string;
  scheduleType?: 'location' | 'provider' | 'group';
  locationState?: string;
  fetchAll?: boolean;
}

type Days = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export default function AddPatient({
  close,
  defaultSlot,
  setDefaultSlot,
  setFetchAppointments,
}: {
  close: () => void;
  defaultSlot: string | undefined;
  setDefaultSlot: Dispatch<SetStateAction<string | undefined>>;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined);
  const [selectedPractitioner, setSelectedPractitioner] = useState<MedplumPractitioner | undefined>(undefined);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birthDate, setBirthDate] = useState<DateTime | null>(null);
  const [sex, setSex] = useState<PersonSex | undefined>(undefined);
  const [mobilePhone, setMobilePhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [reasonForVisit, setReasonForVisit] = useState<string[]>([]);
  const [visitType, setVisitType] = useState<VisitType>(defaultSlot ? VisitType.Prebook : VisitType.Now);
  const [slot, setSlot] = useState<string | undefined>(defaultSlot);
  const [loading, setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ submit?: boolean; phone?: boolean; search?: boolean }>({
    submit: false,
    phone: false,
    search: false,
  });
  const [loadingSlotState, setLoadingSlotState] = useState<SlotLoadingState>({ status: 'initial', input: undefined });
  // const [locationWithSlotData, setLocationWithSlotData] = useState<GetLocationResponse | undefined>(undefined);
  const [locationWithSlotData, setLocationWithSlotData] = useState<any>(undefined);
  const [inputValue, setInputValue] = useState<string>('');
  const [validDate, setValidDate] = useState<boolean>(true);
  const [selectSlotDialogOpen, setSelectSlotDialogOpen] = useState<boolean>(false);
  const [validReasonForVisit, setValidReasonForVisit] = useState<boolean>(true);
  const [openSearchResults, setOpenSearchResults] = useState<boolean>(false);
  const [patients, setPatients] = useState<Patient[] | undefined>(undefined);
  const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>(undefined);
  const [showFields, setShowFields] = useState<{ prefillForSelected?: boolean; forcePatientSearch?: boolean }>({
    prefillForSelected: false,
    forcePatientSearch: true,
  });

  // general variables
  const theme = useTheme();
  // const navigate = useNavigate();
  // const { zambdaIntakeClient, fhirClient } = useMedplum();
  const medplum = useMedplum();
  const sender = medplum.getProfile() as ProfileResource;

  const { getUtilsData } = useUser();

  const reasonForVisitErrorMessage = `Input cannot be more than ${MAXIMUM_CHARACTER_LIMIT} characters`;
  const phoneNumberErrorMessage = 'Phone number must be 10 digits';

  const handleReasonsForVisitChange = (newValues: string[]): void => {
    setValidReasonForVisit(newValues.join(', ').length <= MAXIMUM_CHARACTER_LIMIT);
    setReasonForVisit(newValues);
  };

  // const getCurrentUser = () => {
  //   try {
  //     const user = medplum.getProfile(); // Retrieves the currently logged-in user's profile
  //     console.log('getCurrentUser', user);

  //     if (user?.resourceType === 'Practitioner') {
  //       // If the user is a patient, prefill the form with their information
  //       setSelectedPractitioner(user);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching current user:', error);
  //   }
  // };

  // useEffect(() => {
  //   getCurrentUser();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function generateDateTimeStrings(day: DateTime<true>, openingTime: string, closingTime: string): string[] {
    const open = day.set({
      hour: parseInt(openingTime.split(':')[0], 10),
      minute: parseInt(openingTime.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });
    const close = day.set({
      hour: parseInt(closingTime.split(':')[0], 10),
      minute: parseInt(closingTime.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });

    // Debugging: log open and close times to verify
    // console.log('Open time:', open.toISO());
    // console.log('Close time:', close.toISO());

    const slots = [];
    if (open < close) {
      for (let slot = open; slot < close; slot = slot.plus({ hours: 1 })) {
        slots.push(slot.toISO()); // Generate ISO string for each time slot
      }
    } else {
      console.log('Warning: Closing time is not after opening time.');
    }
    return slots;
  }

  useEffect(() => {
    const fetchLocationWithSlotData = async (params: GetLocationParameters): Promise<void> => {
      setLoadingSlotState({ status: 'loading', input: undefined });
      try {
        // if (!zambdaIntakeClient) throw new Error('Zambda client not found');
        // const locationResponse = await getLocations(zambdaIntakeClient, params);
        // setLocationWithSlotData(locationResponse);

        // const today = DateTime.now();
        // const tomorrow = today.plus({ days: 1 });
        // const days = [
        //   { day: today, label: today.toFormat('ccc').toLowerCase() as Days },
        //   { day: tomorrow, label: tomorrow.toFormat('ccc').toLowerCase() as Days },
        // ];

        const today = DateTime.now();
        const threeMonthsLater = today.plus({ year: 1 });
        const days = [];
        for (let date = today; date <= threeMonthsLater; date = date.plus({ days: 1 })) {
          days.push({ day: date, label: date.toFormat('ccc').toLowerCase() as Days });
        }

        const timezone = selectedLocation?.extension?.find(
          (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone'
        )?.valueString;

        // Generate date-time strings
        let dateTimeList: string[] = [];

        days.forEach(({ day, label }) => {
          selectedLocation?.hoursOfOperation?.forEach((op: LocationHoursOfOperation) => {
            if (op.daysOfWeek?.includes(label)) {
              if (op.openingTime && op.closingTime) {
                dateTimeList.push(...generateDateTimeStrings(day, op.openingTime, op.closingTime));
              }
            }
          });
        });

        if (defaultSlot) {
          const isSlotExist = dateTimeList.includes(defaultSlot);

          if (!isSlotExist) {
            dateTimeList.push(defaultSlot);
            dateTimeList = dateTimeList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
          }
        }

        setLocationWithSlotData({
          availableSlots: dateTimeList,
          timezone: timezone || 'America/New_York',
        });
      } catch (e) {
        console.error('error loading location with slot data', e);
      } finally {
        setLoadingSlotState({ status: 'loaded', input: `${params.locationState}-${params.slug}` });
      }
    };
    const locationSlug = selectedLocation?.identifier?.find(
      (identifierTemp) => identifierTemp.system === 'https://fhir.ottehr.com/r4/slug'
    )?.value;
    const locationState = selectedLocation?.address?.state;
    if (!locationSlug || !locationState) {
      console.log(
        'show some toast: location is missing slug or address.state',
        selectedLocation,
        locationSlug,
        locationState
      );
      return;
    }
    if (
      // !zambdaIntakeClient ||
      loadingSlotState.status === 'loading' ||
      (loadingSlotState.status === 'loaded' && loadingSlotState.input === `${locationState}-${locationSlug}`)
    ) {
      return;
    }
    // eslint-disable-next-line no-void
    void fetchLocationWithSlotData({ slug: locationSlug, locationState, scheduleType: 'location' });
  }, [selectedLocation, loadingSlotState, defaultSlot]);

  // handle functions
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (mobilePhone.length !== 11) {
      setErrors({ phone: true });
      return;
    } else {
      setErrors({});
    }

    if (visitType === VisitType.Prebook && slot === undefined) {
      setSelectSlotDialogOpen(true);
      return;
    }
    if (showFields.forcePatientSearch) {
      setErrors({ search: true });
      return;
    } else {
      setErrors({ ...errors, search: false });
    }

    if (validDate && validReasonForVisit) {
      setLoading(true);

      let selectedPatientEmail, selectedPatientEmailUser;
      if (selectedPatient) {
        selectedPatientEmailUser = selectedPatient.extension?.find(
          (ext) => ext.url === `${PRIVATE_EXTENSION_BASE_URL}/form-user`
        )?.valueString as any;
        if (selectedPatientEmailUser) {
          if (selectedPatientEmailUser !== 'Parent/Guardian') {
            selectedPatientEmail = selectedPatient.telecom?.find((telecom) => telecom.system === 'email')?.value;
          } else if (selectedPatientEmailUser === 'Parent/Guardian') {
            const guardianContact = selectedPatient.contact?.find((contact) =>
              contact.relationship?.find((relationship) => relationship?.coding?.[0].code === 'Parent/Guardian')
            );
            selectedPatientEmail = guardianContact?.telecom?.find((telecom) => telecom.system === 'email')?.value;
          }
        }

        const emailToUse = selectedPatientEmail || email;
        let emailUser = selectedPatientEmailUser;
        // eslint-disable-next-line eqeqeq
        if (emailUser == undefined && emailToUse) {
          emailUser = 'Parent/Guardian';
        }

        // if (!zambdaIntakeClient) throw new Error('Zambda client not found');
        const zambdaParams: CreateAppointmentParameters = {
          patient: {
            id: selectedPatient?.id,
            newPatient: !selectedPatient,
            firstName: selectedPatient?.name?.[0].given?.join(' ') || firstName,
            lastName: selectedPatient?.name?.[0].family || lastName,
            dateOfBirth: selectedPatient?.birthDate || birthDate?.toISODate() || undefined,
            sex: (selectedPatient?.gender as PersonSex) || sex || undefined,
            phoneNumber: mobilePhone,
            email: emailToUse,
            emailUser,
            reasonForVisit: reasonForVisit,
          },
          slot: slot,
          visitType: visitType,
          locationID: selectedLocation?.id,
          scheduleType: 'location',
          visitService: 'in-person',
        };

        console.log('zambdaParams', zambdaParams);

        // const firstname = selectedPatient?.name?.[0].given?.join(' ') || firstName;
        // const lastname = selectedPatient?.name?.[0].family || lastName;
        let response;
        let apiErr = false;
        try {
          // response = await createAppointment(zambdaIntakeClient, zambdaParams);

          // console.log('project', project);
          // console.log('projectId', projectId);

          const user = await medplum.search('Patient', `phone=+${mobilePhone}`);
          console.log('user', user);

          const user1 = await medplum.search('Patient', `phone=+${mobilePhone}`);
          console.log('user1', user1);

          const timezone =
            selectedLocation?.extension?.find((ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone')
              ?.valueString || 'America/New_York';

          console.log('slot', slot);
          console.log('visitType', visitType);

          // Set the timezone as per user's location
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const currentDateTime = DateTime.now().setZone(userTimezone).set({ second: 0, millisecond: 0 }).toISO();

          const startTime = visitType === VisitType.Prebook && slot ? slot : currentDateTime || undefined;

          const selectedTimezone = visitType === VisitType.Prebook ? timezone : userTimezone;

          const patientName = formatHumanName(selectedPatient?.name?.[0] as HumanName);
          response = await medplum.createResource<Appointment>({
            resourceType: 'Appointment',
            status: 'booked',
            start: startTime,
            end: startTime,
            participant: [
              {
                actor: {
                  reference: `Patient/${selectedPatient?.id}`,
                  display: patientName,
                },
                status: 'accepted',
              },
              {
                actor: {
                  reference: `Location/${selectedLocation?.id}`,
                  display: selectedLocation?.name,
                },
                status: 'accepted',
              },
              {
                actor: selectedPractitioner ? createReference(selectedPractitioner as MedplumPractitioner) : undefined,
                status: 'accepted',
              },
            ],
            appointmentType: visitType
              ? { coding: [{ code: visitType, system: 'http://fhir.com/visit-type' }] }
              : undefined,
            serviceType: [{ coding: [{ code: 'in-person', system: 'http://fhir.com/service-type' }] }],
            // reasonCode: reasonForVisit.map((reason) => ({ text: reason })),
            reasonCode: reasonForVisit.map((reason) => ({
              coding: [{ code: reason, display: reason, system: 'http://fhir.com/reason-for-visit' }],
            })),
            extension: [
              {
                url: 'http://hl7.org/fhir/StructureDefinition/timezone', // Custom extension for time zone
                valueString: selectedTimezone,
              },
            ],
          });

          console.log('response', response);

          try {
            const text = `Appointment has been created by ${formatHumanName(sender.name?.[0] as HumanName)} for ${patientName}`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(selectedPatient),
              sender: createReference(sender),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            });
          } catch (error) {
            console.log('error', error);
          }

          await medplum.sendSms2({
            body: `Dear ${patientName}! Your appointment has been scheduled for ${
              startTime ? DateTime.fromISO(startTime).toFormat('MMMM dd, yyyy h:mm a') : ''
            }.`,
            to: `+${mobilePhone}`,
          });

          const encounter = await medplum.createResource({
            resourceType: 'Encounter',
            status: visitType === VisitType.Now ? 'arrived' : 'planned',
            subject: createReference(selectedPatient),
            class: {
              code: 'AMB',
              display: 'ambulatory',
            },
            participant: [{ individual: createReference(selectedPractitioner as MedplumPractitioner) }],
            appointment: [createReference(response)],
            period: {
              start: startTime,
              end: startTime,
            },
            location: [{ location: createReference(selectedLocation as MedplumLocation) }],
          });

          console.log('encounter', encounter);
          await getUtilsData();
        } catch (error) {
          console.error(`Failed to add patient: ${error}`);
          apiErr = true;
        } finally {
          setLoading(false);
          if (response && !apiErr) {
            notifications.show({
              title: 'Appointment Created',
              message: 'Appointment created successfully',
              color: 'green',
            });
            // navigate('/appointment');
            close();
            setDefaultSlot(undefined);
            setFetchAppointments((prev) => !prev);
          } else {
            setErrors({ submit: true });
          }
        }
      } else {
        let response;
        let apiErr = false;
        try {
          const password = 'kdbjdsvjsdvjsdvjvgsdivsdgviug';
          const passwordHash = await bcrypt.hash(password, 10);

          const resourcesExist: Resource[] = await medplum.searchResources(
            'Patient',
            `phone=${encodeURIComponent(`+${mobilePhone}`)}&_count=1000`
          );
          const patients = resourcesExist.filter(
            (resourceTemp) => resourceTemp.resourceType === 'Patient'
          ) as Patient[];

          if (patients.length > 0) {
            notifications.show({
              title: 'Patient Already Exists',
              message: 'Patient already exists with the provided phone number',
              color: 'red',
            });
          } else {
            // const user = await medplum.createResource<User>({
            await medplum.createResource<User>({
              resourceType: 'User',
              firstName: firstName,
              lastName: lastName,
              email: email,
              passwordHash: passwordHash,
              project: { reference: `Project/${selectedPractitioner?.meta?.project}` },
            });

            // const uid = await medplum.post(`/auth/create-akuser`, {
            //   user,
            //   userName: `${firstName} ${lastName}`,
            //   phone: `+${mobilePhone}`,
            //   passwordHash: password,
            // });
            // // await systemRepo.updateResource<User>({ ...result, authentikId: uid });
            // console.log('uid', uid);
            const profile = await medplum.createResource<ProfileResource>({
              resourceType: 'Patient',
              meta: {
                project: selectedPractitioner?.meta?.project,
              },
              name: [{ given: [firstName], family: lastName }],
              telecom: [
                { system: 'phone', value: `+${mobilePhone}`, use: 'mobile' },
                {
                  system: 'email',
                  value: email,
                  use: 'work',
                },
              ],
              birthDate: birthDate ? birthDate.toISODate() || undefined : undefined,
              active: true,
              gender: sex || undefined,
            });

            try {
              const text = `Appointment has been created for ${firstName} ${lastName}`;

              await medplum.createResource({
                resourceType: 'Communication',
                status: 'completed',
                subject: createReference(profile),
                sender: createReference(sender),
                sent: new Date().toISOString(),
                payload: [{ contentString: text }],
              });
            } catch (error) {
              console.log('error', error);
            }

            // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            // const currentDateTime = DateTime.now().setZone(userTimezone).set({ second: 0, millisecond: 0 });

            const timezone =
              selectedLocation?.extension?.find((ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone')
                ?.valueString || 'America/New_York';

            // const selectedTimezone = visitType === VisitType.Prebook ? timezone : userTimezone;

            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const currentDateTime = DateTime.now().setZone(userTimezone).set({ second: 0, millisecond: 0 }).toISO();

            const startTime = visitType === VisitType.Prebook && slot ? slot : currentDateTime || undefined;

            const selectedTimezone = visitType === VisitType.Prebook ? timezone : userTimezone;

            const appointmentData: Appointment = {
              resourceType: 'Appointment',
              status: 'booked',
              minutesDuration: 15,
              participant: [
                {
                  actor: createReference(profile),
                  status: 'accepted',
                },
                {
                  actor: selectedPractitioner
                    ? createReference(selectedPractitioner as MedplumPractitioner)
                    : undefined,
                  status: 'accepted',
                },
                {
                  actor: selectedLocation ? createReference(selectedLocation as MedplumLocation) : undefined,
                  status: 'accepted',
                },
              ],
              start: startTime, // Example: "2024-10-17T09:00:00Z"
              end: startTime, // Example: "2024-10-17T10:00:00Z"
              appointmentType: visitType
                ? { coding: [{ code: visitType, system: 'http://fhir.com/visit-type' }] }
                : undefined,
              serviceType: [{ coding: [{ code: 'in-person', system: 'http://fhir.com/service-type' }] }],
              // reasonCode: reasonForVisit.map((reason) => ({ text: reason })),
              reasonCode: reasonForVisit.map((reason) => ({
                coding: [{ code: reason, display: reason, system: 'http://fhir.com/reason-for-visit' }],
              })),
              extension: [
                {
                  url: 'http://hl7.org/fhir/StructureDefinition/timezone', // Custom extension for time zone
                  valueString: selectedTimezone,
                },
              ],
            };

            response = await medplum.createResource<Appointment>(appointmentData);
            console.log('response', response);

            const encounter = await medplum.createResource({
              resourceType: 'Encounter',
              status: visitType === VisitType.Now ? 'arrived' : 'planned',
              subject: createReference(selectedPatient),
              class: {
                code: 'AMB',
                display: 'ambulatory',
              },
              participant: [{ individual: createReference(selectedPractitioner as MedplumPractitioner) }],
              appointment: [createReference(response)],
              period: {
                start: startTime,
                end: startTime,
              },
              location: [{ location: createReference(selectedLocation as MedplumLocation) }],
            });

            console.log('encounter', encounter);
            await getUtilsData();
          }
        } catch (error) {
          console.error(`Failed to add patient: ${error}`);
          apiErr = true;
        } finally {
          setLoading(false);
          if (response && !apiErr) {
            notifications.show({
              title: 'Appointment Created',
              message: 'Appointment created successfully',
              color: 'green',
            });
            // navigate('/appointment');
            close();
          } else {
            setErrors({ submit: true });
          }
        }
      }
    }
  };
  // console.log(slot);

  const handlePatientSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    if (mobilePhone.length !== 11) {
      setErrors({ ...errors, phone: true });
      return;
    }
    setSearching(true);
    setErrors({ ...errors, search: false });
    setShowFields({ ...showFields, forcePatientSearch: false });

    // if (!fhirClient) {
    //   return;
    // }

    // const patient = await medplum.search('Patient', `phone=${encodeURIComponent(`+${mobilePhone}`)}`);
    // console.log('patient', patient);
    // console.log('mobilePhone', mobilePhone);

    // const resources: Resource[] = await medplum.searchResources({
    //   resourceType: 'Person',
    //   searchParams: [
    //     {
    //       name: 'telecom',
    //       value: `+1${mobilePhone}`,
    //     },
    //     {
    //       name: '_include',
    //       value: 'Person:relatedperson',
    //     },
    //     {
    //       name: '_include:iterate',
    //       value: 'RelatedPerson:patient',
    //     },
    //   ],
    // });
    const resources: Resource[] = await medplum.searchResources(
      'Patient',
      `phone=${encodeURIComponent(`+${mobilePhone}`)}&_count=1000`
    );
    const patients = resources.filter((resourceTemp) => resourceTemp.resourceType === 'Patient') as Patient[];
    patients.sort(sortPatientsByName);
    setPatients(patients);
    setOpenSearchResults(true);
    setSearching(false);
  };

  const sortPatientsByName = (a: Patient, b: Patient): number => {
    const lastNameA = a?.name?.[0].family;
    const lastNameB = b?.name?.[0].family;
    const firstNameA = a?.name?.[0].given?.join(' ');
    const firstNameB = b?.name?.[0].given?.join(' ');
    if (lastNameA && lastNameB && firstNameA && firstNameB) {
      // sort by last name
      if (lastNameA < lastNameB) {
        return -1;
      } else if (lastNameA > lastNameB) {
        return 1;
      } else {
        // if same last name, sort by first name
        return firstNameA.localeCompare(firstNameB);
      }
    } else {
      return 0;
    }
  };

  const getEmailForPatient = (patient: Patient): string => {
    // let email;
    // const emailUser = patient.extension?.find((ext) => ext.url === `${PRIVATE_EXTENSION_BASE_URL}/form-user`)
    //   ?.valueString as any;
    // if (emailUser) {
    // if (emailUser === 'Parent/Guardian') {
    //   const guardianContact = patient.contact?.find((contact) =>
    //     contact.relationship?.find((relationship) => relationship?.coding?.[0].code === 'Parent/Guardian')
    //   );
    //   email = guardianContact?.telecom?.find((telecom) => telecom.system === 'email')?.value;
    // } else {
    const email = patient.telecom?.find((telecom) => telecom.system === 'email')?.value;
    // }
    // }
    return email || 'not found';
  };

  const handleSelectPatient = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selected = patients?.find((patient) => patient.id === event.target.value);
    setSelectedPatient(selected);
  };

  return (
    // <PageContainer>
    <Grid container direction="row">
      {/* <Grid item xs={3.5} /> */}
      <Grid item xs={12}>
        {/* <CustomBreadcrumbs
          chain={[
            { link: '/Appointment', children: 'Appointments' },
            { link: '#', children: 'Add Patient' },
          ]}
        /> */}

        {/* page title */}

        {/* <Typography variant="h3" marginTop={1} color={'primary.dark'}>
          Add Patient
        </Typography> */}

        {/* form content */}
        <Paper>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <Box padding={3} marginTop={2}>
              {/* Location Select */}
              <Typography variant="h4" color="primary.dark">
                Office
              </Typography>

              {/* location search */}
              <Box sx={{ marginTop: 2 }}>
                <LocationSelect
                  location={selectedLocation}
                  setLocation={setSelectedLocation}
                  updateURL={false}
                  required
                  renderInputProps={{ disabled: false }}
                />
              </Box>

              {/* Practitioner search */}
              <Box sx={{ marginTop: 2 }}>
                <PractitionerSelect
                  practitioner={selectedPractitioner}
                  setPractitioner={setSelectedPractitioner}
                  updateURL={false}
                  required
                  renderInputProps={{ disabled: false }}
                />
              </Box>

              {/* Patient information */}
              <Box>
                <Typography variant="h4" color="primary.dark" sx={{ marginTop: 4 }}>
                  Patient information
                </Typography>
                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={8}>
                      <PatternFormat
                        customInput={TextField}
                        value={mobilePhone}
                        format="+(###) ###-#####" // 177 028-24845
                        mask=" "
                        label="Mobile Phone"
                        variant="outlined"
                        placeholder="(XXX) XXX-XXXX"
                        fullWidth
                        required
                        error={errors.phone}
                        helperText={errors?.phone ? phoneNumberErrorMessage : ''}
                        onValueChange={(values, sourceInfo) => {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                          if (sourceInfo.source === 'event') {
                            setMobilePhone(values.value);
                            if (errors.phone && values.value.length === 11) {
                              setErrors({ ...errors, phone: false });
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={4}
                      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '0 8px' }}
                    >
                      <LoadingButton
                        variant="contained"
                        onClick={(event) => handlePatientSearch(event)}
                        loading={searching}
                        sx={{
                          borderRadius: 100,
                          textTransform: 'none',
                          fontWeight: 600,
                        }}
                      >
                        Search for Patients
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
                <Dialog
                  open={openSearchResults}
                  onClose={() => {
                    setSelectedPatient(undefined);
                    setOpenSearchResults(false);
                  }}
                  maxWidth="md"
                  sx={{
                    minWidth: '600px',
                  }}
                >
                  <Box
                    sx={{ minWidth: '600px', borderRadius: '4px', p: '35px', maxHeight: '450px', overflow: 'scroll' }}
                  >
                    <Box>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: '600 !important', color: theme.palette.primary.main, marginBottom: '4px' }}
                      >
                        Select an Existing QRS Patient
                      </Typography>
                    </Box>
                    <Box>
                      <RadioGroup onChange={(e) => handleSelectPatient(e)}>
                        {patients?.map((patient) => {
                          const label = `${patient.name?.[0].family}, ${
                            patient.name?.[0].given
                          } (Email: ${getEmailForPatient(patient)})`;
                          // } (DOB: ${DateTime.fromISO(patient?.birthDate || '').toFormat('MMMM dd, yyyy')})`;
                          return (
                            <FormControlLabel key={patient.id} value={patient.id} control={<Radio />} label={label} />
                          );
                        })}
                      </RadioGroup>
                    </Box>
                    {selectedPatient && (
                      <Box sx={{ marginTop: 2 }}>
                        <Button
                          variant="outlined"
                          sx={{
                            borderRadius: 100,
                            textTransform: 'none',
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            setShowFields({ ...showFields, prefillForSelected: true });
                            setOpenSearchResults(false);
                          }}
                        >
                          Prefill for {selectedPatient?.name?.[0].given}
                        </Button>
                      </Box>
                    )}
                    <Box sx={{ marginTop: 2 }}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 100,
                          textTransform: 'none',
                          fontWeight: 600,
                        }}
                        onClick={() => {
                          setSelectedPatient(undefined);
                          setShowFields({ ...showFields, prefillForSelected: false });
                          setOpenSearchResults(false);
                        }}
                      >
                        Patient Not Found in QRS - Add Manually
                      </Button>
                    </Box>
                  </Box>
                </Dialog>
                {searching && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} marginTop={2}>
                    <CircularProgress />
                  </Box>
                )}
                {showFields.prefillForSelected && selectedPatient && (
                  <Box marginTop={3}>
                    <Typography variant="h4" color="primary.dark">
                      {selectedPatient.name?.[0].given} {selectedPatient.name?.[0].family}
                    </Typography>
                    {selectedPatient.gender && <Typography>Birth Sex: {selectedPatient.gender}</Typography>}
                    <Typography>Email: {getEmailForPatient(selectedPatient)}</Typography>
                    <Typography>
                      Birthday: {DateTime.fromISO(selectedPatient?.birthDate || '').toFormat('MMMM dd, yyyy')}
                    </Typography>
                  </Box>
                )}
                {!showFields.forcePatientSearch && !showFields.prefillForSelected && !searching && (
                  <Box marginTop={2}>
                    <Box>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={5.85}>
                          <TextField
                            label="First Name"
                            variant="outlined"
                            required
                            fullWidth
                            onChange={(event) => {
                              setFirstName(event.target.value);
                            }}
                          ></TextField>
                        </Grid>
                        <Grid item xs={5.85}>
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            required
                            fullWidth
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box marginTop={2}>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={5.85}>
                          <DateSearch
                            date={birthDate}
                            setDate={setBirthDate}
                            defaultValue={null}
                            label="Date of birth"
                            required
                            setIsValidDate={setValidDate}
                          ></DateSearch>
                        </Grid>
                        <Grid item xs={5.85}>
                          <FormControl fullWidth>
                            <InputLabel id="sex-at-birth-label">Sex at birth *</InputLabel>
                            <Select
                              labelId="sex-at-birth-label"
                              id="sex-at-birth-select"
                              value={sex}
                              label="Sex at birth *"
                              required
                              onChange={(event) => {
                                setSex(event.target.value as PersonSex);
                              }}
                            >
                              <MenuItem value={PersonSex.Male}>Male</MenuItem>
                              <MenuItem value={PersonSex.Female}>Female</MenuItem>
                              <MenuItem value={PersonSex.Intersex}>Intersex</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box marginTop={2}>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                            type="email"
                          ></TextField>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Visit Information */}
              {!showFields.forcePatientSearch && !searching && (
                <Box marginTop={4}>
                  <Typography variant="h4" color="primary.dark">
                    Visit information
                  </Typography>
                  <Box marginTop={2}>
                    <Autocomplete
                      multiple
                      autoComplete
                      disableClearable
                      forcePopupIcon
                      id="reason-for-visit-label"
                      options={ReasonForVisitOptions}
                      freeSolo
                      value={reasonForVisit}
                      inputValue={inputValue}
                      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                      onChange={(_, newValue) => handleReasonsForVisitChange(newValue)}
                      onBlur={() => {
                        if (inputValue.trim() !== '') {
                          handleReasonsForVisitChange([...reasonForVisit, inputValue.trim()]);
                        }
                        setInputValue('');
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} sx={{ fontSize: 16 }} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reason for visit"
                          variant="outlined"
                          fullWidth
                          required={reasonForVisit.length === 0}
                          error={!validReasonForVisit}
                          helperText={validReasonForVisit ? '' : reasonForVisitErrorMessage}
                        />
                      )}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiFilledInput-root': {
                          fontSize: 16,
                          p: 0.5,
                          border: '1px solid',
                          borderRadius: 2,
                          '&::before, ::after, :hover:not(.Mui-disabled, .Mui-error)::before': {
                            borderBottom: 0,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box marginTop={2}>
                    <FormControl fullWidth>
                      <InputLabel id="visit-type-label">Visit type *</InputLabel>
                      <Select
                        labelId="visit-type-label"
                        id="visit-type-select"
                        value={visitType || ''}
                        label="Visit type *"
                        required
                        onChange={(event) => {
                          setSlot(undefined);
                          setVisitType(event.target.value as VisitType);
                        }}
                      >
                        <MenuItem value={VisitType.Now}>Now</MenuItem>
                        <MenuItem value={VisitType.Prebook}>Pre-booked visit</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {visitType === VisitType.Prebook && (
                    <SlotPicker
                      slotData={locationWithSlotData?.availableSlots}
                      slotsLoading={loadingSlotState.status === 'loading'}
                      timezone={locationWithSlotData?.timezone || 'Undefined'}
                      selectedSlot={slot}
                      setSelectedSlot={setSlot}
                    />
                  )}
                </Box>
              )}

              {showFields.forcePatientSearch && (
                <Box marginTop={4}>
                  <Typography variant="body1" color="primary.dark">
                    Please enter the mobile number and search for existing patients before proceeding.
                  </Typography>
                </Box>
              )}

              {/* form buttons */}
              <Box marginTop={4}>
                {errors.submit && (
                  <Typography color="error" variant="body2" mb={2}>
                    Failed to add patient. Please try again.
                  </Typography>
                )}
                {errors.search && (
                  <Typography color="error" variant="body2" mb={2}>
                    Please search for patients before adding
                  </Typography>
                )}
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loading || searching}
                  sx={{
                    borderRadius: 100,
                    textTransform: 'none',
                    fontWeight: 600,
                    marginRight: 1,
                  }}
                >
                  Add {visitType}
                </LoadingButton>
                <Button
                  sx={{
                    borderRadius: 100,
                    textTransform: 'none',
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    // navigate('/Appointment');
                    close();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
          <CustomDialog
            open={selectSlotDialogOpen}
            title="Please select a date and time"
            description="To continue, please select an available appointment."
            closeButtonText="Close"
            handleClose={() => setSelectSlotDialogOpen(false)}
          />
        </Paper>
      </Grid>

      {/* <Grid item xs={3.5} /> */}
    </Grid>
    // </PageContainer>
  );
}
