import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { Location } from 'fhir/r4';
import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
// import { UCAppointmentInformation } from 'ehr-utils';
import { AppointmentsStatusChipsCount } from './AppointmentStatusChipsCount';
import AppointmentTableRow from './AppointmentTableRow';
import { ApptTab } from './AppointmentTabs';
import { Appointment } from '@medplum/fhirtypes';
import { SMSModel, VisitStatusHistoryEntry } from '../types/types';
import { VisitStatus } from '../helpers/mappingUtils';

export type AppointmentType = 'now' | 'prebook';

export interface AppointmentMessaging {
  id: NonNullable<Appointment['id']>;
  smsModel?: SMSModel;
  patient: {
    id: string;
    firstName?: string;
    lastName?: string;
    dateOfBirth: string;
    sex?: string;
    phone?: string;
  };
}

export interface UCAppointmentInformation extends AppointmentMessaging {
  id: string;
  start: string;
  unconfirmedDOB: string;
  reasonForVisit: string;
  comment: string | undefined;
  appointmentType?: AppointmentType;
  appointmentStatus: string;
  status: VisitStatus;
  cancellationReason: string | undefined;
  provider: string | undefined;
  group: string | undefined;
  paperwork: {
    demographics: boolean;
    photoID: boolean;
    insuranceCard: boolean;
    consent: boolean;
    ovrpInterest: boolean;
  };
  next: boolean;
  visitStatusHistory: VisitStatusHistoryEntry[];
  needsDOBConfirmation: boolean | undefined;
  waitingMinutes?: number;
}

export interface AppointmentTableProps {
  appointments: UCAppointmentInformation[];
  location: Location | undefined;
  tab: ApptTab;
  now: DateTime;
  updateAppointments: () => void;
  setEditingComment: (editingComment: boolean) => void;
}

export default function AppointmentTable({
  appointments,
  location,
  tab,
  now,
  updateAppointments,
  setEditingComment,
}: AppointmentTableProps): ReactElement {
  const theme = useTheme();
  const actionButtons = tab === ApptTab.prebooked;
  const showTime = tab !== ApptTab.prebooked;
  const [collapseWaiting, setCollapseWaiting] = useState<boolean>(false);
  const [collapseExam, setCollapseExam] = useState<boolean>(false);

  return (
    <>
      <AppointmentsStatusChipsCount appointments={appointments} />
      <Paper>
        <TableContainer sx={{ overflow: 'inherit' }}>
          <Table style={{ tableLayout: 'fixed', width: '100%' }}>
            {/* column widths must add up to the table width ^ */}
            <TableHead>
              <TableRow>
                {/* <TableCell style={{ width: '15%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Type & Status
                  </Typography>
                </TableCell> */}
                {/* {showTime && ( */}
                {/* <TableCell style={{ width: '13%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Requested Time <br /> (Local Timezone)
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '13%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Requested Time <br /> (User Timezone)
                  </Typography>
                </TableCell> */}
                {/* )} */}
                <TableCell style={{ width: '12%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Patient
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '13%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Location
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '13%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Surgeon
                  </Typography>
                </TableCell>
                {/* <TableCell style={{ width: '16%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Reason
                  </Typography>
                </TableCell> */}
                {/* <TableCell style={{ width: '14%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Visit Components
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '14%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Provider
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '14%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Group
                  </Typography>
                </TableCell> */}
                <TableCell style={{ width: '7%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Notes
                  </Typography>
                </TableCell>
                {/* <TableCell style={{ width: '5%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Chat
                  </Typography>
                </TableCell> */}
                {/* {tab === ApptTab.prebooked && ( */}
                <TableCell style={{ width: '12%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                    Action
                  </Typography>
                </TableCell>
                {/* )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tab === ApptTab['in-office'] ? (
                <>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: alpha(theme.palette.secondary.main, 0.08) }} colSpan={9}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => setCollapseWaiting(!collapseWaiting)} sx={{ mr: 0.75, p: 0 }}>
                          <ArrowDropDownCircleOutlinedIcon
                            sx={{
                              color: theme.palette.primary.main,
                              rotate: collapseWaiting ? '' : '180deg',
                            }}
                          ></ArrowDropDownCircleOutlinedIcon>
                        </IconButton>
                        {/* todo add a count to the this title */}
                        <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                          Waiting Room (
                          {
                            appointments.filter((appointmentTemp) => {
                              return appointmentTemp.status === 'arrived' || appointmentTemp.status === 'ready';
                            }).length
                          }
                          )
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {!collapseWaiting &&
                    // todo add logic to filter out appointments that are not waiting
                    appointments
                      .filter((appointmentTemp) => {
                        return appointmentTemp.status === 'arrived' || appointmentTemp.status === 'ready';
                      })
                      .map((appointment, idx) => {
                        return (
                          <AppointmentTableRow
                            key={idx}
                            appointment={appointment}
                            location={location}
                            actionButtons={actionButtons}
                            showTime={showTime}
                            now={now}
                            updateAppointments={updateAppointments}
                            setEditingComment={setEditingComment}
                            tab={tab}
                          ></AppointmentTableRow>
                        );
                      })}
                  <TableRow>
                    <TableCell sx={{ backgroundColor: alpha(theme.palette.secondary.main, 0.08) }} colSpan={9}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => setCollapseExam(!collapseExam)} sx={{ mr: 0.75, p: 0 }}>
                          <ArrowDropDownCircleOutlinedIcon
                            sx={{
                              color: theme.palette.primary.main,
                              rotate: collapseExam ? '' : '180deg',
                            }}
                          ></ArrowDropDownCircleOutlinedIcon>
                        </IconButton>
                        {/* todo add a count to the this title */}
                        <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                          In Exam (
                          {
                            appointments.filter((appointmentTemp) => {
                              return appointmentTemp.status !== 'arrived' && appointmentTemp.status !== 'ready';
                            }).length
                          }
                          )
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {!collapseExam &&
                    // todo add logic to filter out appointments that are not in exam
                    appointments
                      .filter((appointmentTemp) => {
                        return appointmentTemp.status !== 'arrived' && appointmentTemp.status !== 'ready';
                      })
                      .map((appointment, idx) => {
                        return (
                          <AppointmentTableRow
                            key={idx}
                            appointment={appointment}
                            location={location}
                            actionButtons={actionButtons}
                            showTime={showTime}
                            now={now}
                            updateAppointments={updateAppointments}
                            setEditingComment={setEditingComment}
                            tab={tab}
                          ></AppointmentTableRow>
                        );
                      })}
                </>
              ) : (
                appointments?.map((appointment, idx) => {
                  return (
                    <AppointmentTableRow
                      key={idx}
                      appointment={appointment}
                      location={location}
                      actionButtons={actionButtons}
                      showTime={showTime}
                      now={now}
                      updateAppointments={updateAppointments}
                      setEditingComment={setEditingComment}
                      tab={tab}
                    ></AppointmentTableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
