import { ProfileResource } from '@medplum/core';
import {
  Patient,
  Practitioner,
  PractitionerRole,
  ProjectMembership,
  Location,
  CalendarAccess,
} from '@medplum/fhirtypes';
import { useMedplum } from '@medplum/react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

export interface UtilsData {
  allPatients: Patient[];
  allLocations: Location[];
  allPractitioners: Practitioner[];
  allPractitionerRoles: PractitionerRole[];
}

export interface UserContextType {
  profile: ProfileResource | Practitioner | undefined;
  userProjectMembership: ProjectMembership | undefined;
  practitionerReferences: string[];
  utilsData: UtilsData;
  setUtilsData: React.Dispatch<React.SetStateAction<UtilsData>>;
  pAddNote: boolean;
  pAddSurgery: boolean;
  pDeleteNote: boolean;
  pDeleteSurgery: boolean;
  pInviteUser: boolean;
  pUpdateNote: boolean;
  pUpdatePatient: boolean;
  pUpdateSurgery: boolean;
  pViewPatient: boolean;
  surgeonOptions: { label: string; value: string }[];
  locationOptions: { label: string; value: string }[];
  associatedStaffs: any[];
  associatedSurgeons: any[];
  setAssociatedStaff: React.Dispatch<React.SetStateAction<any[]>>;
  setAssociatedSurgeons: React.Dispatch<React.SetStateAction<any[]>>;
  setPractitionerReferences: React.Dispatch<React.SetStateAction<string[]>>;
  getUtilsData: () => Promise<void>;
  allAssociatedUsers: any[];
  setAllAssociatedUsers: React.Dispatch<React.SetStateAction<any[]>>;
  allProjectMemberships: ProjectMembership[];
}

const UserContext = createContext<UserContextType | undefined>(undefined);

const sortLocationsByLabel = (locations: { label: string; value: string }[]): { label: string; value: string }[] => {
  function compare(a: { label: string; value: string }, b: { label: string; value: string }): number {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();

    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }

  locations.sort(compare);

  return locations;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const medplum = useMedplum();
  const profile = medplum.getProfile();
  const userProjectMembership = medplum.getProjectMembership();
  const [practitionerReferences, setPractitionerReferences] = useState<string[]>([]);
  const [associatedStaffs, setAssociatedStaff] = useState<any[]>([]);
  const [associatedSurgeons, setAssociatedSurgeons] = useState<any[]>([]);
  const [allAssociatedUsers, setAllAssociatedUsers] = useState<any[]>([]);
  const [allProjectMemberships, setAllProjectMemberships] = useState<ProjectMembership[]>([]);

  const [utilsData, setUtilsData] = useState<UtilsData>({
    allPatients: [],
    allLocations: [],
    allPractitioners: [],
    allPractitionerRoles: [],
  });

  const getUtilsData = async (): Promise<void> => {
    const _count = 10000000;

    const results = await Promise.allSettled([
      medplum.search('Location', { _count, _sort: 'name' }),
      medplum.search('ProjectMembership', { _count }),
      medplum.search('PractitionerRole', { _count }),
      medplum.search('Patient', { _count, _sort: 'name' }),
      medplum.search('Practitioner', { _count }),
      medplum.search('CalendarAccess', {
        _count,
        receiver: `${userProjectMembership?.resourceType}/${userProjectMembership?.id}`,
      }),
      medplum.search('CalendarAccess', {
        _count,
        practitioner: userProjectMembership?.profile.reference,
        // _include: 'CalendarAccess:receiver',
      }),
      medplum.search('CalendarAccess', { _count }),
    ]);

    const locations =
      results[0].status === 'fulfilled'
        ? (results[0].value.entry?.map((entry) => entry.resource as Location) ?? [])
        : [];

    const practitionersMemberships =
      results[1].status === 'fulfilled'
        ? (results[1].value.entry?.map((entry) => entry.resource as ProjectMembership) ?? [])
        : [];

    const allPractitionerRoles =
      results[2].status === 'fulfilled'
        ? (results[2].value.entry?.map((entry) => entry.resource as PractitionerRole) ?? [])
        : [];

    const patients =
      results[3].status === 'fulfilled'
        ? (results[3].value.entry?.map((entry) => entry.resource as Patient) ?? [])
        : [];

    const practitioners =
      results[4].status === 'fulfilled'
        ? (results[4].value.entry?.map((entry) => entry.resource as Practitioner) ?? [])
        : [];

    const associatedSurgeonsAccessControl =
      results[5].status === 'fulfilled' ? results[5].value.entry?.map((entry) => entry.resource as CalendarAccess) : [];

    const associatedStaffsAccessControl =
      results[6].status === 'fulfilled' ? results[6].value.entry?.map((entry) => entry.resource as CalendarAccess) : [];

    const allAssociatedUsersAccessControl =
      results[7].status === 'fulfilled' ? results[7].value.entry?.map((entry) => entry.resource as CalendarAccess) : [];

    setAllProjectMemberships(practitionersMemberships);

    const associatedSurgeons = [];
    const associatedStaffs = [];

    for (const access of associatedSurgeonsAccessControl ?? []) {
      const practitionerReference = access?.practitioner?.reference;

      const receiverReference = access?.receiver?.reference;
      const senderReference = access?.sender?.reference;

      const practitioner = practitioners.find((p) => p.id === practitionerReference?.split('/')[1]);
      const receiverDetails = practitionersMemberships.find((p) => p.id === receiverReference?.split('/')[1]);
      const senderDetails = practitionersMemberships.find((p) => p.id === senderReference?.split('/')[1]);

      if (receiverDetails) {
        const practitionerDetails = practitioners.find(
          (p) => p.id === receiverDetails?.profile?.reference?.split('/')[1]
        );

        (receiverDetails as any).profileDetails = practitionerDetails;
      }

      if (receiverDetails) {
        const practitionerDetails = practitioners.find(
          (p) => p.id === receiverDetails?.profile?.reference?.split('/')[1]
        );

        (receiverDetails as any).profileDetails = practitionerDetails;
      }

      if (senderDetails) {
        const senderProfile = practitioners.find((p) => p.id === senderDetails?.profile?.reference?.split('/')[1]);

        if (senderProfile) {
          (senderDetails as any).profileDetails = senderProfile;
        } else {
          const [resourceType, id] = senderDetails?.profile?.reference
            ? senderDetails.profile.reference.split('/')
            : [undefined, undefined];
          if (resourceType && id) {
            const senderProfileDetails = await medplum.readResource(resourceType, id);
            (senderDetails as any).profileDetails = senderProfileDetails;
          }
        }
      }

      associatedSurgeons.push({
        ...access,
        practitionerDetails: practitioner,
        receiverDetails,
        senderDetails,
      });
    }

    for (const access of associatedStaffsAccessControl ?? []) {
      const practitionerReference = access?.practitioner?.reference;

      const receiverId = access?.receiver?.reference?.split('/')[1];
      const senderId = access?.sender?.reference?.split('/')[1];
      const practitioner = practitioners.find((p) => p.id === practitionerReference?.split('/')[1]);
      const receiverDetails = practitionersMemberships.find((p) => p.id === receiverId);
      const senderDetails = practitionersMemberships.find((p) => p.id === senderId);

      if (receiverDetails) {
        const practitionerDetails = practitioners.find(
          (p) => p.id === receiverDetails?.profile?.reference?.split('/')[1]
        );

        (receiverDetails as any).profileDetails = practitionerDetails;
      }

      if (senderDetails) {
        const senderProfile = practitioners.find((p) => p.id === senderDetails?.profile?.reference?.split('/')[1]);

        if (senderProfile) {
          (senderDetails as any).profileDetails = senderProfile;
        } else {
          const [resourceType, id] = senderDetails?.profile?.reference
            ? senderDetails.profile.reference.split('/')
            : [undefined, undefined];
          if (resourceType && id) {
            const senderProfileDetails = await medplum.readResource(resourceType, id);
            (senderDetails as any).profileDetails = senderProfileDetails;
          }
        }
      }

      associatedStaffs.push({
        ...access,
        practitionerDetails: practitioner,
        receiverDetails,
        senderDetails,
      });
    }

    if (userProjectMembership?.admin) {
      const associatedUsers = [];

      for (const access of allAssociatedUsersAccessControl ?? []) {
        const practitionerReference = access?.practitioner?.reference;

        const receiverId = access?.receiver?.reference?.split('/')[1];
        const senderId = access?.sender?.reference?.split('/')[1];
        const practitioner = practitioners.find((p) => p.id === practitionerReference?.split('/')[1]);
        const receiverDetails = practitionersMemberships.find((p) => p.id === receiverId);
        const senderDetails = practitionersMemberships.find((p) => p.id === senderId);

        if (receiverDetails) {
          const practitionerDetails = practitioners.find(
            (p) => p.id === receiverDetails?.profile?.reference?.split('/')[1]
          );

          (receiverDetails as any).profileDetails = practitionerDetails;
        }

        if (senderDetails) {
          const senderProfile = practitioners.find((p) => p.id === senderDetails?.profile?.reference?.split('/')[1]);

          if (senderProfile) {
            (senderDetails as any).profileDetails = senderProfile;
          } else {
            const [resourceType, id] = senderDetails?.profile?.reference
              ? senderDetails.profile.reference.split('/')
              : [undefined, undefined];
            if (resourceType && id) {
              const senderProfileDetails = await medplum.readResource(resourceType, id);
              (senderDetails as any).profileDetails = senderProfileDetails;
            }
          }
        }

        associatedUsers.push({
          ...access,
          practitionerDetails: practitioner,
          receiverDetails,
          senderDetails,
        });
      }

      setAllAssociatedUsers(associatedUsers);
    }

    setAssociatedSurgeons(associatedSurgeons ?? []);
    setAssociatedStaff(associatedStaffs ?? []);

    const associatedPractitionersRefs = [];

    if (userProjectMembership?.title === 'Surgeon') {
      associatedPractitionersRefs.push(`Practitioner/${profile?.id}`);
    }

    // if (userProjectMembership?.title === 'Staff') {
    for (const surgeon of associatedSurgeons) {
      if (surgeon?.practitioner?.reference) {
        associatedPractitionersRefs.push(surgeon?.practitioner?.reference);
      }
    }
    // }

    const uniqueAssociatedPractitionersRefs = [...new Set(associatedPractitionersRefs)];
    setPractitionerReferences(uniqueAssociatedPractitionersRefs);

    const updatedAllPatients = patients.map((p) => {
      const insuranceStatus =
        p?.extension?.find(
          (ext: { url: string }) => ext.url === 'http://medplum.com/fhir/StructureDefinition/insurance-status'
        )?.valueString || 'Unverified';

      return {
        ...p,
        insuranceStatus,
      };
    });

    const practitionersUpdatedList = practitionersMemberships
      .filter((el) => el.title === 'Surgeon' || el.authRole === 'Surgeon')
      .filter((membership) => membership?.profile?.reference?.split('/')[0] === 'Practitioner')
      .map((projectMembership) => projectMembership?.profile?.reference);

    const unique = [...new Set(practitionersUpdatedList)];
    const allPractitioners = [];

    for (const practitionerReference of unique) {
      if (practitionerReference) {
        const practitioner = await medplum.readResource('Practitioner', practitionerReference.split('/')[1]);
        allPractitioners.push(practitioner);
      }
    }

    setUtilsData({
      allLocations: locations,
      allPractitioners: allPractitioners,
      allPractitionerRoles: allPractitionerRoles,
      allPatients: updatedAllPatients,
    });
  };

  useEffect(() => {
    if (profile) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getUtilsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const pAddNote = userProjectMembership?.admin || (userProjectMembership?.pAddNote ?? false);
  const pAddSurgery = userProjectMembership?.admin || (userProjectMembership?.pAddSurgery ?? false);
  const pDeleteNote = userProjectMembership?.admin || (userProjectMembership?.pDeleteNote ?? false);
  const pDeleteSurgery = userProjectMembership?.admin || (userProjectMembership?.pDeleteSurgery ?? false);
  const pInviteUser = userProjectMembership?.admin || (userProjectMembership?.pInviteUser ?? false);
  const pUpdateNote = userProjectMembership?.admin || (userProjectMembership?.pUpdateNote ?? false);
  const pUpdatePatient = userProjectMembership?.admin || (userProjectMembership?.pUpdatePatient ?? false);
  const pUpdateSurgery = userProjectMembership?.admin || (userProjectMembership?.pUpdateSurgery ?? false);
  const pViewPatient = userProjectMembership?.admin || (userProjectMembership?.pViewPatient ?? false);

  const { allPractitionerRoles, allPractitioners, allLocations } = utilsData;

  const surgeonOptions = useMemo(() => {
    const allPractitioner = allPractitioners.map((practitioner) => {
      const name = `${practitioner?.name?.[0]?.prefix?.[0] ?? ''} ${practitioner?.name?.[0]?.given?.[0] ?? ''} ${practitioner?.name?.[0]?.family ?? ''}`;

      const practitionerRole = allPractitionerRoles.find(
        (practitionerRoleTemp) => practitionerRoleTemp.practitioner?.reference === `Practitioner/${practitioner.id}`
      );

      if (!practitionerRole) {
        return { label: name, value: practitioner.id };
      }

      const practitionerRoleExtension = practitionerRole.extension?.find((ext) => ext.url === 'average-session-time');
      if (practitionerRoleExtension) {
        const value = practitionerRoleExtension?.valueDuration?.value ?? undefined;
        const averageSessionTime = value !== undefined && !isNaN(value) ? Math.round(value) : 0;

        if (averageSessionTime > 0) {
          return {
            label: `${name} - Est. Session ${averageSessionTime} min`,
            value: practitioner.id,
          };
        }
      }

      return { label: name, value: practitioner.id };
    });

    return sortLocationsByLabel(allPractitioner as { label: string; value: string }[]);
  }, [allPractitioners, allPractitionerRoles]);

  const locationOptions = useMemo(() => {
    const allLocationsOpt = allLocations.map((location: Location) => {
      const locationWaitingExtension = location.extension?.find((ext) => ext.url === 'average-waiting-time');
      if (locationWaitingExtension) {
        const value = locationWaitingExtension?.valueDuration?.value ?? undefined;
        const averageWaitingTime = value !== undefined && !isNaN(value) ? Math.round(value) : 0;

        if (averageWaitingTime > 0) {
          return {
            label: `${location.name} - Est. Wait ${averageWaitingTime} min`,
            value: location.id,
          };
        }
      }
      return { label: location.name, value: location.id };
    });

    return sortLocationsByLabel(allLocationsOpt as { label: string; value: string }[]);
  }, [allLocations]);

  return (
    <UserContext.Provider
      value={{
        practitionerReferences,
        profile,
        userProjectMembership,
        utilsData,
        setUtilsData,
        pAddSurgery,
        pDeleteNote,
        pDeleteSurgery,
        pInviteUser,
        pUpdateNote,
        pUpdatePatient,
        pUpdateSurgery,
        pViewPatient,
        pAddNote,
        surgeonOptions,
        locationOptions,
        associatedStaffs,
        associatedSurgeons,
        setAssociatedStaff,
        setAssociatedSurgeons,
        setPractitionerReferences,
        getUtilsData,
        allAssociatedUsers,
        setAllAssociatedUsers,
        allProjectMemberships
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
