import { formatSearchQuery, MedplumClient, Operator } from '@medplum/core';
import {
  Practitioner as BasePractitioner,
  Bundle,
  Patient,
  PractitionerRole,
  Resource,
  ResourceType,
  Location as FhirLocation,
} from '@medplum/fhirtypes';

interface Practitioner extends BasePractitioner {
  color?: string;
  backgroundColor?: string;
}

import { useMedplum, useMedplumProfile } from '@medplum/react';
import { useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';
import { Event } from 'react-big-calendar';
import { Grid, Modal } from '@mantine/core';
import Sidebar from './Sidebar';
import CalendarView from './CalendarView';
import SurgeryDetails from './SurgeryDetails';
import Toolbar from './Toolbar';
import { useDisclosure } from '@mantine/hooks';
import AddPatient from './addPatient';
import { Typography } from '@mui/material';
import { useUser } from '../../user.context';

export type Doctor = {
  reference: string;
  display: string;
  photo: string | undefined;
  color: string | undefined;
  backgroundColor: string | undefined;
};

export type Appointment = {
  title: string;
  start: Date;
  end: Date;
  resource: any;
};

export interface UtilsData {
  allPatients?: Patient[];
  allLocations?: Location[];
  allPractitioners?: Practitioner[];
  allPractitionerRoles?: PractitionerRole[];
}

interface props {
  id: string;
  resourceType: string;
  selectedSurgeon?: Practitioner;
}

export function UserSchedulePage({ id, resourceType, selectedSurgeon }: props): JSX.Element {
  const medplum = useMedplum();
  const profile = useMedplumProfile() as Practitioner;
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [allAppointments, setAllAppointments] = useState<Appointment[]>([]);
  const [filteredAppointments, setFilteredAppointments] = useState<Appointment[]>([]);
  const [doctorsList, setDoctorsList] = useState<Doctor[]>([]);
  // const navigate = useNavigate();
  // const [createSlotOpened, createSlotHandlers] = useDisclosure(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  // const { schedule } = useContext(ScheduleContext);
  // const [slots] = useSearchResources('Slot', { schedule: getReferenceString(schedule as Schedule) });
  // const [appointments] = useSearchResources('Appointment', { actor: getReferenceString(profile as Practitioner) });
  const [selectedDoctors, setSelectedDoctors] = useState<string[]>([]);
  // const [events] = useState(initialEvents);
  // const [selectedSurgery, setSelectedSurgery] = useState<Event | null>(null);
  // const [toolbarOptionSelected, setToolbarOptionSelected] = useState('Day');
  // const filteredEvents = events.filter((event) => event.doctors.some((doc) => selectedDoctors.includes(doc.id)));
  const [view, setView] = useState<'day' | 'week' | 'month'>('day');
  const [fetchAppointments, setFetchAppointments] = useState<boolean>(false);
  const [opened, { open, close }] = useDisclosure(false);

  const { utilsData, userProjectMembership } = useUser();

  const { allLocations, allPractitionerRoles, allPractitioners } = utilsData;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getAppointments = async () => {
    const day = dayjs(selectedDate).format('YYYY-MM-DD');
    let start = `${day}T00:00:00Z`;
    let end = `${day}T23:59:59Z`;

    if (view === 'week') {
      const startOfWeek = dayjs(selectedDate).startOf('week').format('YYYY-MM-DD');
      const endofWeek = dayjs(selectedDate).endOf('week').format('YYYY-MM-DD');
      start = `${startOfWeek}T00:00:00Z`;
      end = `${endofWeek}T23:59:59Z`;
    }

    if (view === 'month') {
      const startOfMonth = dayjs(selectedDate).startOf('month').format('YYYY-MM-DD');
      const endOfMonth = dayjs(selectedDate).endOf('month').format('YYYY-MM-DD');
      start = `${startOfMonth}T00:00:00Z`;
      end = `${endOfMonth}T23:59:59Z`;
    }

    const filters = [];

    const practitionersFilter = [];

    const { extension } = profile;

    const filteredInvitedByExt = extension?.find(
      (ext) => ext.url === 'http://medplum.com/fhir/StructureDefinition/calendarInvitedByList'
    );

    if (!userProjectMembership?.admin) {
      const practitionerReferences = [];

      if (filteredInvitedByExt?.extension && filteredInvitedByExt.extension.length > 0) {
        const invitedByList = filteredInvitedByExt?.extension || [];
        // setInvitedByList(invitedByList);
        for (const invitedBy of invitedByList) {
          // filters.push({
          //   code: 'practitioner',
          //   operator: Operator.EQUALS,
          //   value: invitedBy?.valueReference as string,
          // });
          practitionerReferences.push(invitedBy?.valueReference as string);
        }
      }

      if (profile?.resourceType === 'Practitioner') {
        practitionerReferences.push(`Practitioner/${profile.id}`);
        // filters.push({ code: 'practitioner', operator: Operator.EQUALS, value: `Practitioner/${profile.id}` });
      }

      if (practitionerReferences.length > 0) {
        practitionersFilter.push({
          code: 'practitioner',
          operator: Operator.IN,
          value: practitionerReferences.join(','),
        });
      }

      if (practitionerReferences.length === 0) {
        setAllAppointments([]);
        setFilteredAppointments([]);
        setDoctorsList([]);
        return;
      }
    }

    filters.push({ code: 'date', operator: Operator.GREATER_THAN_OR_EQUALS, value: start });
    filters.push({ code: 'date', operator: Operator.LESS_THAN_OR_EQUALS, value: end });

    const response = await medplum.search(
      'Appointment' as ResourceType,
      formatSearchQuery({
        total: 'accurate',
        fields: undefined,
        count: 100000,
        resourceType: 'Appointment',
        sortRules: [{ code: 'date', descending: true }],
        filters: [...filters, ...practitionersFilter],
      })
    );

    const getTimezoneFromExtension = (resource: { extension: any[] }): string => {
      const timezoneExtension = resource.extension?.find(
        (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/timezone'
      );

      return timezoneExtension?.valueString || '';
    };

    // const _count = 10000000;
    // const [locations, practitioners, practitionerRoles] = await Promise.allSettled([
    //   (await medplum.search('Location', { _count, _sort: 'name' })).entry?.map((entry) => entry.resource as Location) ??
    //     [],
    //   (await medplum.search('Practitioner', { _count, _sort: 'name' })).entry?.map(
    //     (entry) => entry.resource as Practitioner
    //   ) ?? [],
    //   (await medplum.search('PractitionerRole', { _count })).entry?.map(
    //     (entry) => entry.resource as PractitionerRole
    //   ) ?? [],
    //   (await medplum.search('Patient', { _count })).entry?.map((entry) => entry.resource as Patient) ?? [],
    // ]);

    // const colors = ['#FFA500', '#20B2AA', '#FF69B4', '#FFD700', '#A52A2A', '#708090'];

    // let allLocations: Location[] = [];
    // let allPractitioners: Practitioner[] = [];
    // let allPractitionerRoles: PractitionerRole[] = [];

    // if (locations.status === 'fulfilled') {
    //   allLocations = locations.value;
    // } else {
    //   console.error('Failed to load locations', locations.reason);
    // }

    // if (practitioners.status === 'fulfilled') {
    //   allPractitioners = practitioners.value;
    // } else {
    //   console.error('Failed to load practitioners', practitioners.reason);
    // }

    // if (practitionerRoles.status === 'fulfilled') {
    //   allPractitionerRoles = practitionerRoles.value;
    // } else {
    //   console.error('Failed to load practitionerRoles', practitionerRoles.reason);
    // }

    const allDoctors: Doctor[] = [];
    const allDoctorsIds: string[] = [];

    for (const entry of response.entry || []) {
      const { participant = [] } = entry.resource || {};

      let patient = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Patient')
      );

      if (patient) {
        const patientDetails = utilsData.allPatients?.find((p) => p.id === patient.actor?.reference?.split('/')[1]);
        // const patientDetails = await medplum.readResource('Patient', patient.actor?.reference?.replace('Patient/', ''));
        // const patientDetails = allPatients.find((p: any) => p?.id === patient.actor?.reference?.split('/')[1]);
        // const email = patientDetails?.telecom?.find((telecom: { system: string }) => telecom.system === 'email')?.value;
        // let user: User | null = null;
        // if (email) {
        //   user = await medplum.searchOne('User', `email=${email}`);
        // }

        // const insuranceStatus =
        //   patientDetails?.extension?.find(
        //     (ext: { url: string }) => ext.url === 'http://medplum.com/fhir/StructureDefinition/insurance-status'
        //   )?.valueString || 'Unverified';

        patient = patient
          ? {
              ...patient,
              ...patient.actor,
              actor: { ...patient.actor, reference: patient.actor?.reference?.replace('Patient/', '') },
              // user: user,
              patientDetails,
              // insuranceStatus,
              // email,
            }
          : undefined;
      }

      let location = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Location')
      );
      const locationRecord: FhirLocation | undefined = allLocations.find(
        (loc: any) => loc?.id === location?.actor?.reference?.split('/')?.[1]
      );

      let locationAverageWaitingTime = null;
      let locationAverageSessionTime = null;

      if (locationRecord) {
        // (locationRecord as any)?.
        const locationWaitTimeExtension = (locationRecord as any)?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-waiting-time'
        );

        // console.log('locationWaitTimeExtension', locationWaitTimeExtension);
        if (locationWaitTimeExtension) {
          locationAverageWaitingTime = locationWaitTimeExtension?.valueDuration?.value;
        }
        const locationAverageSessionTimeExtension = (locationRecord as any)?.extension?.find(
          (ext: { url: string }) => ext.url === 'average-session-time'
        );

        if (locationAverageSessionTimeExtension) {
          locationAverageSessionTime = locationAverageSessionTimeExtension?.valueDuration?.value;
        }

        // console.log('locationAverageSessionTimeExtension', locationAverageSessionTimeExtension);
      }

      if (location) {
        location = location
          ? {
              ...location,
              ...location.actor,
              actor: { ...location.actor, reference: location.actor?.reference?.replace('Location/', '') },
              locationAverageWaitingTime,
              locationAverageSessionTime,
            }
          : undefined;
      }

      let practitioner = participant?.find((participant: { actor: { reference: string } }) =>
        participant.actor?.reference?.startsWith('Practitioner')
      );

      const practitionerRecord = allPractitioners.find(
        (loc: any) => loc?.id === practitioner?.actor?.reference?.split('/')[1]
      );

      let practitionerAverageWaitingTime = null;
      let practitionerAverageSessionTime = null;

      let practitionerRoleAverageWaitingTime = null;
      let practitionerRoleAverageSessionTime = null;

      if (practitionerRecord) {
        const practitionerWaitTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-waiting-time'
        );

        // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
        if (practitionerWaitTimeExtension) {
          practitionerAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
        }
        const practitionerAverageSessionTimeExtension = practitionerRecord?.extension?.find(
          (ext) => ext.url === 'average-session-time'
        );

        if (practitionerAverageSessionTimeExtension) {
          practitionerAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
        }

        const practitionerRoleRecord = allPractitionerRoles.find((pr: any) => {
          const prFound = pr?.practitioner?.reference === practitioner?.actor?.reference;
          const locationFound = pr?.location?.find(
            (loc: any) =>
              loc.reference === location?.actor || loc.reference === `Location/${location?.actor?.reference}`
          );
          return prFound && locationFound;
        });

        if (practitionerRoleRecord) {
          const practitionerWaitTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-waiting-time'
          );

          // console.log('practitionerWaitTimeExtension', practitionerWaitTimeExtension);
          if (practitionerWaitTimeExtension) {
            practitionerRoleAverageWaitingTime = practitionerWaitTimeExtension.valueDuration?.value;
          }
          const practitionerAverageSessionTimeExtension = practitionerRoleRecord?.extension?.find(
            (ext) => ext.url === 'average-session-time'
          );

          if (practitionerAverageSessionTimeExtension) {
            practitionerRoleAverageSessionTime = practitionerAverageSessionTimeExtension.valueDuration?.value;
          }

          // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
        }

        // console.log('practitionerAverageSessionTimeExtension', practitionerAverageSessionTimeExtension);
      }

      if (practitioner) {
        let bgColor, textColor;

        const bgColorExtension = practitionerRecord?.extension?.find(
          (ext: { url: string }) => ext.url === 'http://hl7.org/fhir/StructureDefinition/practitioner-background-color'
        );

        const textColorExtension = practitionerRecord?.extension?.find(
          (ext: { url: string }) => ext.url === 'http://hl7.org/fhir/StructureDefinition/practitioner-text-color'
        );

        if (bgColorExtension) {
          bgColor = bgColorExtension.valueString || '#422DAD';
        }

        if (textColorExtension) {
          textColor = textColorExtension.valueString || 'rgb(99,99,100)';
        }

        practitioner = practitioner
          ? {
              ...practitioner,
              ...practitioner.actor,
              actor: { ...practitioner.actor, reference: practitioner.actor?.reference?.replace('Practitioner/', '') },
              practitionerAverageWaitingTime,
              practitionerAverageSessionTime,
              practitionerRoleAverageWaitingTime,
              practitionerRoleAverageSessionTime,
              photo: practitionerRecord?.photo?.[0]?.url || undefined,
              color: textColor,
              backgroundColor: bgColor,
            }
          : undefined;
      }

      const userTimezone = getTimezoneFromExtension(entry.resource);

      if (!allDoctorsIds.includes(practitioner?.actor?.reference)) {
        allDoctorsIds.push(practitioner?.actor?.reference);
        allDoctors.push({
          ...practitioner?.actor,
          photo: practitioner.photo,
          color: practitioner.color,
          backgroundColor: practitioner.backgroundColor,
        });
      }

      const loadTimelineResources = (
        medplum: MedplumClient,
        resourceType: ResourceType,
        id: string
      ): Promise<PromiseSettledResult<Bundle>[]> => {
        const ref = `${resourceType}/${id}`;
        const _count = 10000;
        return Promise.allSettled([
          // medplum.readHistory('Patient', id),
          medplum.search('Communication', { subject: ref, _count }),
          // medplum.search('Device', { patient: ref, _count }),
          // medplum.search('DeviceRequest', { patient: ref, _count }),
          // medplum.search('DiagnosticReport', { subject: ref, _count }),
          medplum.search('Media', { subject: ref, _count }),
          // medplum.search('ServiceRequest', { subject: ref, _count }),
          // medplum.search('Task', { subject: ref, _count }),
        ]);
      };

      // const resourceType = 'Patient';
      // const reference = { reference: resourceType + '/' + patient.reference.split('/')[1] };

      // const resource = useResource(reference);

      const handleBatchResponse = (batchResponse: PromiseSettledResult<Bundle>[]): any[] => {
        const newItems = [];

        for (const settledResult of batchResponse) {
          if (settledResult.status !== 'fulfilled') {
            // User may not have access to all resource types
            continue;
          }

          const bundle = settledResult.value;
          // console.log('bundle', bundle);
          // if (bundle.type === 'history') {
          //   setHistory(bundle);
          // }

          if (bundle.entry) {
            for (const entry of bundle.entry) {
              newItems.push(entry.resource as Resource);
            }
          }
        }

        return newItems;
      };

      const loadTimeline = async (): Promise<any[]> => {
        const resourceType: ResourceType = 'Patient';
        const id: string = patient.reference.split('/')[1];

        const res = await loadTimelineResources(medplum, resourceType, id)
          .then(handleBatchResponse)
          .catch(console.error);

        return res || [];
      };

      const notes = await loadTimeline();

      entry.resource = {
        ...entry.resource,
        patient,
        location,
        practitioner,
        userTimezone,
        notes,
      };

      // return { ...entry, patient, location };
    }

    setDoctorsList(allDoctors);
    setSelectedDoctors(allDoctorsIds);

    const appointments =
      response?.entry?.map((entry) => {
        return {
          // id: entry.resource?.id,
          title: `${entry.resource?.patient?.display}'s surgery`,
          start: new Date(dayjs(entry.resource?.start).toDate()),
          end: new Date(dayjs(entry.resource?.end).add(1, 'hour').toDate()),
          resource: entry.resource,
        };
      }) || [];

    setAllAppointments(appointments);
    setFilteredAppointments(appointments);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAppointments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, fetchAppointments, view]);

  useEffect(() => {
    const filteredAppointments = allAppointments.filter((appointment) => {
      const practitionerId = appointment.resource?.practitioner?.actor?.reference;

      return selectedDoctors.includes(practitionerId);
    });

    setFilteredAppointments(filteredAppointments);
  }, [allAppointments, selectedDoctors]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const toggleDoctor = (id: string, checked: boolean) => {
    // const updatedSelectedDoctors = selectedDoctors.includes(id) ? selectedDoctors.filter((d) => d !== id) : [...selectedDoctors, id];

    if (checked) {
      setSelectedDoctors((prev) => [...prev, id]);
    } else {
      setSelectedDoctors((prev) => prev.filter((d) => d !== id));
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Typography variant="h3" marginTop={1} color={'primary.dark'}>
            Add Patient
          </Typography>
        }
        size="lg"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <AddPatient close={close} surgeonDisabled={true} selectedSurgeon={selectedSurgeon} />
      </Modal>
      {/* <Document style={{ backgroundColor: 'red' }}> */}
      <Grid gutter="xs" style={{ padding: '0px' }}>
        <Grid.Col span={2.5} style={{ padding: '0px' }}>
          <Sidebar
            doctors={doctorsList}
            selectedDoctors={selectedDoctors}
            onToggle={toggleDoctor}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            showCalendar={false}
          />
        </Grid.Col>
        <Grid.Col span={9.5} style={{ padding: '0px' }}>
          <Grid>
            <Grid.Col span={12} pt={14}>
              <Toolbar view={view} setView={setView} open={open} />
            </Grid.Col>
            <Grid.Col span={9}>
              <CalendarView
                // onSelectEvent={setSelectedSurgery}
                setAllAppointments={setAllAppointments}
                slots={filteredAppointments}
                appointments={filteredAppointments}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                view={view}
                setView={setView}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                open={open}
                setFetchAppointments={setFetchAppointments}
              />
            </Grid.Col>
            <Grid.Col span={3} pt={28}>
              <SurgeryDetails id={id} resourceType={resourceType} />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      {/* <CreateUpdateSlot event={selectedEvent} opened={createSlotOpened} handlers={createSlotHandlers} />
      
      {/* </Document> */}
    </>
  );
}
