import { ActionIcon, Drawer, em, Indicator, Menu, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { createReference, getReferenceString, MedplumClient, ProfileResource } from '@medplum/core';
import { Attachment, Communication, Resource, ResourceType } from '@medplum/fhirtypes';
import {
  // DefaultResourceTimeline,
  // DefaultResourceTimeline,
  // EncounterTimeline,
  // PatientTimeline,
  ResourceTimelineMenuItemContext,
  // ServiceRequestTimeline,
  useMedplum,
  useMedplumNavigate,
  useMedplumProfile,
} from '@medplum/react';
import {
  IconBell,
  IconEdit,
  IconListDetails,
  IconPin,
  IconPinnedOff,
  // IconRepeat,
  // IconTextRecognition,
  IconTrash,
} from '@tabler/icons-react';
import { ReactNode, useCallback, useState } from 'react';
import { ResourceTimeline } from './ResourceTimeline/ResourceTimeline';
// import { isAwsTextractEnabled } from '../config';

export function LoggedInUserTimeline(): JSX.Element | null {
  const medplum = useMedplum();
  const navigate = useMedplumNavigate();
  const [opened, { close, open }] = useDisclosure(false);
  const isMobile = useMediaQuery(`(max-width: ${em(1024)})`);

  const profile = useMedplumProfile();
  const userLogged = medplum.getActiveLogin();
  const [_items, _setItems] = useState<Resource[]>([]);

  const reference = profile ? { reference: profile.resourceType + '/' + profile.id } : undefined;

  const [notificationCount, _setNotificationCount] = useState(0);

  // const [resendSubscriptionsResource, setResendSubscriptionsResource] = useState<Resource | undefined>();
  // const resendSubscriptiosnDisclosure = useDisclosure(false);

  function setPriority(
    communication: Communication,
    priority: 'routine' | 'urgent' | 'asap' | 'stat'
  ): Promise<Communication> {
    return medplum.updateResource({ ...communication, priority });
  }

  function onPin(communication: Communication, reloadTimeline: () => void): void {
    setPriority(communication, 'stat').then(reloadTimeline).catch(console.error);
  }

  function onUnpin(communication: Communication, reloadTimeline: () => void): void {
    setPriority(communication, 'routine').then(reloadTimeline).catch(console.error);
  }

  function onDetails(timelineItem: Resource): void {
    navigate(`/${profile?.resourceType}/${timelineItem.id}`);
  }

  function onEdit(timelineItem: Resource): void {
    navigate(`/${profile?.resourceType}/${timelineItem.id}/edit`);
  }

  function onDelete(timelineItem: Resource): void {
    navigate(`/${profile?.resourceType}/${timelineItem.id}/delete`);
  }

  function onVersionDetails(version: Resource): void {
    navigate(`/${profile?.resourceType}/${version.id}/_history/${version.meta?.versionId}`);
  }

  function getMenu(context: ResourceTimelineMenuItemContext): ReactNode {
    const { primaryResource, currentResource, reloadTimeline } = context;

    const isHistoryResource =
      currentResource.resourceType === primaryResource.resourceType && currentResource.id === primaryResource.id;

    const canPin = currentResource.resourceType === 'Communication' && currentResource.priority !== 'stat';
    const canUnpin = currentResource.resourceType === 'Communication' && currentResource.priority === 'stat';

    const showVersionDetails = isHistoryResource;
    const showDetails = !isHistoryResource;

    const canEdit = !isHistoryResource;
    const canDelete = !isHistoryResource;

    const isProjectAdmin = medplum.getProjectMembership()?.admin;
    // const canResend = isProjectAdmin;

    // const showAwsAi =
    //   isAwsTextractEnabled() &&
    //   (currentResource.resourceType === 'DocumentReference' || currentResource.resourceType === 'Media');

    return (
      <Menu.Dropdown>
        <Menu.Label>Resource</Menu.Label>
        {canPin && (
          <Menu.Item
            leftSection={<IconPin size={14} />}
            onClick={() => onPin(currentResource, reloadTimeline)}
            aria-label={`Pin ${getReferenceString(currentResource)}`}
          >
            Pin
          </Menu.Item>
        )}
        {canUnpin && (
          <Menu.Item
            leftSection={<IconPinnedOff size={14} />}
            onClick={() => onUnpin(currentResource, reloadTimeline)}
            aria-label={`Unpin ${getReferenceString(currentResource)}`}
          >
            Unpin
          </Menu.Item>
        )}
        {showDetails && (
          <Menu.Item
            leftSection={<IconListDetails size={14} />}
            onClick={() => onDetails(currentResource)}
            aria-label={`Details ${getReferenceString(currentResource)}`}
          >
            Details
          </Menu.Item>
        )}
        {showVersionDetails && (
          <Menu.Item
            leftSection={<IconListDetails size={14} />}
            onClick={() => onVersionDetails(currentResource)}
            aria-label={`Details ${getReferenceString(currentResource)}`}
          >
            Details
          </Menu.Item>
        )}
        {canEdit && (
          <Menu.Item
            leftSection={<IconEdit size={14} />}
            onClick={() => onEdit(currentResource)}
            aria-label={`Edit ${getReferenceString(currentResource)}`}
          >
            Edit
          </Menu.Item>
        )}
        {isProjectAdmin && (
          <>
            <Menu.Divider />
            <Menu.Label>Admin</Menu.Label>
            {/* {canResend && (
              <Menu.Item
                leftSection={<IconRepeat size={14} />}
                onClick={() => onResend(currentResource)}
                aria-label={`Resend Subscriptions ${getReferenceString(currentResource)}`}
              >
                Resend Subscriptions
              </Menu.Item>
            )} */}
          </>
        )}
        {/* {showAwsAi && (
          <>
            <Menu.Divider />
            <Menu.Label>AWS AI</Menu.Label>
            <Menu.Item
              leftSection={<IconTextRecognition size={14} />}
              onClick={() => onAwsTextract(currentResource, reloadTimeline)}
              aria-label={`AWS Textract ${getReferenceString(currentResource)}`}
            >
              AWS Textract
            </Menu.Item>
          </>
        )} */}
        {canDelete && (
          <>
            <Menu.Divider />
            <Menu.Label>Danger zone</Menu.Label>
            <Menu.Item
              color="red"
              leftSection={<IconTrash size={14} />}
              onClick={() => onDelete(currentResource)}
              aria-label={`Delete ${getReferenceString(currentResource)}`}
            >
              Delete
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    );
  }

  const loadTimelineResources = useCallback((medplum: MedplumClient, resourceType: ResourceType, id: string) => {
    const ref = `${resourceType}/${id}`;
    const _count = 100;
    return Promise.allSettled([
      // medplum.readHistory('Patient', id),
      medplum.search('Communication', { subject: ref, _count }),
      medplum.search('Communication', { sender: ref, _count }),
      // medplum.search('Device', { patient: ref, _count }),
      // medplum.search('DeviceRequest', { patient: ref, _count }),
      // medplum.search('DiagnosticReport', { subject: ref, _count }),
      medplum.search('Media', { subject: ref, _count }),
      medplum.search('Media', { sender: ref, _count }),
      // medplum.search('ServiceRequest', { subject: ref, _count }),
      // medplum.search('Task', { subject: ref, _count }),
    ]);
  }, []);

  const NotificationBadge = ({ count }: { count: number }): JSX.Element => {
    return (
      <Indicator
        label={count}
        size={16}
        offset={8} // Positioning the badge
        color="transparent"
        withBorder
        // disabled={count === 0} // Hide the badge if count is 0
      >
        <ActionIcon size="xl" variant="transparent" onClick={open}>
          <IconBell size={32} />
        </ActionIcon>
      </Indicator>
    );
  };

  const FloatingButton = (): JSX.Element => {
    return (
      <Indicator
        label={notificationCount}
        size={16}
        offset={8} // Positioning the badge
        color="transparent"
        withBorder
        pos="fixed"
        style={{
          // position: 'fixed',
          bottom: '20px', // Distance from the bottom
          right: '20px', // Distance from the right
          zIndex: 1000, // Ensure it stays above other elements
        }}
        // disabled={count === 0} // Hide the badge if count is 0
      >
        <ActionIcon size="xl" variant="transparent" onClick={open}>
          <IconBell size={32} />
        </ActionIcon>
      </Indicator>
    );
  };

  return (
    <>
      {opened && (
        <Drawer
          opened={opened}
          onClose={close}
          title={<Title pl={15}>{`Notes for ${userLogged?.profile.display}`}</Title>}
          position="right"
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          size="lg"
          transitionProps={{ transition: 'rotate-right', duration: 150, timingFunction: 'linear' }}
        >
          <ResourceTimeline
            // setTableNotesItems={setItems}
            value={reference}
            loadTimelineResources={loadTimelineResources}
            createCommunication={(resource: Resource, sender: ProfileResource, text: string) => ({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(resource),
              sender: createReference(sender),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            })}
            createMedia={(resource: Resource, operator: ProfileResource, content: Attachment) => ({
              resourceType: 'Media',
              status: 'completed',
              subject: createReference(resource),
              operator: createReference(operator),
              issued: new Date().toISOString(),
              content,
            })}
            getMenu={getMenu}
            // {...rest}
          />
        </Drawer>
      )}
      {!isMobile && <NotificationBadge count={notificationCount} />}
      <FloatingButton />
    </>
  );
}
