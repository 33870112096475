import { Button, Group } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useUser } from '../../user.context';

interface ToolbarProps {
  view: 'day' | 'week' | 'month';
  setView: (view: 'day' | 'week' | 'month') => void;
  open: () => void;
}

const Toolbar = ({ view, setView, open }: ToolbarProps): JSX.Element => {
  const { pAddSurgery } = useUser();

  const options = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
  ];

  return (
    <div
      style={{
        backgroundColor: '#C9DCEA',
        padding: '20px 20px',
        // borderRadius: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {/* Left Section: Date Selector */}
      {/* <Group gap="xs"> */}
      {/* <DatePickerInput
          defaultValue={new Date()}
          placeholder=""
          variant="unstyled"
          rightSection={<IconChevronDown />}
          fw={600}
          size="lg"
        /> */}
      {/* <Text fw={600} size="md">
          4 February 2023
        </Text>
        <Menu>
          <Menu.Target>
            <Button variant="subtle" size="xs">
              <IconChevronDown size={16} />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item>3 February 2023</Menu.Item>
            <Menu.Item>4 February 2023</Menu.Item>
            <Menu.Item>5 February 2023</Menu.Item>
          </Menu.Dropdown>
        </Menu> */}
      {/* </Group> */}

      {/* Center Section: Day, Week, Month, Year Buttons */}
      <Group gap="xs">
        {options.map((option) => (
          <Button
            key={option.value}
            variant={view === option.value ? 'filled' : 'outline'}
            size="sm"
            color={view === option.value ? '#006DB1' : '#5C5F62'}
            style={{ border: 'none' }}
            bg={view === option.value ? '#006DB1' : 'white'}
            onClick={() => setView(option.value as 'day' | 'week' | 'month')}
            // py={10}
            h={28}
            px={10}
          >
            {option.label}
          </Button>
        ))}
      </Group>

      {/* Right Section: Today and Add Surgery Buttons */}
      <Group gap="xs">
        {/* <Button
          variant="outline"
          size="sm"
          color="#5C5F62"
          style={{ border: 'none' }}
          bg={'white'}
          leftSection={<IconChevronLeft size={16} />}
          rightSection={<IconChevronRight size={16} />}
          px={2}
          h={34}
        >
          Today
        </Button> */}
        {pAddSurgery && (
          <Button
            px={4}
            h={34}
            w={132}
            variant="filled"
            size="sm"
            color="#006DB1"
            leftSection={<IconPlus size={16} />}
            onClick={open}
          >
            Add Surgery
          </Button>
        )}

        {/* <Group ps="apart" mb="md" justify="space-between">
          <ActionIcon variant="filled" size="sm" color="blue" radius="lg" onClick={() => alert('Add new Surgery')}>
            <IconPlus size={16} />
          </ActionIcon>
          <Text size="lg" fw={600}>
            Add Surgery
          </Text>
        </Group> */}
      </Group>
    </div>
  );
};

export default Toolbar;
