import { Modal, Paper, Tabs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { normalizeErrorString, Operator, parseSearchRequest, SearchRequest } from '@medplum/core';
import { Patient, Practitioner, ResourceType } from '@medplum/fhirtypes';
import { Loading, useMedplum, useMedplumProfile } from '@medplum/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from '../../HomePage.module.css';
import {
  addSearchValues,
  // getTransactionBundle,
  // RESOURCE_TYPE_CREATION_PATHS,
  saveLastSearch,
} from '../../HomePage.utils';
import { SearchControl } from './SearchControl/SearchControl';
import AppointmentsPage from './AppointmentsPage';
import { useDisclosure } from '@mantine/hooks';
import PatientDetails from '../schedule/PatientDetails';
import { useUser } from '../../user.context';
// import { exportJsonFile } from '../../utils';
// import { IconPlus } from '@tabler/icons-react';
const invitedByExtName = 'http://medplum.com/fhir/StructureDefinition/calendarInvitedByList';

const HomePage: React.FC = () => {
  const medplum = useMedplum();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = useMedplumProfile() as Practitioner;
  const [search, setSearch] = useState<SearchRequest>();
  const [activeTab, setActiveTab] = useState<string | null>('patient');
  const [allPatients, setAllPatients] = useState<Patient[]>([]);
  const [allPatientsIds, setAllPatientsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [patientDetailsOpened, patientDetailsHandler] = useDisclosure(false);
  const [patient, setPatient] = useState<Patient>();

  const { pUpdatePatient, userProjectMembership } = useUser();

  const getUtilsData = async (): Promise<void> => {
    setLoading(true);
    // const memberships = await medplum.search('ProjectMembership', { profile: `Practitioner/${profile?.id}` });
    // if (memberships.entry && memberships.entry.length > 0) {
    //   const userProjectMembership = memberships.entry[0].resource as ProjectMembership;
    //   setUserProjectMemberShip(userProjectMembership);

    const userExtensions = profile?.extension || [];

    const patients = [];
    const ids: string[] = [];

    const invitedByExt = userExtensions.find((ext) => ext.url === invitedByExtName);
    if (invitedByExt?.extension && invitedByExt.extension.length > 0) {
      const invitedByList: any[] = invitedByExt.extension?.map((ext) => ext.valueReference) || [];
      invitedByList.push(`Practitioner/${profile?.id}`);
      if (invitedByList.length > 0) {
        for (const invitedBy of invitedByList) {
          const encounters = await medplum.search('Encounter', { practitioner: invitedBy as string });

          if (encounters.entry && encounters.entry.length > 0) {
            for (const encounter of encounters.entry) {
              const patient = encounter.resource.subject?.reference;
              if (patient) {
                const patientId = patient.split('/')[1];
                const patientData = await medplum.readResource('Patient', patientId);
                patients.push(patientData);
                if (!ids.includes(patient)) {
                  ids.push(patient);
                }
              }
            }
          }
        }
      }
    }

    setAllPatientsIds(ids);

    const uniquePatients = patients.filter(
      (patient, index, self) => index === self.findIndex((t) => t.id === patient.id)
    );

    setAllPatients(uniquePatients);
    // }
    setLoading(false);

    //  else {
    //   showNotification({ color: 'red', message: 'No project memberships found', autoClose: false });
    // }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getUtilsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (loading) {
      return;
    }
    // Parse the search from the URL
    const parsedSearch = parseSearchRequest('/Patient' + location.search);
    // const parsedSearch = parseSearchRequest(location.pathname + location.search);

    // Fill in the search with default values
    const populatedSearch = addSearchValues(parsedSearch, medplum.getUserConfiguration());
    populatedSearch.resourceType = 'Patient' as ResourceType;
    // console.log('resourceType', populatedSearch.resourceType);
    // populatedSearch.resourceType = 'Patient' as ResourceType;
    // const updatedResourceType =
    //   populatedSearch.resourceType === 'Patient' ? 'Patient' : populatedSearch.resourceType;
    // console.log('updatedResourceType', updatedResourceType);
    // if (location.pathname === `/` && location.search === formatSearchQuery(populatedSearch)) {
    // If the URL matches the parsed search, then save it and execute it
    populatedSearch.fields = ['name', 'dob', 'gender', 'address', 'phone', 'email'];

    const filters = [];

    // const role = (userProjectMembership?.authRole || profile.resourceType) ?? '';
    const role = userProjectMembership?.authRole ?? '';

    if (['guest', 'staff'].includes(role)) {
      if (allPatientsIds.length > 0) {
        filters.push([
          {
            code: '_id',
            operator: Operator.IN,
            value: allPatientsIds.join(',') as string,
          },
        ]);
      }
      populatedSearch.filters =
        populatedSearch?.filters && populatedSearch.filters.length > 0
          ? [...populatedSearch.filters, ...filters.flat()]
          : filters.flat();

      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);
    } else {
      saveLastSearch(populatedSearch);
      setSearch(populatedSearch);
      // }
      // else {
      //   // Otherwise, navigate to the desired URL
      //   navigate(`/${'Patient'}${formatSearchQuery(populatedSearch)}`);
      //   // navigate(`/${populatedSearch.resourceType}${formatSearchQuery(populatedSearch)}`);
      // }
    }
  }, [medplum, navigate, location, profile, allPatients, loading, allPatientsIds, userProjectMembership?.authRole]);

  if (!search?.resourceType || !search.fields || search.fields.length === 0 || loading) {
    return <Loading />;
  }

  search.resourceType = 'Patient' as ResourceType;

  const ToggleView: React.FC = () => {
    return (
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="patient">Patients List</Tabs.Tab>
          {pUpdatePatient && <Tabs.Tab value="queue">Queue System</Tabs.Tab>}
        </Tabs.List>
      </Tabs>
    );
  };

  const showToggle = !['guest'].includes(userProjectMembership?.authRole ?? '');

  if (activeTab === 'patient') {
    return (
      <>
        {patientDetailsOpened && patient && (
          <Modal
            opened={!!patient}
            onClose={patientDetailsHandler.close}
            size="auto"
            centered
            overlayProps={{
              backgroundOpacity: 0.55,
              blur: 3,
            }}
            inset={3}
            zIndex={111}
            className="appointment-detail-modal"
          >
            <PatientDetails patient={patient} notesModelOpen={open} />
          </Modal>
        )}
        <Paper shadow="xs" m="md" p="xs" className={classes.paper}>
          <SearchControl
            // hideFilters={true}
            checkboxesEnabled={false}
            search={search}
            onClick={(e) => {
              setPatient(e.resource);
              patientDetailsHandler.open();
            }}
            // onClick={(e) => navigate(`/${'Patient'}/${e.resource.id}`)}
            // onAuxClick={(e) => window.open(`/${'Patient'}/${e.resource.id}`, '_blank')}
            // onChange={(e) => {
            //   navigate(`/${'Patient'}${formatSearchQuery(e.definition)}`);
            // }}
            onNew={() => {
              navigate(`/${'Patient'}/new`);
            }}
            // onExportCsv={() => {
            //   const url = medplum.fhirUrl('Patient', '$csv') + formatSearchQuery(search);
            //   medplum
            //     .download(url)
            //     .then((blob: Blob) => {
            //       window.open(window.URL.createObjectURL(blob), '_blank');
            //     })
            //     .catch((err) => showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false }));
            // }}
            // onExportTransactionBundle={async () => {
            //   getTransactionBundle(search, medplum)
            //     .then((bundle) => exportJsonFile(JSON.stringify(bundle, undefined, 2)))
            //     .catch((err) => showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false }));
            // }}
            onDelete={(ids: string[]) => {
              if (window.confirm('Are you sure you want to delete these resources?')) {
                medplum.invalidateSearches('Patient' as ResourceType);
                medplum
                  .executeBatch({
                    resourceType: 'Bundle',
                    type: 'batch',
                    entry: ids.map((id) => ({
                      request: {
                        method: 'DELETE',
                        url: `${'Patient'}/${id}`,
                      },
                    })),
                  })
                  .then(() => setSearch({ ...search }))
                  .catch((err) =>
                    showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false })
                  );
              }
            }}
            // onBulk={(ids: string[]) => {
            //   navigate(`/bulk/${'Patient'}?ids=${ids.join(',')}`);
            // }}
            Toggle={showToggle ? ToggleView : undefined}
          />
        </Paper>
      </>
    );
  }

  return <AppointmentsPage Toggle={showToggle ? ToggleView : undefined} />;

  // }
};

export default HomePage;
