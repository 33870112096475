import { CustomContainerFactory } from './CustomContainerFactory';
import { myAppLogo } from '../assets/index';
// import Footer from './Footer';

const imageForBackground = (page: string): string => {
  switch (page) {
    default:
      return myAppLogo;
  }
};
// const CustomContainer = CustomContainerFactory(imageForBackground, ottehrLogo, 'Ottehr Telemedicine', <Footer />);
const CustomContainer = CustomContainerFactory(imageForBackground, myAppLogo, 'YNDR');
export default CustomContainer;
