import { Event } from 'react-big-calendar';
import { Modal, Text, Group, Button, Select, Drawer, Title } from '@mantine/core';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Appointment, Bundle, Resource, ResourceType } from '@medplum/fhirtypes';
import { useDisclosure } from '@mantine/hooks';
import { sortByDateAndPriority, useMedplum } from '@medplum/react';
import { MedplumClient } from '@medplum/core';
import { TimelinePage } from '../../components/dialogs/PatientTimeline';
import { showNotification } from '@mantine/notifications';
import { Appointment as ScheduleAppointment } from './index';
import { useUser } from '../../user.context';

interface AppointmentModalProps {
  appointment: Event | null; // Appointment;
  onClose: () => void;
  onUpdate: (appointment: Appointment) => void;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
  setAllAppointments: Dispatch<SetStateAction<ScheduleAppointment[]>>;
}

const InsuranceStatus = ({
  resource,
  // setFetchAppointments,
  patient,
  setAllAppointments,
}: {
  resource: Resource;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
  patient: any;
  setAllAppointments: Dispatch<SetStateAction<ScheduleAppointment[]>>;
}): JSX.Element => {
  const [insuranceStatus, setInsuranceStatus] = useState(resource?.insuranceStatus || 'Unverified');
  const medplum = useMedplum();

  const { setUtilsData } = useUser();

  const handleUpdate = async (): Promise<void> => {
    const otherExtensions =
      resource?.extension?.filter(
        (ext: { url: string }) => ext.url !== 'http://medplum.com/fhir/StructureDefinition/insurance-status'
      )[0]?.valueString || [];

    const updatedExtension = {
      url: 'http://medplum.com/fhir/StructureDefinition/insurance-status',
      valueString: insuranceStatus,
    };

    const updatedAppointment = {
      ...resource,
      extension: [...otherExtensions, updatedExtension],
    };

    delete updatedAppointment?.insuranceStatus;

    const updatedPatient = await medplum.updateResource(updatedAppointment);

    const updatedPatientDetails = { ...updatedPatient, insuranceStatus };

    const updatedItem = { ...patient, patientDetails: updatedPatientDetails };

    setUtilsData((prev) => {
      return {
        ...prev,
        allPatients: prev?.allPatients?.map((p) => (p?.id === updatedPatient?.id ? updatedPatientDetails : p)),
      };
    });

    setAllAppointments((prev) => {
      return prev?.map((app) =>
        app.resource.patient.reference === patient.reference
          ? {
              ...app,
              resource: {
                ...app.resource,
                patient: updatedItem,
              },
            }
          : app
      );
    });

    // setInsuranceStatus(insuranceStatus);
    showNotification({ color: 'green', message: 'Insurance status changed successfully!' });
    // setFetchAppointments((prev) => !prev);
  };

  return (
    <>
      <Select
        label="Insurance Status"
        data={['Verified', 'Unverified']}
        value={insuranceStatus}
        onChange={(e) => setInsuranceStatus(e || '')}
      />
      <Group align="right" mt="md">
        <Button onClick={handleUpdate}>Save</Button>
      </Group>
    </>
  );
};

const AppointmentModal = ({
  appointment,
  onClose,
  setFetchAppointments,
  setAllAppointments,
}: AppointmentModalProps): JSX.Element => {
  const { location, patient, notes = [] } = appointment?.resource || {};
  const { patientDetails } = patient;
  const patientId = patient.actor.reference;
  const resourceType = 'Patient';
  const reference = { reference: resourceType + '/' + patientId };

  const [opened, { open, close }] = useDisclosure(false);
  const [items, setItems] = useState<Resource[]>(notes);

  const medplum = useMedplum();
  // const resource = useResource(reference);

  const loadTimelineResources = useCallback((medplum: MedplumClient, resourceType: ResourceType, id: string) => {
    const ref = `${resourceType}/${id}`;
    const _count = 1000;
    return Promise.allSettled([
      // medplum.readHistory('Patient', id),
      medplum.search('Communication', { subject: ref, _count }),
      // medplum.search('Device', { patient: ref, _count }),
      // medplum.search('DeviceRequest', { patient: ref, _count }),
      // medplum.search('DiagnosticReport', { subject: ref, _count }),
      medplum.search('Media', { subject: ref, _count }),
      // medplum.search('ServiceRequest', { subject: ref, _count }),
      // medplum.search('Task', { subject: ref, _count }),
    ]);
  }, []);

  const sortAndSetItems = useCallback(
    (newItems: Resource[]): void => {
      sortByDateAndPriority(newItems, patientDetails);
      newItems.reverse();
      // setItems(newItems);
    },
    [patientDetails]
  );

  const handleBatchResponse = useCallback(
    (batchResponse: PromiseSettledResult<Bundle>[]): void => {
      const newItems = [];

      for (const settledResult of batchResponse) {
        if (settledResult.status !== 'fulfilled') {
          // User may not have access to all resource types
          continue;
        }

        const bundle = settledResult.value;
        // console.log('bundle', bundle);
        // if (bundle.type === 'history') {
        //   setHistory(bundle);
        // }

        if (bundle.entry) {
          for (const entry of bundle.entry) {
            newItems.push(entry.resource as Resource);
          }
        }
      }

      sortAndSetItems(newItems);
    },
    [sortAndSetItems]
  );

  const loadTimeline = useCallback(() => {
    let resourceType: ResourceType;
    let id: string;
    if ('resourceType' in reference) {
      resourceType = reference.resourceType;
      id = reference.reference?.split('/')[1] as string;
    } else {
      [resourceType, id] = reference.reference?.split('/') as [ResourceType, string];
    }
    loadTimelineResources(medplum, resourceType, id).then(handleBatchResponse).catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => loadTimeline(), [loadTimeline]);

  return (
    <>
      {opened && (
        <Drawer
          opened={opened}
          onClose={close}
          title={<Title pl={15}>{`Notes for ${patient?.display}`}</Title>}
          position="right"
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          size="lg"
          transitionProps={{ transition: 'rotate-right', duration: 150, timingFunction: 'linear' }}
        >
          <TimelinePage id={patient.actor.reference} setItems={setItems} />
        </Drawer>
      )}
      <Modal
        opened={!!appointment}
        onClose={onClose}
        size="auto"
        centered
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        title={
          <Text variant="h3" mt={1} fw="bold" color={'primary.dark'}>
            APPOINTMENT DETAILS
          </Text>
        }
      >
        <Button onClick={open} variant="transparent" mb={20}>
          {items.length > 0 ? `View ${items.length} Note${items.length > 1 ? 's' : ''}` : `No notes available`}
        </Button>
        {patient?.display && (
          <Text mb={10}>
            <b>Patient Name:</b> {patient?.display}
          </Text>
        )}
        {/* {appointment?.patientDOB && (
          <Text mb={10}>
            <b>DOB:</b> {appointment?.patientDOB}
          </Text>
        )} */}
        <Text mb={10}>{/* <b>Surgery Type:</b> {appointment?.surgeryType} */}</Text>
        {location?.display && (
          <Text mb={10}>
            <b>Location:</b> {location?.display}
          </Text>
        )}

        <InsuranceStatus
          resource={patientDetails}
          patient={patient}
          setFetchAppointments={setFetchAppointments}
          setAllAppointments={setAllAppointments}
        />
      </Modal>
    </>
  );
};

export default AppointmentModal;
