import { LoadingButton } from '@mui/lab';
import {
  // Autocomplete,
  Box,
  Button,
  // Chip,
  CircularProgress,
  Dialog,
  // FormControl,
  FormControlLabel,
  Grid,
  // InputLabel,
  // MenuItem,
  Paper,
  Radio,
  RadioGroup,
  // Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Checkbox, Group, List, Select, Stack, Text, TextInput, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  createReference,
  formatHumanName,
  // createReference,
  // formatSearchQuery,
  InviteRequest,
  // isOperationOutcome,
  normalizeErrorString,
  normalizeOperationOutcome,
  // Operator,
  // ProfileResource,
  // SearchRequest,
  // Operator,
  // Operator,
  // createReference,
  // formatHumanName,
  // ProfileResource,
} from '@medplum/core';
import {
  AccessPolicy,
  OperationOutcome,
  // Project,
  ProjectMembership,
  Reference,
  // Appointment,
  User,
  Practitioner as MedplumPractitioner,
  HumanName,
  // Practitioner,
  // Location as MedplumLocation,
  // HumanName,
} from '@medplum/fhirtypes';
import { Form, MedplumLink, getErrorsForInput, useMedplum, useMedplumProfile } from '@medplum/react';
// import { AccessPolicyInput } from '../../admin/AccessPolicyInput';
// import { ZambdaClient } from '@zapehr/sdk';
import { Resource } from 'fhir/r4';
// import { DateTime } from 'luxon';
import { useEffect, useState, useCallback } from 'react';
import PractitionerSelect from '../../queue-management/components/PractitionerSelect';
// import { LensBlurTwoTone } from '@mui/icons-material';
// import { PatternFormat } from 'react-number-format';
// import { useNavigate } from 'react-router-dom';
// import { GetLocationParameters, GetLocationResponse, createAppointment, getLocations } from '../api/api';
// import CustomBreadcrumbs from '../../queue-management/components/CustomBreadcrumbs';

// import DateSearch from '../../queue-management/components/DateSearch';
// import LocationSelect from '../../queue-management/components/LocationSelect';
// import { MAXIMUM_CHARACTER_LIMIT } from '../../queue-management/constants';
// import { useApiClients } from '../hooks/useAppClients';
// import PageContainer from '../../queue-management/layout/PageContainer';
// import {
//   CreateAppointmentParameters,
//   PersonSex,
//   PRIVATE_EXTENSION_BASE_URL,
//   VisitType,
// } from '../../queue-management/types/types';
// import { PRIVATE_EXTENSION_BASE_URL } from 'ehr-utils';
// import SlotPicker from '../../queue-management/components/SlotPicker';

// import PractitionerSelect from '../../queue-management/components/PractitionerSelect';
// import { GetLocationParameters } from '../../types';
// import bcrypt from 'bcryptjs';
// import { CustomDialog } from '../../queue-management/components/dialogs/CustomDialog';

// type SlotLoadingState =
//   | { status: 'initial'; input: undefined }
//   | { status: 'loading'; input: undefined }
//   | { status: 'loaded'; input: string };

// interface GetLocationParameters {
//   slug?: string;
//   scheduleType?: 'location' | 'provider' | 'group';
//   locationState?: string;
//   fetchAll?: boolean;
// }

// type Days = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

const emailRegex = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const PRACTITIONER_PERMISSIONS = {
  pInviteUser: true,
  pAddNote: true,
  pUpdateNote: true,
  pDeleteNote: false,
  pAddSurgery: false,
  pUpdateSurgery: false,
  pDeleteSurgery: false,
  pViewPatient: true,
  pUpdatePatient: true,
};

const GUEST_PERMISSIONS = {
  pInviteUser: false,
  pAddNote: false,
  pUpdateNote: false,
  pDeleteNote: false,
  pAddSurgery: false,
  pUpdateSurgery: false,
  pDeleteSurgery: false,
  pViewPatient: true,
  pUpdatePatient: false,
};

const STAFF_PERMISSIONS = {
  pInviteUser: false,
  pAddNote: true,
  pUpdateNote: false,
  pDeleteNote: false,
  pAddSurgery: true,
  pUpdateSurgery: true,
  pDeleteSurgery: false,
  pViewPatient: true,
  pUpdatePatient: true,
};

const permissionLabels = {
  pInviteUser: 'Invite User',
  pAddNote: 'Add Note',
  pUpdateNote: 'Update Note',
  pDeleteNote: 'Delete Note',
  pAddSurgery: 'Add Surgery',
  pUpdateSurgery: 'Update Surgery',
  pDeleteSurgery: 'Delete Surgery',
  pViewPatient: 'View Patient',
  pUpdatePatient: 'Update Patient',
};

// 'p-invite-user': false,
// 'p-add-note': false,
// 'p-update-note': false,
// 'p-delete-note': false,
// 'p-add-surgery': false,
// 'p-update-surgery': false,
// 'p-delete-surgery': false,
// 'p-view-patient': true,
// 'p-update-patient': false,

export default function InviteUser({ close }: { close: () => void }): JSX.Element {
  // const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined);
  const [selectedPractitioner, setSelectedPractitioner] = useState<MedplumPractitioner | undefined>(undefined);
  // const [firstName, setFirstName] = useState<string>('');
  // const [lastName, setLastName] = useState<string>('');
  // const [birthDate, setBirthDate] = useState<DateTime | null>(null);
  // const [sex, setSex] = useState<PersonSex | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  // const [reasonForVisit, setReasonForVisit] = useState<string[]>([]);
  // const [visitType, setVisitType] = useState<VisitType>();
  // const [slot, setSlot] = useState<string | undefined>();
  const [loading, _setLoading] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ submit?: boolean; email?: boolean; search?: boolean }>({
    submit: false,
    email: false,
    search: false,
  });
  // const [loadingSlotState, setLoadingSlotState] = useState<SlotLoadingState>({ status: 'initial', input: undefined });
  // const [locationWithSlotData, setLocationWithSlotData] = useState<GetLocationResponse | undefined>(undefined);
  // const [locationWithSlotData, setLocationWithSlotData] = useState<any>(undefined);
  // const [inputValue, setInputValue] = useState<string>('');
  // const [validDate, setValidDate] = useState<boolean>(true);
  // const [selectSlotDialogOpen, setSelectSlotDialogOpen] = useState<boolean>(false);
  // const [validReasonForVisit, setValidReasonForVisit] = useState<boolean>(true);
  const [openSearchResults, setOpenSearchResults] = useState<boolean>(false);
  const [users, setUsers] = useState<User[] | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [showFields, setShowFields] = useState<{ prefillForSelected?: boolean; forcePatientSearch?: boolean }>({
    prefillForSelected: false,
    forcePatientSearch: true,
  });
  const [accessPolicy, _setAccessPolicy] = useState<Reference<AccessPolicy>>();
  const [outcome, setOutcome] = useState<OperationOutcome>();
  const [emailSent, setEmailSent] = useState(false);
  const [result, setResult] = useState<ProjectMembership | undefined>(undefined);
  const [role, setRole] = useState('Staff');
  const [title, setTitle] = useState('Guest');
  const [permissions, setPermissions] = useState(GUEST_PERMISSIONS);
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  // const userLogged = medplum.getActiveLogin();

  const userProjectMembership = medplum.getProjectMembership();

  const isAdmin = userProjectMembership?.admin || false;
  const isSurgeon = userProjectMembership?.authRole === 'Surgeon';

  // let isPractitioner = false;

  // if (userProjectMembership?.authRole === 'practitioner') {
  //   isPractitioner = true;
  // }

  // general variables
  const theme = useTheme();
  // const navigate = useNavigate();
  // const { zambdaIntakeClient, fhirClient } = useMedplum();

  const project = medplum.getProject();
  // const _sender = medplum.getProfile() as ProfileResource;
  // const reasonForVisitErrorMessage = `Input cannot be more than ${MAXIMUM_CHARACTER_LIMIT} characters`;
  const phoneNumberErrorMessage = 'Email should be valid!';

  useEffect(() => {
    if (role === 'Staff') {
      if (title === 'Guest') {
        setPermissions(GUEST_PERMISSIONS);
      } else {
        setPermissions(STAFF_PERMISSIONS);
      }
    }

    if (role === 'Surgeon') {
      setPermissions(PRACTITIONER_PERMISSIONS);
    }
  }, [role, title]);

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setErrors({ email: true });
      // return;
    } else {
      setErrors({});
      // let invitedUser = null;
      // let invitedProfile = null;

      // let invitedMembership: ProjectMembership | undefined = undefined;

      console.log('selectedUser', selectedUser);
      const userMembership = await medplum.search('ProjectMembership', {
        user: `User/${selectedUser?.id}`,
        project: `Project/${project?.id}`,
      });

      // if ((userMembership?.entry?.length ?? 0) > 0) {
      //   if (userMembership?.entry && userMembership.entry.length > 0) {
      //     invitedMembership = userMembership.entry[0].resource as ProjectMembership;

      //     // if (!invitedMembership?.profile) {
      //     //   const invitedMembershipProfile = await medplum.createResource({
      //     //     resourceType: 'Practitioner',
      //     //     meta: {
      //     //       project: project?.id ?? '',
      //     //     },
      //     //     name: [
      //     //       {
      //     //         given: [selectedUser?.firstName],
      //     //         family: selectedUser?.lastName,
      //     //       },
      //     //     ],
      //     //     telecom: [
      //     //       {
      //     //         system: 'email',
      //     //         value: selectedUser?.email,
      //     //       },
      //     //     ],
      //     //   });

      //     //   invitedMembership.profile = createReference(invitedMembershipProfile as Practitioner);
      //     // }
      //   }
      // }

      const body = {
        ...userMembership.entry?.[0].resource,
        // resourceType: formData.resourceType as 'Practitioner' | 'Patient' | 'RelatedPerson',
        resourceType: 'Practitioner' as 'Practitioner' | 'Patient' | 'RelatedPerson',
        firstName: selectedUser?.firstName || '',
        lastName: selectedUser?.lastName || '',
        email: selectedUser?.email,
        // sendEmail: formData.sendEmail === 'on',
        sendEmail: false,
        accessPolicy,
        // admin: formData.isAdmin === 'on',
        admin: false,
        // authRole: formData.role || 'staff',
        // title: formData.title || 'guest',
        upsert: false,
        practitioner: isAdmin ? selectedPractitioner : profile,
        sender: userProjectMembership,
        ...(!userMembership ? permissions : {}),
      };

      try {
        const response: ProjectMembership | OperationOutcome = await medplum.invite(
          project?.id as string,
          body as InviteRequest
        );

        if ('project' in response && 'user' in response && 'profile' in response) {
          setResult(response);
          // Create Communication Log for removed user
          const text = `You have been invited for surgeon ${createReference(isAdmin ? selectedPractitioner : profile).display}'s calendar by ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}`;

          await medplum.createResource({
            resourceType: 'Communication',
            status: 'completed',
            subject: response?.profile,
            sender: createReference(profile),
            sent: new Date().toISOString(),
            payload: [{ contentString: text }],
          });

          if (isSurgeon) {
            // Create Communication Log for surgeon
            const text = `You invited the guest ${response?.profile?.display} in your calendar.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            });
          }

          if (isAdmin) {
            // Create Communication Log for admin
            const text = `You invited ${response?.profile?.display} in surgeon ${createReference(isAdmin ? selectedPractitioner : profile).display}'s calendar.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            });

            // Create Communication Log for surgeon by admin
            const payloadText = `${response?.profile?.display} has been added in your calendar by admin ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(isAdmin ? selectedPractitioner : profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: payloadText }],
            });
          }
        }

        // setEmailSent(body.sendEmail ?? false);
        showNotification({ color: 'green', message: 'Invite successfully send!' });

        medplum.invalidateSearches('Patient');
        medplum.invalidateSearches('Practitioner');
        medplum.invalidateSearches('ProjectMembership');

        close();
      } catch (err) {
        showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
      }
    }
  };
  // console.log(slot);

  const handleUserSearch = async (e: any): Promise<void> => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setErrors({ ...errors, email: true });
      return;
    }
    setSearching(true);
    setErrors({ ...errors, search: false });
    setShowFields({ ...showFields, forcePatientSearch: false });

    const resources: Resource[] = await medplum.searchResources(
      'User',
      `email=${encodeURIComponent(email)}&_count=1000`
    );
    const users = resources.filter((resourceTemp) => resourceTemp.resourceType === 'User') as User[];
    // users.sort(sortPatientsByName);
    setUsers(users);
    setOpenSearchResults(true);
    setSearching(false);
  };

  const handleSelectPatient = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selected = users?.find((patient) => patient.id === event.target.value);
    setSelectedUser(selected);
  };

  const handleSubmit = useCallback(
    async (formData: Record<string, string>) => {
      // const authRole = formData.authRole || role || 'staff';

      const body = {
        // resourceType: formData.resourceType as 'Practitioner' | 'Patient' | 'RelatedPerson',
        resourceType: 'Practitioner' as 'Practitioner' | 'Patient' | 'RelatedPerson',
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email || email,
        // sendEmail: formData.sendEmail === 'on',
        sendEmail: true,
        accessPolicy,
        // admin: formData.isAdmin === 'on',
        admin: false,
        authRole: formData.role || role || 'staff',
        title: formData.title || title || 'guest',
        upsert: false,
        practitioner: isAdmin ? selectedPractitioner : profile,
        sender: userProjectMembership,
        ...permissions,
      };

      try {
        const response: ProjectMembership | OperationOutcome = await medplum.invite(
          project?.id as string,
          body as InviteRequest
        );

        if ('project' in response && 'user' in response && 'profile' in response) {
          setResult(response);

          const text = `You have been invited for surgeon ${createReference(isAdmin ? selectedPractitioner : profile).display}'s calendar by ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}`;

          await medplum.createResource({
            resourceType: 'Communication',
            status: 'completed',
            subject: response?.profile,
            sender: createReference(profile),
            sent: new Date().toISOString(),
            payload: [{ contentString: text }],
          });

          if (isSurgeon) {
            // Create Communication Log for surgeon
            const text = `You invited the guest ${response?.profile?.display} in your calendar.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            });
          }

          if (isAdmin) {
            // Create Communication Log for admin
            const text = `You invited ${response?.profile?.display} in surgeon ${createReference(isAdmin ? selectedPractitioner : profile).display}'s calendar.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: text }],
            });

            // Create Communication Log for surgeon by admin
            const payloadText = `${response?.profile?.display}  has been added in your calendar by admin ${isAdmin ? createReference(profile).display : formatHumanName(profile?.name?.[0] as HumanName)}.`;

            await medplum.createResource({
              resourceType: 'Communication',
              status: 'completed',
              subject: createReference(isAdmin ? selectedPractitioner : profile),
              sender: createReference(profile),
              sent: new Date().toISOString(),
              payload: [{ contentString: payloadText }],
            });
          }
        }

        setEmailSent(body.sendEmail ?? false);
        showNotification({ color: 'green', message: 'Invite successfully send!' });
        medplum.invalidateSearches('Patient');
        medplum.invalidateSearches('Practitioner');
        medplum.invalidateSearches('ProjectMembership');
        close();
      } catch (err) {
        showNotification({ color: 'red', message: normalizeErrorString(err), autoClose: false });
        setOutcome(normalizeOperationOutcome(err));
      }
    },
    [
      accessPolicy,
      close,
      email,
      isAdmin,
      isSurgeon,
      medplum,
      permissions,
      profile,
      project?.id,
      role,
      selectedPractitioner,
      title,
      userProjectMembership,
    ]
  );

  const PermissionManager = (): JSX.Element => {
    // Handle permission change
    const handlePermissionChange = (key: keyof typeof permissions): void => {
      setPermissions((prev) => ({
        ...prev,
        [key]: !prev[key], // Toggle the value of the selected permission
      }));
    };

    return (
      <Stack gap="xs" justify="-moz-initial">
        <Title order={4}>Manage Permissions</Title>
        {/* <Group dir="column"> */}
        <Grid container gap={1}>
          {Object.entries(permissions).map(([key, value]) => (
            <Grid item xs={5}>
              <Checkbox
                w={'100%'}
                key={key}
                // label={formatPermissionKey(key)}
                label={permissionLabels[key as keyof typeof permissions]}
                checked={value}
                onChange={() => handlePermissionChange(key as keyof typeof permissions)}
              />
            </Grid>
          ))}
        </Grid>
        {/* </Group> */}
      </Stack>
    );
  };

  return (
    // <PageContainer>
    <Grid container direction="row">
      {/* <Grid item xs={3.5} /> */}
      <Grid item xs={12}>
        {/* <CustomBreadcrumbs
          chain={[
            { link: '/Appointment', children: 'Appointments' },
            { link: '#', children: 'Add Patient' },
          ]}
        /> */}

        {/* page title */}

        {/* <Typography variant="h3" marginTop={1} color={'primary.dark'}>
          Add Patient
        </Typography> */}

        {/* form content */}
        <Paper>
          <Box padding={3} marginTop={2}>
            {/* Patient information */}
            <Box>
              <form onSubmit={(e) => handleFormSubmit(e)}>
                {/* <Typography variant="h4" color="primary.dark" sx={{ marginTop: 4 }}>
                  Invite User
                </Typography> */}
                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={8}>
                      <TextField
                        value={email}
                        label="Email"
                        variant="outlined"
                        placeholder="Email"
                        fullWidth
                        required
                        error={errors.email}
                        helperText={errors?.email ? phoneNumberErrorMessage : ''}
                        onChange={({ target }) => {
                          setEmail(target.value);
                          // if (errors.email && values.value.length === 11) {
                          setErrors({ ...errors, email: false });
                          // }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', padding: '0 8px' }}
                    >
                      <LoadingButton
                        variant="contained"
                        onClick={(event) => handleUserSearch(event)}
                        loading={searching}
                        sx={{
                          borderRadius: 100,
                          textTransform: 'none',
                          fontWeight: 600,
                        }}
                      >
                        Search for Users
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
                <Dialog
                  open={openSearchResults}
                  onClose={() => {
                    setSelectedUser(undefined);
                    setOpenSearchResults(false);
                  }}
                  maxWidth="md"
                  sx={{
                    minWidth: '600px',
                  }}
                >
                  <Box
                    sx={{ minWidth: '600px', borderRadius: '4px', p: '35px', maxHeight: '450px', overflow: 'scroll' }}
                  >
                    {users && users?.length > 0 ? (
                      <>
                        <Box>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: '600 !important',
                              color: theme.palette.primary.main,
                              marginBottom: '4px',
                            }}
                          >
                            Select an Existing Users
                          </Typography>
                        </Box>
                        <Box>
                          <RadioGroup onChange={(e) => handleSelectPatient(e)}>
                            {users?.map((patient) => {
                              const label = `${patient.firstName} ${patient.lastName} (Email: ${patient.email})`;
                              // } (DOB: ${DateTime.fromISO(patient?.birthDate || '').toFormat('MMMM dd, yyyy')})`;
                              return (
                                <FormControlLabel
                                  key={patient.id}
                                  value={patient.id}
                                  control={<Radio />}
                                  label={label}
                                />
                              );
                            })}
                          </RadioGroup>
                        </Box>
                        {selectedUser && (
                          <Box sx={{ marginTop: 2 }}>
                            <Button
                              variant="outlined"
                              sx={{
                                borderRadius: 100,
                                textTransform: 'none',
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                setShowFields({ ...showFields, prefillForSelected: true });
                                setOpenSearchResults(false);
                              }}
                            >
                              Select {selectedUser?.firstName}
                            </Button>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box sx={{ marginTop: 2 }}>
                        <Button
                          variant="contained"
                          sx={{
                            borderRadius: 100,
                            textTransform: 'none',
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            setSelectedUser(undefined);
                            setShowFields({ ...showFields, prefillForSelected: false });
                            setOpenSearchResults(false);
                          }}
                        >
                          User Not Found - Add Manually
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Dialog>
                {searching && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} marginTop={2}>
                    <CircularProgress />
                  </Box>
                )}
                {showFields.prefillForSelected && selectedUser && (
                  <Box marginTop={3}>
                    <Typography variant="h4" color="primary.dark">
                      {selectedUser.firstName} {selectedUser.lastName}
                    </Typography>
                    {/* {selectedUser.gender && <Typography>Birth Sex: {selectedUser.gender}</Typography>} */}
                    <Typography>Email: {selectedUser.email}</Typography>
                    {/* <Typography>
                      Birthday: {DateTime.fromISO(selectedUser?.birthDate || '').toFormat('MMMM dd, yyyy')}
                    </Typography> */}

                    {!selectedUser && (
                      <Select
                        name="title"
                        label="Title"
                        placeholder="Select Title"
                        required={true}
                        disabled={!isAdmin}
                        defaultValue={'Guest'}
                        data={['Physician Assistant', 'Nurse Practitioner', 'Guest']}
                        error={getErrorsForInput(outcome, 'title')}
                        mt={10}
                      />
                    )}

                    {isAdmin && (
                      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                        <PractitionerSelect
                          practitioner={selectedPractitioner}
                          setPractitioner={setSelectedPractitioner}
                          updateURL={false}
                          required
                          renderInputProps={{ disabled: false, size: 'small' }}
                        />
                      </Box>
                    )}

                    {isAdmin && !selectedUser && <PermissionManager />}
                  </Box>
                )}
                {showFields.forcePatientSearch && (
                  <Box marginTop={4}>
                    <Typography variant="body1" color="primary.dark">
                      Please enter the email and search for existing users before proceeding.
                    </Typography>
                  </Box>
                )}

                {/* form buttons */}
                {!(!showFields.forcePatientSearch && !showFields.prefillForSelected && !searching) && (
                  <Box marginTop={4}>
                    {errors.submit && (
                      <Typography color="error" variant="body2" mb={2}>
                        Failed to add patient. Please try again.
                      </Typography>
                    )}
                    {errors.search && (
                      <Typography color="error" variant="body2" mb={2}>
                        Please search for users before adding
                      </Typography>
                    )}
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={loading || searching}
                      sx={{
                        borderRadius: 100,
                        textTransform: 'none',
                        fontWeight: 600,
                        marginRight: 1,
                      }}
                    >
                      Add User
                    </LoadingButton>
                    <Button
                      sx={{
                        borderRadius: 100,
                        textTransform: 'none',
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        // navigate('/Appointment');
                        close();
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
              </form>
            </Box>
            <Box>
              {!showFields.forcePatientSearch && !showFields.prefillForSelected && !searching && (
                <Form
                  onSubmit={handleSubmit}
                  style={{
                    minWidth: '400px',
                  }}
                >
                  {!result && !outcome && (
                    <Stack>
                      <Title>Invite new User</Title>
                      {/* {medplum.isSuperAdmin() && (
                         <FormSection title="Project" htmlFor="project" outcome={outcome}>
                           <ResourceInput<Project>
                             resourceType="Project"
                             name="project"
                             defaultValue={project}
                             onChange={setProject}
                           />
                         </FormSection>
                       )} */}
                      {/* <NativeSelect
                         name="resourceType"
                         label="Role"
                         defaultValue="Practitioner"
                         data={['Practitioner', 'Patient', 'RelatedPerson']}
                         error={getErrorsForInput(outcome, 'resourceType')}
                         hidden={true}
                       /> */}
                      <TextInput
                        name="firstName"
                        label="First Name"
                        required={true}
                        autoFocus={true}
                        error={getErrorsForInput(outcome, 'firstName')}
                      />
                      <TextInput
                        name="lastName"
                        label="Last Name"
                        required={true}
                        error={getErrorsForInput(outcome, 'lastName')}
                      />
                      <TextInput
                        name="email"
                        type="email"
                        label="Email"
                        required={true}
                        disabled
                        error={getErrorsForInput(outcome, 'email')}
                        defaultValue={email}
                        value={email}
                      />
                      {isAdmin && (
                        <Select
                          name="authRole"
                          label="Role"
                          placeholder="Select Role"
                          required={true}
                          defaultValue={role}
                          value={role}
                          onChange={(e) => setRole(e ?? 'Staff')}
                          data={['Surgeon', 'Staff']}
                          error={getErrorsForInput(outcome, 'title')}
                        />
                      )}

                      {role === 'Staff' && (
                        <Select
                          name="title"
                          label="Title"
                          placeholder="Select Title"
                          required={true}
                          disabled={!isAdmin}
                          value={title}
                          onChange={(e) => setTitle(e ?? 'Guest')}
                          defaultValue={'Guest'}
                          data={['Physician Assistant', 'Nurse Practitioner', 'Guest']}
                          error={getErrorsForInput(outcome, 'title')}
                        />
                      )}

                      {isAdmin && (
                        <Box sx={{ marginTop: 2 }}>
                          <PractitionerSelect
                            practitioner={selectedPractitioner}
                            setPractitioner={setSelectedPractitioner}
                            updateURL={false}
                            required
                            renderInputProps={{ disabled: false }}
                          />
                        </Box>
                      )}

                      {isAdmin && <PermissionManager />}

                      {/* <FormSection title="Access Policy" htmlFor="accessPolicy" outcome={outcome}>
                         <AccessPolicyInput
                           name="accessPolicy"
                           onChange={(value) => setAccessPolicy(value as Reference<AccessPolicy> | undefined)}
                         />
                       </FormSection> */}
                      {/* <Checkbox name="sendEmail" label="Send email" defaultChecked={true} /> */}
                      {/* <Checkbox name="isAdmin" label="Admin" /> */}
                      <Group justify="flex-end">
                        <Button type="submit">Invite</Button>
                      </Group>
                    </Stack>
                  )}
                  {outcome && (
                    <div data-testid="success">
                      <p>User created, email couldn't be sent</p>
                      {/* <p>Could not send email. Make sure you have AWS SES set up.</p> */}
                      {/* <p>
                         Click <MedplumLink to="/admin/project">here</MedplumLink> to return to the project admin page.
                       </p> */}
                    </div>
                  )}
                  {result && (
                    <div data-testid="success">
                      <Text>User created</Text>
                      {emailSent && <Text>Email sent</Text>}
                      <List>
                        <List.Item>
                          <MedplumLink to={result as ProjectMembership}>Go to new membership</MedplumLink>
                        </List.Item>
                        <List.Item>
                          <MedplumLink to={result.profile}>Go to new profile</MedplumLink>
                        </List.Item>
                        <List.Item>
                          <MedplumLink to="/Users">Back to users list</MedplumLink>
                        </List.Item>
                      </List>
                    </div>
                  )}
                </Form>
              )}
            </Box>

            {/* Visit Information */}
            {/* {!showFields.forcePatientSearch && !searching && (
                <Box marginTop={4}>
                  <Typography variant="h4" color="primary.dark">
                    Visit information
                  </Typography>
                  <Box marginTop={2}>
                    <Autocomplete
                      multiple
                      autoComplete
                      disableClearable
                      forcePopupIcon
                      id="reason-for-visit-label"
                      options={ReasonForVisitOptions}
                      freeSolo
                      value={reasonForVisit}
                      inputValue={inputValue}
                      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
                      onChange={(_, newValue) => handleReasonsForVisitChange(newValue)}
                      onBlur={() => {
                        if (inputValue.trim() !== '') {
                          handleReasonsForVisitChange([...reasonForVisit, inputValue.trim()]);
                        }
                        setInputValue('');
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} sx={{ fontSize: 16 }} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Reason for visit"
                          variant="outlined"
                          fullWidth
                          required={reasonForVisit.length === 0}
                          error={!validReasonForVisit}
                          helperText={validReasonForVisit ? '' : reasonForVisitErrorMessage}
                        />
                      )}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiFilledInput-root': {
                          fontSize: 16,
                          p: 0.5,
                          border: '1px solid',
                          borderRadius: 2,
                          '&::before, ::after, :hover:not(.Mui-disabled, .Mui-error)::before': {
                            borderBottom: 0,
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box marginTop={2}>
                    <FormControl fullWidth>
                      <InputLabel id="visit-type-label">Visit type *</InputLabel>
                      <Select
                        labelId="visit-type-label"
                        id="visit-type-select"
                        value={visitType || ''}
                        label="Visit type *"
                        required
                        onChange={(event) => {
                          setSlot(undefined);
                          setVisitType(event.target.value as VisitType);
                        }}
                      >
                        <MenuItem value={VisitType.Now}>Now</MenuItem>
                        <MenuItem value={VisitType.Prebook}>Pre-booked visit</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {visitType === VisitType.Prebook && (
                    <SlotPicker
                      slotData={locationWithSlotData?.availableSlots}
                      slotsLoading={loadingSlotState.status === 'loading'}
                      timezone={locationWithSlotData?.timezone || 'Undefined'}
                      selectedSlot={slot}
                      setSelectedSlot={setSlot}
                    />
                  )}
                </Box>
              )} */}
          </Box>
          {/* <CustomDialog
            open={selectSlotDialogOpen}
            title="Please select a date and time"
            description="To continue, please select an available appointment."
            closeButtonText="Close"
            handleClose={() => setSelectSlotDialogOpen(false)}
          /> */}
        </Paper>
      </Grid>

      {/* <Grid item xs={3.5} /> */}
    </Grid>
    // </PageContainer>
  );
}
