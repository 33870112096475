import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, ScrollArea, Box, Group, Text, Divider } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import '@mantine/dates/styles.css';
import { Doctor } from '.';

interface SidebarProps {
  doctors: Doctor[];
  selectedDoctors: string[];
  onToggle: (id: string, checked: boolean) => void;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  showCalendar: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  doctors,
  selectedDoctors,
  onToggle,
  selectedDate,
  setSelectedDate,
  showCalendar,
}) => {
  return (
    <Box p="md" style={{ width: '100%', borderRight: '1px solid #eee' }}>
      <DatePicker value={selectedDate} onChange={(e) => setSelectedDate(e as Date)} />
      {showCalendar && (
        <>
          <Divider my="lg" />
          <Group ps="apart" mb="md" justify="space-between">
            <Text size="lg" fw={600}>
              Calendar
            </Text>
            {/* <ActionIcon variant="filled" size="sm" color="#006DB1" radius="lg" onClick={() => alert('Add new calendar')}>
          <IconPlus size={16} />
        </ActionIcon> */}
          </Group>
          <ScrollArea style={{ minHeight: '200px', marginTop: '20px' }}>
            {doctors.map((doctor) => (
              <Checkbox
                key={doctor.reference}
                label={doctor.display}
                checked={selectedDoctors.includes(doctor.reference)}
                onChange={(e) => onToggle(doctor.reference, e.target.checked)}
                style={{ marginTop: '10px' }}
                color={doctor.backgroundColor}
                iconColor={doctor.color}
              />
            ))}
          </ScrollArea>
        </>
      )}
    </Box>
  );
};

export default Sidebar;
