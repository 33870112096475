import { Event } from 'react-big-calendar';
import { Drawer, Title } from '@mantine/core';
import { Dispatch, SetStateAction, useState } from 'react';
import { Appointment, Resource } from '@medplum/fhirtypes';
import { TimelinePage } from '../../components/dialogs/PatientTimeline';

interface AppointmentModalProps {
  appointment: Event | null; // Appointment;
  onClose: () => void;
  opened: boolean;
  onUpdate: (appointment: Appointment) => void;
  setFetchAppointments: Dispatch<SetStateAction<boolean>>;
}

const AppointmentNotesDrawer = ({
  appointment,
  onClose,
  // onUpdate,
  opened,
  // setFetchAppointments,
}: AppointmentModalProps): JSX.Element => {
  const { patient, notes = [] } = appointment?.resource || {};

  const [_items, setItems] = useState<Resource[]>(notes);

  // useEffect(() => {
  //   setFetchAppointments((prev) => !prev);
  // }, [items, setFetchAppointments]);

  return (
    <>
      {opened && (
        <Drawer
          opened={opened}
          onClose={onClose}
          title={<Title pl={15}>{`Notes for ${patient?.display}`}</Title>}
          position="right"
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
          size="lg"
          transitionProps={{ transition: 'rotate-right', duration: 150, timingFunction: 'linear' }}
        >
          <TimelinePage id={patient.actor.reference} setItems={setItems} />
        </Drawer>
      )}
    </>
  );
};

export default AppointmentNotesDrawer;
