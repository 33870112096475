import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox, ScrollArea, Box, Group, Text, Divider, Grid, Indicator } from '@mantine/core';
import { DatePicker, DatePickerProps } from '@mantine/dates';
import '@mantine/dates/styles.css';
import { Doctor } from './SchedulePage';
import dayjs from 'dayjs';

interface SidebarProps {
  doctors: Doctor[];
  selectedDoctors: string[];
  onToggle: (id: string, checked: boolean) => void;
  selectedDate: Date | null;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  allAppointmentsDates: string[];
}

const Sidebar: React.FC<SidebarProps> = ({
  doctors,
  selectedDoctors,
  onToggle,
  selectedDate,
  setSelectedDate,
  allAppointmentsDates,
}) => {
  const dayRenderer: DatePickerProps['renderDay'] = (date) => {
    const day = date.getDate();
    const dateStr = dayjs(date).format('DD-MM-YYYY');
    return (
      <Indicator
        inline
        processing
        color="#8EC5EB"
        size={10}
        offset={-3}
        zIndex={1}
        disabled={!allAppointmentsDates.includes(dateStr)}
      >
        <div>{day}</div>
      </Indicator>
    );
  };

  return (
    <Box p="md" style={{ width: '100%', borderRight: '1px solid #eee' }}>
      <Grid gutter="xs" style={{ padding: '0px' }} justify="space-between">
        <Grid.Col order={{ xs: 1, sm: 1 }} span={{ xs: 7, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <DatePicker
            className="sidebar-datepicker"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e as Date)}
            renderDay={dayRenderer}
          />
        </Grid.Col>
        <Grid.Col order={{ xs: 3, sm: 1 }} span={{ xs: 0, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Divider my="lg" />
        </Grid.Col>
        <Grid.Col order={{ xs: 2, sm: 1 }} span={{ xs: 5, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Group ps="apart" mb="md" justify="space-between">
            <Text size="lg" fw={600}>
              Calendar
            </Text>
            {/* <ActionIcon variant="filled" size="sm" color="#006DB1" radius="lg" onClick={() => alert('Add new calendar')}>
          <IconPlus size={16} />
        </ActionIcon> */}
          </Group>
          <ScrollArea style={{ minHeight: '200px', marginTop: '20px' }}>
            {doctors.map((doctor) => (
              <Checkbox
                key={doctor.reference}
                label={doctor.display}
                checked={selectedDoctors.includes(doctor.reference)}
                onChange={(e) => onToggle(doctor.reference, e.target.checked)}
                style={{ marginTop: '10px' }}
                color={doctor.backgroundColor}
                iconColor={doctor.color}
              />
            ))}
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default Sidebar;
